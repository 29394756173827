import React from "react";
import { AppBar, Toolbar, Box, Button, Container } from "@mui/material";
import { Link } from "react-router-dom";
import mainLogo from "../assets/mainLogo.svg";

export const ophthalmologyTools = [
  { text: "RETINAL IMAGES", link: "/retinal-images" },
  { text: "OPTOS IMAGES", link: "/optos-images" },
  { text: "RETINAL OCT", link: "/retinal-oct" },
  { text: "CORNEAL TOPOGRAPHY", link: "/corneal-topography" },
  { text: "OPD-SCAN III WAVEFRONT ABERROMETER", link: "/opd-scan-iii-wavefront-aberrometer" },
  { text: "PENTACAM", link: "/pentacam" },
  { text: "HD ANALYZER", link: "/hd-analyzer" },
  { text: "VISUAL FIELD TESTING", link: "/visual-field-testing" },
  { text: "CORNEAL IMAGES", link: "/corneal-images" },
  { text: "MEIBOMIAN GLAND IMAGES", link: "/meibomian-gland-images" },
  { text: "LIPIVIEW", link: "/lipiview" },
  { text: "REFRACTIVE SURGERY ASSESSMENT", link: "/refractive-surgery-assessment" },
  { text: "IOL SELECTION ASSESSMENT", link: "/iol-selection-assessment" },
];

export default function SiteHeader() {
  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "#000",
        padding: "10px 0",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              mb: { xs: 2, md: 0 },
            }}
          >
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "10px",
              width: { xs: "100%", md: "auto" },
            }}
          >
            <Button
              component={Link}
              to="/client-login"
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#fff",
                "&:hover": { backgroundColor: "#fff", color: "#000" },
                width: { xs: "100%", md: "auto" },
                textTransform: "none",
              }}
            >
              Client Login
            </Button>
            <Button
              component={Link}
              to="/genaeye" // Adjust the path if different
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#fff",
                "&:hover": { backgroundColor: "#fff", color: "#000" },
                width: { xs: "100%", md: "auto" },
                textTransform: "none",
              }}
            >
              Ask GenA Eye™
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
