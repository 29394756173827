import React from "react";
import MasterLayout from "../MasterLayout";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";

const SolutionsLayout = () => {
  return (
    <MasterLayout>
      <div style={{ width: "100%", backgroundColor: "#000" }}>
        <Section1 />
        <Section2 />
        <Section3 />
      </div>
    </MasterLayout>
  );
};

export default SolutionsLayout;
