// Section3.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import eye from "../../assets/homePage/section3/eye.svg";
import imageAnalysis from "../../assets/homePage/section3/imageAnalysis.svg";
import scribe from "../../assets/homePage/section3/scribe.svg";
import assistant from "../../assets/homePage/section3/assistant.svg";
import blueEye from "../../assets/homePage/section3/blueEye.jpg";
import gradient from "../../assets/homePage/section3/gradient.jpg";
import scholar from "../../assets/homePage/section3/scholar.svg";

const Section3 = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [isGetStartedHovered, setIsGetStartedHovered] = useState(false); // State for "Get Started" button hover

  const experiences = [
    {
      id: 1,
      title: "Patient Experience",
      description:
        "Use an intelligent AI assistant to gather pre-visit information and answer questions to improve patient care.",
    },
    {
      id: 2,
      title: "Physician Experience",
      description:
        "Leverage AI-driven insights to support diagnostic and treatment decisions, monitor disease progression, and generate compliant reports.",
    },
    {
      id: 3,
      title: "Practice Experience",
      description:
        "Automate routine tasks, freeing up time for patient care and strategic thinking.",
    },
    {
      id: 4,
      title: "Educational Experience",
      description:
        "Gain immediate access to the most current published ophthalmic information.",
    },
  ];

  const solutionCards = [
    {
      id: 1,
      icon: eye,
      title: "Oculogyx GenA Eye™",
      description: [
        "Oculogyx GenA Eye™",
        "Ask Everything Ophthalmic!",
        "Refractive Surgery Advisor",
        "IOL Advisor",
        "Wavefront Optimized TX",
        "Ophthalmic Study Guide",
      ],
    },
    {
      id: 2,
      icon: imageAnalysis,
      title: "Oculogyx Image Analysis",
      description: [
        "Oculogyx Image Analysis",
        "Visual Fields",
        "Retinal Photography",
        "Retinal OCT",
        "Optic Nerve OCT",
        "Optos",
        "Corneal Topography",
      ],
    },
    {
      id: 3,
      icon: scribe,
      title: "Oculogyx Scribe",
      description: [
        "Oculogyx Scribe",
        "Dictation",
        "Chart Notes",
        "Reports",
        "Referral Letters",
      ],
    },
    {
      id: 4,
      icon: assistant,
      title: "Oculogyx Practice Agent",
      description: [
        "Oculogyx Practice Agent",
        "Communicate with Patients",
        "Ophthalmic Triage",
        "Pre-Visit Interviews",
        "Practice Information Agent",
      ],
    },
    {
      id: 5,
      icon: scholar,
      title: "Ophthalmic Scholar",
      description: [
        "Ophthalmic Scholar",
        "Research Assistant",
        "Ophthalmic History Taking",
        "Clinical Trial Design and Data Analysis",
        "Board Preparation",
        "Ophthalmic Study Guide",
        "Test Your Clinical Acumen",
      ],
    },
  ];

  return (
    <div
      className="section3-container"
      style={{
        backgroundColor: "#f9fafc",
        padding: "70px 30px",
        color: "#000",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <div className="comp-practice-solutions">
        <div style={{ textAlign: "center", marginBottom: "60px" }}>
          <h2
            style={{
              fontSize: "32px",
              fontWeight: "500",
              marginBottom: "30px",
            }}
          >
            Comprehensive Practice{" "}
            <span
              style={{
                background: "linear-gradient(90deg, #5dc1d8, #103444)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                color: "transparent",
              }}
            >
              Solutions
            </span>
          </h2>
          <p style={{ fontSize: "18px", marginBottom: "40px" }}>
            Oculogyx Gen-AEye is a powerful AI platform that uses data analytics
            and machine learning to improve clinical decision-making, enhance
            patient outcomes, and optimize daily operations for eye care
            professionals.
          </p>
          <div
            className="solutions-cards"
            style={{
              /* Use a grid with 5 columns */
              display: "grid",
              gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
              gap: "20px",
              padding: "0 20px",
            }}
          >
            {solutionCards.map((card) => {
              const isHovered = hoveredCard === card.id;
              return (
                <div
                  key={card.id}
                  style={{
                    position: "relative", // For overlay positioning
                    /* Removed flex: "1 1 22%" to let CSS grid handle the layout */
                    textAlign: "center",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    color: "#000",
                    transition:
                      "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    overflow: "hidden",
                    cursor: "pointer",
                    padding: "30px",
                    height: "550px", // Fixed height
                  }}
                  onMouseEnter={() => setHoveredCard(card.id)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  {/* Default Content */}
                  <div
                    style={{
                      opacity: isHovered ? 0 : 1,
                      transition: "opacity 0.3s ease-in-out",
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: "60px", // Reserve space for hover content
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "20px",
                    }}
                  >
                    <img
                      src={card.icon}
                      alt={card.title}
                      style={{ height: "100px", marginBottom: "15px" }}
                    />
                    <h3
                      style={{
                        fontSize: "30px",
                        fontWeight: "500",
                        marginBottom: "15px",
                      }}
                    >
                      {card.title}
                    </h3>
                  </div>

                  {/* Hover Content */}
                  <div
                    style={{
                      opacity: isHovered ? 1 : 0,
                      transition: "opacity 0.3s ease-in-out",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start", // Align content to the top
                      background:
                        "linear-gradient(180deg, rgba(93,193,216,0.8) 0%, rgba(16,52,68,0.8) 100%)",
                      color: "#fff",
                      overflow: "auto", // Allow scrolling if content overflows
                    }}
                  >
                    {/* Primary Description */}
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        marginBottom: "10px",
                      }}
                    >
                      {card.description[0]}
                    </p>
                    {/* Subsequent List Items Left-Aligned */}
                    <ul
                      style={{
                        listStyleType: "disc",
                        paddingLeft: "20px",
                        margin: 0,
                        textAlign: "left", // Left-align the list items
                      }}
                    >
                      {card.description.slice(1).map((item, index) => (
                        <li
                          key={index}
                          style={{
                            marginBottom: "10px",
                            fontSize: "16px",
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
          <Link
            to="/contact-us"
            className={`get-started-button ${
              isGetStartedHovered ? "hovered" : ""
            }`}
            onMouseEnter={() => setIsGetStartedHovered(true)}
            onMouseLeave={() => setIsGetStartedHovered(false)}
          >
            {/* Button Text with Higher Z-Index */}
            <span>Get Started</span>
            {/* Gradient Overlay */}
            <div className="get-started-overlay"></div>
          </Link>
        </div>
        <div
          className="save-time"
          style={{
            display: "flex",
            gap: "30px",
            flexWrap: "wrap",
            padding: "0 30px",
          }}
        >
          <div
            className="img-overlay-container"
            style={{
              flex: "1 1 45%",
              position: "relative",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <img
              src={blueEye}
              alt="Eye Overlay"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(180deg, rgba(93,193,216,0.8) 0%, rgba(16,52,68,0.8) 100%)",
                zIndex: 1,
              }}
            />
            <div
              className="overlay-text"
              style={{
                position: "absolute",
                top: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                textAlign: "left",
                zIndex: 2,
                width: "100%",
                padding: "0 30px",
              }}
            >
              <h3
                style={{
                  margin: 0,
                  fontSize: "26px",
                  fontWeight: "500",
                  color: "#fff",
                }}
              >
                Save Time and Enhance Patient Care
              </h3>
              <p
                style={{
                  margin: "10px 0",
                  fontSize: "16px",
                  color: "#fff",
                  maxWidth: "400px",
                }}
              >
                Our groundbreaking AI is tailored specifically for eye care to
                empower physicians, staff, and patients with the latest
                advancement in AI-driven ophthalmic solutions.
              </p>
              {/* "Learn More" Button Linking to /solutions */}
              <Link to="/solutions" className="learn-more-button">
                Learn More
              </Link>
            </div>
          </div>
          <div
            className="experiences-grid"
            style={{
              flex: "1 1 50%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "30px",
            }}
          >
            {experiences.map((exp) => (
              <div
                key={exp.id}
                style={{
                  textAlign: "center",
                  padding: "30px",
                  borderRadius: "10px",
                  position: "relative",
                  overflow: "hidden",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  background: "#fff",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    inset: 0,
                    border: "5px solid transparent",
                    borderImageSource: `url(${gradient})`,
                    borderImageSlice: 1,
                    borderRadius: "10px",
                    pointerEvents: "none",
                  }}
                />
                <div style={{ position: "relative", zIndex: 1 }}>
                  <h3
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      marginBottom: "10px",
                    }}
                  >
                    {exp.title}
                  </h3>
                  <p style={{ fontSize: "14px" }}>{exp.description}</p>
                  {/* "Learn More +" Link Linking to /solutions */}
                  <Link to="/solutions" className="learn-more-link">
                    Learn More +
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* CSS Styles */}
      <style>{`
        /* "Get Started" Button Styles */
        .get-started-button {
          display: inline-block;
          position: relative;
          padding: 15px 30px;
          font-size: 18px;
          color: #fff;
          background: #103444;
          border-radius: 30px;
          border: none;
          cursor: pointer;
          margin-top: 20px;
          text-decoration: none; /* Remove underline */
          overflow: hidden;
          transition: background 0.3s ease;
        }

        .get-started-button:hover .get-started-overlay {
          opacity: 1;
        }

        .get-started-button .get-started-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to right, #5dc1d8, #103444);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 1;
        }

        .get-started-button span {
          position: relative;
          z-index: 2;
        }

        /* "Learn More" Button Styles */
        .learn-more-button {
          display: inline-block;
          padding: 12px 25px;
          font-size: 16px;
          cursor: pointer;
          background-color: #fff;
          color: #000;
          border: none;
          border-radius: 20px;
          font-weight: 500;
          transition: background-color 0.3s ease;
          text-decoration: none; /* Remove underline */
        }

        .learn-more-button:hover {
          background-color: #5dc1d8;
          color: #fff;
        }

        /* "Learn More +" Link Styles */
        .learn-more-link {
          display: inline-block;
          font-size: 14px;
          color: #5dc1d8;
          text-decoration: none;
          font-weight: 500;
          transition: color 0.3s ease;
        }

        .learn-more-link:hover {
          color: #103444;
        }

        /* Responsive Styles */
        @media (max-width: 768px) {
          .section3-container {
            padding: 30px 20px;
          }
          .solutions-cards {
            flex-direction: column;
            align-items: center;
          }
          .solutions-cards > div {
            flex: none;
            width: 100% !important;
            max-width: 400px;
          }
          .save-time {
            flex-direction: column;
          }
          .save-time .img-overlay-container {
            position: relative;
            width: 100% !important;
            min-height: 300px;
            margin-bottom: 20px;
          }
          .save-time .overlay-text {
            position: static;
            transform: none;
            padding: 20px 0;
            background: rgba(16,52,68,0.6);
          }
          .experiences-grid {
            grid-template-columns: 1fr !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Section3;
