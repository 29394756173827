// PrivacyPolicyLayout.jsx
import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";
import logo from "../assets/altMainLogo.svg"; // Adjust the path as needed
import MasterLayout from "./MasterLayout";

const PrivacyPolicyLayout = () => {
  // Define reusable style objects for navigation links
  const navLinkStyle = {
    color: "#000", // Black text for light background
    textDecoration: "none",
    marginRight: "20px",
    fontSize: "22px",
    cursor: "pointer",
    transition: "color 0.3s ease",
  };

  const navLinkHoverStyle = {
    color: "#5dc1d8", // Blue color on hover
  };

  // Function to handle hover effect
  const handleNavLinkHover = (e) => {
    e.target.style.color = navLinkHoverStyle.color;
  };

  const handleNavLinkOut = (e) => {
    e.target.style.color = navLinkStyle.color;
  };

  return (
    <MasterLayout>
      <div style={{ backgroundColor: "black" }}>
        <Box
          className="privacy-container"
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
            backgroundColor: "#fff", // White background for readability
            color: "#000", // Black text for contrast
            position: "relative",
            minHeight: "100vh",
            paddingTop: "20px",
            paddingBottom: "50px",
          }}
        >
          {/* Gradient Bar at the Top */}
          <Box
            sx={{
              width: "100%",
              height: "10px",
              background: "linear-gradient(to right, #5dc1d8, #103444)",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "1000",
            }}
          ></Box>

          <Container sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
            {/* Navigation Header */}
            <Box
              className="top-nav"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "30px",
                position: "relative",
                zIndex: "1001",
              }}
            >
              {/* Logo */}
              <Link to="/" style={{ display: "inline-block" }}>
                <img src={logo} alt="Oculogyx Logo" style={{ height: 100 }} />
              </Link>

              {/* Navigation Links */}
              <Box
                className="nav-links"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Link
                  to="/"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Home
                </Link>
                <Link
                  to="/about-us"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  About
                </Link>
                <Link
                  to="/solutions"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Solutions
                </Link>
                {/* Uncomment if needed
              <Link
                to="/news-resources"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                News & Resources
              </Link>
              */}
                <Link
                  to="/contact-us"
                  style={{ ...navLinkStyle, marginRight: "0" }} // Remove margin on last link
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Contact
                </Link>
              </Box>
            </Box>

            {/* Privacy Policy Content */}
            <Box my={4}>
              <Typography
                variant="h4"
                component="h1"
                align="center"
                sx={{ fontWeight: "bold", my: 4 }}
              >
                Privacy Policy
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={{ lineHeight: 1.6 }}
              >
                {/* Introduction Section */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Introduction
                  </Typography>
                  <Typography variant="body1" paragraph>
                    OCULOGYX INC., a Wyoming corporation (“Company” or “We”)
                    respects your privacy and is committed to protecting it
                    through our compliance with this policy.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    This policy describes the types of information we may
                    collect from you or that you may provide when you visit the
                    website www.oculogyx.ai (our “Website”) and our practices
                    for collecting, using, maintaining, protecting, and
                    disclosing that information.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    This policy applies to information we collect:
                  </Typography>
                  <ul>
                    <li>On this Website.</li>
                    <li>
                      In email, text, and other electronic messages between you
                      and this Website.
                    </li>
                    <li>
                      Through mobile and desktop applications you download from
                      this Website, which provide dedicated non-browser-based
                      interaction between you and this Website.
                    </li>
                    <li>
                      When you interact with our advertising and applications on
                      third-party websites and services, if those applications
                      or advertising include links to this policy.
                    </li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    It does not apply to information collected by:
                  </Typography>
                  <ul>
                    <li>
                      Us offline or through any other means, including on any
                      other website operated by Company or any third party
                      (including our affiliates and subsidiaries); or
                    </li>
                    <li>
                      Any third party (including our affiliates and
                      subsidiaries), including through any application or
                      content (including advertising) that may link to or be
                      accessible from or on the Website.
                    </li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    Please read this policy carefully to understand our policies
                    and practices regarding your information and how we will
                    treat it. If you do not agree with our policies and
                    practices, your choice is not to use our Website. By
                    accessing or using this Website, you agree to this privacy
                    policy. This policy may change from time to time. Your
                    continued use of this Website after we make changes is
                    deemed to be acceptance of those changes, so please check
                    the policy periodically for updates.
                  </Typography>
                </Box>

                {/* Children Under the Age of 18 */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Children Under the Age of 18
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Our Website is not intended for children under 18 years of
                    age. No one under age 18 may provide any personal
                    information to or on the Website. We do not knowingly
                    collect personal information from children under 18. If you
                    are under 18, do not use or provide any information on this
                    Website or on or through any of its features, register on
                    the Website, make any purchases through the Website, use any
                    of the interactive or public comment features of this
                    Website, or provide any information about yourself to us,
                    including your name, address, telephone number, email
                    address, or any screen name or user name you may use. If we
                    learn we have collected or received personal information
                    from a child under 18 without verification of parental
                    consent, we will delete that information. If you believe we
                    might have any information from or about a child under 18
                    without parental consent, please contact us at{" "}
                    <a href="mailto:info@oculogyx.ai">info@oculogyx.ai</a>.
                  </Typography>
                </Box>

                {/* Information We Collect */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Information We Collect
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We collect several types of information from and about users
                    of our Website, including information:
                  </Typography>
                  <ul>
                    <li>
                      By which you may be personally identified, such as name,
                      postal address, e-mail address, telephone number, credit
                      card information, banking information, and any other
                      identifier by which you may be contacted online or offline
                      (“personal information”);
                    </li>
                    <li>
                      If you subscribe to a feature or service that requires a
                      fee or make a purchase by or through the Website, you will
                      be required to provide your payment information,
                      including, without limitation, bank account numbers,
                      credit card or debit card numbers, account details, ACH
                      information, and similar data (collectively, “Payment
                      Information”). Such Payment Information will be collected
                      and processed by our third-party payment vendor, pursuant
                      to the terms and conditions of their privacy policies and
                      terms of use. Company uses a third-party vendor, Stripe,
                      Inc., (“Stripe”), to process payments made through the
                      Website, subject to Stripe’s privacy policy, terms of use,
                      and other terms of sale, as may be modified from time to
                      time (see{" "}
                      <a
                        href="https://stripe.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://stripe.com/privacy
                      </a>
                      );
                    </li>
                    <li>
                      That is about you but individually does not identify you;
                      and/or
                    </li>
                    <li>
                      About your internet connection, the equipment you use to
                      access our Website, and usage details.
                    </li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    We collect this information:
                  </Typography>
                  <ul>
                    <li>Directly from you when you provide it to us.</li>
                    <li>
                      Automatically as you navigate through the site.
                      Information collected automatically may include usage
                      details, IP addresses, and information collected through
                      cookies, web beacons, and other tracking technologies.
                    </li>
                    <li>
                      From third parties, for example, our business partners.
                    </li>
                  </ul>
                </Box>

                {/* Information You Provide to Us */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Information You Provide to Us
                  </Typography>
                  <Typography variant="body1" paragraph>
                    The information we collect on or through our Website may
                    include:
                  </Typography>
                  <ul>
                    <li>
                      Information that you provide by filling in forms on our
                      Website. This includes information provided at the time of
                      registering to use our Website, subscribing to our
                      service, posting material, submitting User Contributions,
                      or requesting further services. We may also ask you for
                      information when you report a problem with our Website.
                    </li>
                    <li>
                      Records and copies of your correspondence (including email
                      addresses), if you contact us.
                    </li>
                    <li>
                      Your responses to surveys that we might ask you to
                      complete for research purposes.
                    </li>
                    <li>
                      Details of transactions you carry out through our Website.
                      You may be required to provide financial information
                      before making transactions through our Website.
                    </li>
                    <li>Your search queries on the Website.</li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    You also may provide information to be published or
                    displayed (hereinafter, “posted”) on public areas of the
                    Website, or transmitted to other users of the Website or
                    third parties (collectively, “User Contributions”). Your
                    User Contributions are posted on and transmitted to others
                    at your own risk. Although we limit access to certain pages
                    and you may set certain privacy settings for such
                    information by logging into your account profile, please be
                    aware that no security measures are perfect or impenetrable.
                    Additionally, we cannot control the actions of other users
                    of the Website with whom you may choose to share your User
                    Contributions. Therefore, we cannot and do not guarantee
                    that your User Contributions will not be viewed by
                    unauthorized persons.
                  </Typography>
                </Box>

                {/* Information We Collect Through Automatic Data Collection Technologies */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Information We Collect Through Automatic Data Collection
                    Technologies
                  </Typography>
                  <Typography variant="body1" paragraph>
                    As you navigate through and interact with our Website, we
                    may use automatic data collection technologies to collect
                    certain information about your equipment, browsing actions,
                    and patterns, including:
                  </Typography>
                  <ul>
                    <li>
                      Details of your visits to our Website, including traffic
                      data, location data, and other communication data and the
                      resources that you access and use on the Website.
                    </li>
                    <li>
                      Information about your computer and internet connection,
                      including your IP address, operating system, and browser
                      type.
                    </li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    The information we collect automatically may include
                    personal information or we may maintain it or associate it
                    with personal information we collect in other ways or
                    receive from third parties. It helps us to improve our
                    Website and to deliver a better and more personalized
                    service, including by enabling us to:
                  </Typography>
                  <ul>
                    <li>Estimate our audience size and usage patterns.</li>
                    <li>
                      Store information about your preferences, allowing us to
                      customize our Website according to your individual
                      interests.
                    </li>
                    <li>Speed up your searches.</li>
                    <li>Recognize you when you return to our Website.</li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    The technologies we use for this automatic data collection
                    may include:
                  </Typography>
                  <ul>
                    <li>
                      <strong>Cookies (or browser cookies).</strong> A cookie is
                      a small file placed on the hard drive of your computer.
                      You may refuse to accept browser cookies by activating the
                      appropriate setting on your browser. However, if you
                      select this setting you may be unable to access certain
                      parts of our Website. Unless you have adjusted your
                      browser setting so that it will refuse cookies, our system
                      will issue cookies when you direct your browser to our
                      Website.
                    </li>
                    <li>
                      <strong>Flash Cookies.</strong> Certain features of our
                      Website may use local stored objects (or Flash cookies) to
                      collect and store information about your preferences and
                      navigation to, from, and on our Website. Flash cookies are
                      not managed by the same browser settings as are used for
                      browser cookies. For information about managing your
                      privacy and security settings for Flash cookies, see{" "}
                      <a
                        href="https://www.adobe.com/privacy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Choices About How We Use and Disclose Your Information
                      </a>
                      .
                    </li>
                    <li>
                      <strong>Web Beacons.</strong> Pages of our Website may
                      contain small electronic files known as web beacons (also
                      referred to as clear gifs, pixel tags, and single-pixel
                      gifs) that permit the Company, for example, to count users
                      who have visited those pages and for other related website
                      statistics (for example, recording the popularity of
                      certain website content and verifying system and server
                      integrity).
                    </li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    We do not collect personal information automatically, but we
                    may tie this information to personal information about you
                    that we collect from other sources or you provide to us.
                  </Typography>
                </Box>

                {/* Third-Party Use of Cookies */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Third-Party Use of Cookies
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Some content or applications, including advertisements, on
                    the Website are served by third-parties, including
                    advertisers, ad networks and servers, content providers, and
                    application providers. These third parties may use cookies
                    to collect information about you when you use our website.
                    The information they collect may be associated with your
                    personal information or they may collect information,
                    including personal information, about your online activities
                    over time and across different websites and other online
                    services. They may use this information to provide you with
                    interest-based (behavioral) advertising or other targeted
                    content.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We do not control these third parties’ tracking technologies
                    or how they may be used. If you have any questions about an
                    advertisement or other targeted content, you should contact
                    the responsible provider directly. For information about how
                    you can opt out of receiving targeted advertising from many
                    providers, see{" "}
                    <a
                      href="https://www.networkadvertising.org/choices/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Choices About How We Use and Disclose Your Information
                    </a>
                    .
                  </Typography>
                </Box>

                {/* How We Use Your Information */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    How We Use Your Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We use information that we collect about you or that you
                    provide to us, including any personal information:
                  </Typography>
                  <ul>
                    <li>To present our Website and its contents to you.</li>
                    <li>
                      To provide you with information, products, or services
                      that you request from us.
                    </li>
                    <li>
                      To fulfill any other purpose for which you provide it.
                    </li>
                    <li>
                      To provide you with notices about your account or
                      subscription, including expiration and renewal notices.
                    </li>
                    <li>
                      To carry out our obligations and enforce our rights
                      arising from any contracts entered into between you and
                      us, including for billing and collection.
                    </li>
                    <li>
                      To notify you about changes to our Website or any products
                      or services we offer or provide through it.
                    </li>
                    <li>
                      To allow you to participate in interactive features on our
                      Website.
                    </li>
                    <li>
                      In any other way we may describe when you provide the
                      information.
                    </li>
                    <li>For any other purpose with your consent.</li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    We may also use your information to contact you about our
                    own and third-parties’ goods and services that may be of
                    interest to you. If you do not want us to use your
                    information in this way, please adjust your user preferences
                    in your account profile. For more information, see{" "}
                    <a href="#choices-about-how-we-use-and-disclose-your-information">
                      Choices About How We Use and Disclose Your Information
                    </a>
                    .
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We may use the information we have collected from you to
                    enable us to display advertisements to our advertisers’
                    target audiences. Even though we do not disclose your
                    personal information for these purposes without your
                    consent, if you click on or otherwise interact with an
                    advertisement, the advertiser may assume that you meet its
                    target criteria.
                  </Typography>
                </Box>

                {/* Disclosure of Your Information */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Disclosure of Your Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We may disclose aggregated information about our users, and
                    information that does not identify any individual, without
                    restriction.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We may disclose personal information that we collect or you
                    provide as described in this privacy policy:
                  </Typography>
                  <ul>
                    <li>To our subsidiaries and affiliates.</li>
                    <li>
                      To contractors, service providers, and other third parties
                      we use to support our business.
                    </li>
                    <li>
                      To a buyer or other successor in the event of a merger,
                      divestiture, restructuring, reorganization, dissolution,
                      or other sale or transfer of some or all of OCULOGYX
                      INC.’s assets, whether as a going concern or as part of
                      bankruptcy, liquidation, or similar proceeding, in which
                      personal information held by OCULOGYX INC. about our
                      Website users is among the assets transferred.
                    </li>
                    <li>To fulfill the purpose for which you provide it.</li>
                    <li>
                      For any other purpose disclosed by us when you provide the
                      information.
                    </li>
                    <li>With your consent.</li>
                  </ul>
                  <Typography variant="body1" paragraph>
                    We may also disclose your personal information:
                  </Typography>
                  <ul>
                    <li>
                      To comply with any court order, law, or legal process,
                      including to respond to any government or regulatory
                      request.
                    </li>
                    <li>
                      To enforce or apply our Terms of Use, Registered User
                      Agreement, and other agreements, including for billing and
                      collection purposes.
                    </li>
                    <li>
                      If we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of OCULOGYX INC.,
                      our customers, or others. This includes exchanging
                      information with other companies and organizations for the
                      purposes of fraud protection and credit risk reduction.
                    </li>
                  </ul>
                </Box>

                {/* Choices About How We Use and Disclose Your Information */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Choices About How We Use and Disclose Your Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We strive to provide you with choices regarding the personal
                    information you provide to us. We have created mechanisms to
                    provide you with the following control over your
                    information:
                  </Typography>
                  <ul>
                    <li>
                      <strong>Tracking Technologies and Advertising.</strong>{" "}
                      You can set your browser to refuse all or some browser
                      cookies, or to alert you when cookies are being sent. To
                      learn how you can manage your Flash cookie settings, visit
                      the Flash player settings page on Adobe’s website. If you
                      disable or refuse cookies, please note that some parts of
                      this site may then be inaccessible or not function
                      properly.
                    </li>
                    <li>
                      We do not control third parties’ collection or use of your
                      information to serve interest-based advertising. However,
                      these third parties may provide you with ways to choose
                      not to have your information collected or used in this
                      way. You can opt out of receiving targeted ads from
                      members of the Network Advertising Initiative (”NAI”) on
                      the NAI’s website.
                    </li>
                  </ul>
                </Box>

                {/* Accessing and Correcting Your Information */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Accessing and Correcting Your Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You can review and change your personal information by
                    logging into the Website and visiting your account profile
                    page.
                  </Typography>
                </Box>

                {/* Your California Privacy Rights */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Your California Privacy Rights
                  </Typography>
                  <Typography variant="body1" paragraph>
                    California Civil Code Section § 1798.83 permits users of our
                    Website that are California residents to request certain
                    information regarding our disclosure of personal information
                    to third parties for their direct marketing purposes. To
                    make such a request, please send an email to{" "}
                    <a href="mailto:info@oculogyx.ai">info@oculogyx.ai</a> or
                    write us at the mailing address below.
                  </Typography>
                </Box>

                {/* Data Security */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Data Security
                  </Typography>
                  <Typography variant="body1" paragraph>
                    We have implemented measures designed to secure your
                    personal information from accidental loss and from
                    unauthorized access, use, alteration, and disclosure. All
                    information you provide to us is stored on our secure
                    servers behind firewalls. Any payment transactions will be
                    encrypted. See Stripe’s website for more information
                    regarding its security measures.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    The safety and security of your information also depends on
                    you. Where we have given you (or where you have chosen) a
                    password for access to certain parts of our Website, you are
                    responsible for keeping this password confidential. We ask
                    you not to share your password with anyone. We urge you to
                    be careful about giving out information in public areas of
                    the Website like message boards. The information you share
                    in public areas may be viewed by any user of the Website.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Unfortunately, the transmission of information via the
                    internet is not completely secure. Although we do our best
                    to protect your personal information, we cannot guarantee
                    the security of your personal information transmitted to our
                    Website. Any transmission of personal information is at your
                    own risk. We are not responsible for circumvention of any
                    privacy settings or security measures contained on the
                    Website.
                  </Typography>
                </Box>

                {/* Changes to Our Privacy Policy */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Changes to Our Privacy Policy
                  </Typography>
                  <Typography variant="body1" paragraph>
                    It is our policy to post any changes we make to our privacy
                    policy on this page. If we make material changes to how we
                    treat our users’ personal information, we will notify you
                    through a notice on the Website home page. The date the
                    privacy policy was last revised is identified at the top of
                    the page. You are responsible for ensuring we have an
                    up-to-date active and deliverable email address for you, and
                    for periodically visiting our Website and this privacy
                    policy to check for any changes.
                  </Typography>
                </Box>

                {/* Contact Information */}
                <Box mb={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    Contact Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    To ask questions or comment about this privacy policy and
                    our privacy practices, contact us at:
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>OCULOGYX INC.</strong>
                    <br />
                    6154 La Jolla Mesa Dr,
                    <br />
                    La Jolla, CA 92037
                    <br />
                    <a href="mailto:info@oculogyx.ai">info@oculogyx.ai</a>
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Container>

          {/* Inline Styles */}
          <Box component="div" sx={{ display: "none" }}>
            <style>{`
            /* Navigation Links Styles */
            .nav-link {
              color: #000;
              text-decoration: none;
              margin-right: 20px;
              font-size: 22px;
              cursor: pointer;
              transition: color 0.3s ease;
            }
            .nav-link.last-nav-link {
              margin-right: 0;
            }
            .nav-link:hover {
              color: #5dc1d8;
            }

            /* Responsive Styles */
            @media (max-width: 768px) {
              .top-nav {
                flex-direction: column;
                align-items: flex-start;
              }
              .top-nav img {
                margin-bottom: 20px;
              }
              .nav-links {
                flex-direction: column;
                align-items: flex-start;
              }
              .nav-link {
                margin-right: 0;
                margin-bottom: 10px;
              }
              .privacy-container {
                padding: 20px;
              }
              .privacy-container h1 {
                font-size: 28px;
              }
              .privacy-container h2 {
                font-size: 24px;
              }
              .privacy-container p {
                font-size: 16px;
              }
            }

            /* Additional Styles for Privacy Policy */
            .privacy-container h1, .privacy-container h2, .privacy-container p {
              color: #000; /* Set text color to black for readability */
            }

            /* Adjust background for readability */
            .privacy-container {
              background-color: #f9f9f9;
              color: #000;
              border-radius: 10px;
              padding: 30px;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
            }

            /* Optional: Style ordered lists */
            .privacy-container ol {
              padding-left: 20px;
            }
            .privacy-container li {
              margin-bottom: 10px;
            }
          `}</style>
          </Box>
        </Box>
      </div>
    </MasterLayout>
  );
};

export default PrivacyPolicyLayout;
