import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  IconButton,
  Link,
  Button,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import mainLogo from "../assets/mainLogo.svg";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: { xs: "#000", md: "#000" },
        color: "white",
        pt: { xs: 4, md: 6 },
        pb: { xs: 2, md: 4 },
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ maxWidth: { xs: "100%", md: "1200px" }, margin: "0 auto", width: "100%" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <img src={mainLogo} alt="Oculogyx" />
              <Box mt={2}>
                <IconButton color="inherit">
                  <XIcon />
                </IconButton>
                <IconButton color="inherit">
                  <InstagramIcon />
                </IconButton>
                <IconButton color="inherit">
                  <FacebookIcon />
                </IconButton>
              </Box>
            </Grid>
            {/* Quick Links */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "500" }}>
                Quick Links
              </Typography>
              <Link href="/contact-us" color="inherit" sx={{ display: "block", mb: 1 }}>
                Contact
              </Link>
              <Link href="/solutions" color="inherit" sx={{ display: "block", mb: 1 }}>
                Solutions
              </Link>
              <Link href="/about-us" color="inherit" sx={{ display: "block" }}>
                About
              </Link>
            </Grid>
            {/* Explore Links */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "500" }}>
                Explore
              </Typography>
              <Link href="/genaeye" color="inherit" sx={{ display: "block", mb: 1 }}>
                GenA Eye™
              </Link>
              <Link href="/image-analysis" color="inherit" sx={{ display: "block", mb: 1 }}>
                Image Analysis
              </Link>
              <Link href="/ophthalmic-scribe" color="inherit" sx={{ display: "block", mb: 1 }}>
                Ophthalmic Scribe
              </Link>
              {/* <Link href="/news-resources" color="inherit" sx={{ display: "block" }}>
                News & Resources
              </Link> */}
            </Grid>
            {/* Newsletter */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: "500" }}>
                Subscribe to our Newsletter!
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="username@email.com"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "25px",
                  mb: 1,
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "25px",
                  },
                }}
              />
              <Button
                variant="text"
                onClick={() => alert("Our newsletter is coming soon!")} // Replace with actual functionality
                sx={{
                  textTransform: "none",
                  textDecoration: "underline",
                  color: "white",
                  cursor: "pointer",
                  fontSize: "20px",
                  display: "block",
                  textAlign: "left",
                  mt: 0,
                  pl: 0, // Ensure alignment starts directly under TextField
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>

          {/* Gradient Divider */}
          <Box
            sx={{
              height: "2px",
              background: "linear-gradient(to right,  #103444, #5dc1d8)",
              mt: 4,
              mb: 2,
            }}
          />

          {/* Copyright Section */}
          <Box textAlign="center" py={2}>
            <Typography variant="caption">
              Copyright © 2025 Oculogyx - Bringing AI to Eye Care |
              <Link href="/privacy-policy" color="inherit" sx={{ ml: 1, mr: 1 }}>
                Privacy Policy
              </Link>
              |
              <Link href="/terms-of-use" color="inherit" sx={{ ml: 1, mr: 1 }}>
                Terms of Use
              </Link>
              |
              <Link href="/terms-and-conditions" color="inherit" sx={{ ml: 1 }}>
                Terms and Conditions
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
