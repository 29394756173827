// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: `'Work Sans', sans-serif`,
    // ...other typography settings
  },
  palette: {
    primary: {
      main: '#007bff',
    },
    // ...other palette settings
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none', // Removes underline by default
      },
      styleOverrides: {
        root: {
          // Optional: Customize link color, hover effects, etc.
          color: '#007bff', // Example: Set link color to primary color
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline', // Underline on hover
            color: '#0056b3', // Darker shade on hover
          },
        },
      },
    },
    // ...other component overrides
  },
});

export default theme;
