import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Hub } from "aws-amplify/utils";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "../App.css";
import OldImageBannerWithText from "../components/OldImageBannerWithText";
import SubscriptionModal from "../components/SubscriptionModal";
import { getSessionJwt } from "../util/utilities";
import { ENDPOINTS } from "../util/constants";
import FileUpload from "../components/FileUpload";
import MasterOnlyLayout from "./MasterOnlyLayout";
import blueEye from "../assets/homePage/section3/blueEye.jpg";
import DropDownButton from "../components/DropDownButton";
import logo from "../assets/mainLogo.svg";

const SuggestedPrompts = ({ setAdditionalPrompt, hidePrompts, prompts }) => {
  const handlePromptClick = (prompt) => {
    setAdditionalPrompt(prompt);
    hidePrompts();
  };

  return (
    prompts.length > 0 && (
      <Box sx={{ textAlign: "center", marginY: 2 }}>
        <Typography variant="h6" gutterBottom>
          Suggested Prompts
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {prompts.map((prompt, index) => (
            <Grid item key={index}>
              <Button
                variant="outlined"
                onClick={() => handlePromptClick(prompt)}
                sx={{
                  minWidth: 200,
                  padding: "15px 20px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#333",
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                {prompt}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};

// Reuse the header categories logic from GptLayout
function populateHeaderCategories(menuItems, isMobile, open, setOpen) {
  const items = menuItems.map((value, index) => (
    <Grid
      key={index}
      item
      xs={value?.subItems?.length ? 2 : value?.text?.length > 10 ? 2 : 1}
      sx={{ textAlign: "center" }}
    >
      <DropDownButton dropDownItem={value} isMobile={isMobile} />
    </Grid>
  ));
  return (
    <>
      {isMobile && (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={logo} alt="Oculogyx Logo" style={{ height: 40 }} />
              </Box>
            </Link>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Button
              disableRipple
              sx={{
                color: "#FFFFFF",
                fontSize: isMobile ? 10 : 14,
                fontFamily: "system-ui",
                fontWeight: 400,
              }}
              onClick={() => setOpen((o) => !o)}
            >
              {`${!open ? "Open" : "Hide"} Menu`}
            </Button>
          </Box>
        </>
      )}
      {((open && isMobile) || !isMobile) && (
        <Grid container spacing={2} justifyContent="center">
          {items}
        </Grid>
      )}
    </>
  );
}

export default function FileToReportLayout(props) {
  const {
    headerText,
    isLoading,
    setIsLoading,
    responseText,
    setResponseText,
    setMessages,
    waitingAfterDone,
    setWaitingAfterDone,
    type,
    maxFiles,
  } = props;

  const [subscriptionStatus, setSubscriptionStatus] = useState("PENDING");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [authActivity, setAuthActivity] = useState(0);
  const [showPrompts, setShowPrompts] = useState(true);
  const [additionalPrompt, setAdditionalPrompt] = useState("");

  // Nav header related
  const [open, setOpen] = useState(false);
  const menuItems = [
    { text: "GenA Eye™", link: "/genaeye" },
    { text: "Image Analysis", link: "/image-analysis" },
    {
      text: "Practice Tools",
      subItems: [
        { text: "Ophthalmic Scribe", link: "/ophthalmic-scribe" },
        { text: "Ophthalmic Triage", link: "/ophthalmic-triage" },
        { text: "Refractive Surgery Advisor", link: "/refractive-surgery" },
        {
          text: "Wavefront Optimized Treatment Plan",
          link: "/wavefront-optimized-treatment-plan",
        },
        {
          text: "Practice Information Agent",
          link: "/practice-information-agent",
        },
        { text: "Ophthalmic Pre-Visit Interview", link: "/previsit-interview" },
      ],
    },
    {
      text: "OPHTHALMIC SCHOLAR",
      link: "/ophthalmic-scholar",
      subItems: [
        { text: "Ophthalmic Study Guide", link: "/ophthalmic-study-guide" },
        { text: "OculogyX Research Assistant", link: "/research-assistant" },
        {
          text: "Ophthalmic Patient History",
          link: "/ophthalmic-patient-history",
        },
      ],
    },
  ];

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const handleOpenSubscriptionModal = (e) => {
    e.preventDefault();
    setIsSubscriptionModalOpen(true);
  };
  const handleCloseSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false);
  };
  const handleSubscriptionSelect = (selectedSubscription) => {
    createCheckoutAndRedirect(selectedSubscription.name);
  };

  // Retrieve custom prompts based on headerText
  function getCustomPrompts(headerText) {
    switch (headerText) {
      case "Image Analysis":
        return [
          "Analyze & Report image",
          "Analyze Multiple Images and Report Changes",
          "Analyze a Document",
          "Merge Documents",
        ];
      case "Ophthalmic Scribe":
        return [
          "Full Chart Note",
          "SOAP Follow up Note",
          "Dictated Note",
          "Letter to referring doctor",
          "Note to Staff",
          "Full Chart Note, Letter to Referring Doctor, and Note to Staff",
        ];
      case "Refractive Surgery Advisor":
        return [
          "Am I candidate for PRK?",
          "Am I a candidate for Lasik?",
          "Am I a candidate to ICL?",
          "Should I consider a Refractive Lens Exchange?",
        ];
      case "Wavefront Optimized Treatment Plan":
        return [
          "Distance Correction OU",
          "Monovision Near OD",
          "Monovision Near OS",
          "Near Target =",
        ];
      default:
        return [];
    }
  }

  // Generate custom text for file uploads based on headerText
  function getCustomUploadText(headerText) {
    switch (headerText) {
      case "Wavefront Optimized Treatment Plan":
        return "Import Pentacam Maps, PDFs of Clinic Chart Notes";
      case "Refractive Surgery Advisor":
        return "Upload Pentacam Scans, Autorefraction, and Argos";
      default:
        return "";
    }
  }

  const customPrompts = getCustomPrompts(headerText);
  const customText = getCustomUploadText(headerText);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setSubscriptionStatus("Refresh page!");
        return;
      }
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/get-subscription-status`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      if (!data.results) {
        console.error(
          "Error fetching subscription status. Message:",
          data.message
        );
        setSubscriptionStatus("Error. Refresh page!");
        return;
      }
      setSubscriptionStatus(data.results.subscriptionStatus);
    };
    fetchSubscriptionStatus();
  }, [authActivity]);

  useEffect(() => {
    const hubListenerCancel = Hub.listen("auth", (data) => {
      setAuthActivity((prev) => prev + 1);
      if (data.payload.event === "signedIn") {
        hubListenerCancel();
      }
    });
  }, []);

  useEffect(() => {
    if (responseText && waitingAfterDone && isLoading) {
      const apiMessage = {
        text: responseText,
        sender: "gpt",
      };
      setMessages((prevMessages) => [...prevMessages, apiMessage]);
      setResponseText("");
      setWaitingAfterDone(false);
      setIsLoading(false);
    }
  }, [waitingAfterDone]);

  const createCheckoutAndRedirect = async (selectedSubscriptionName) => {
    try {
      const jwtToken = await getSessionJwt();
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/create-checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ subscriptionName: selectedSubscriptionName }),
        }
      );

      const data = await response.json();

      if (data.results?.checkoutURL) {
        window.location.href = data.results.checkoutURL;
      } else {
        console.error(
          "Error creating checkout: Missing checkout URL in response."
        );
      }
    } catch (error) {
      console.error("Error creating checkout. Message:", error);
    }
  };

  return (
    <MasterOnlyLayout>
      {/* Top Nav / Header from GptLayout */}
      <div
        style={{
          background: "#e0f7fa",
          minHeight: "80vh",
          position: "relative",
        }}
      >
        {!isMobile ? (
          <AppBar position="static" sx={{ background: "#103444" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                <Link to="/" style={{ display: "inline-block" }}>
                  <img src={logo} alt="Oculogyx Logo" style={{ width: 150 }} />
                </Link>
              </Box>
              {populateHeaderCategories(menuItems, isMobile, open, setOpen)}
            </Toolbar>
          </AppBar>
        ) : (
          <Box sx={{ bgcolor: "#103444" }}>
            <Container maxWidth="mx">
              {populateHeaderCategories(menuItems, isMobile, open, setOpen)}
            </Container>
          </Box>
        )}

        <OldImageBannerWithText
          header={headerText}
          subHeader="An AI digital assistant for Ophthalmic Care professionals."
          imgLink={blueEye}
          imgAltText="EyeGpt"
          height={isMobile ? 95 : 150}
        />

        <Authenticator>
          {({ signOut, user }) => (
            <main>
              <SubscriptionModal
                open={isSubscriptionModalOpen}
                onClose={handleCloseSubscriptionModal}
                onSubscriptionSelect={handleSubscriptionSelect}
              />

              <Grid container alignItems="center">
                {/* Sidebar */}
                <Grid
                  item
                  xs={12}
                  sm={1.5}
                  sx={{
                    textAlign: "center",
                    bgcolor: "#E1D7CB",
                  }}
                >
                  <div
                    className={!isMobile ? "gpt-sidebar-container" : ""}
                    style={{
                      height: isMobile ? "auto" : "calc(100vh - 236px)",
                    }}
                  >
                    <br />
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ fontSize: "1.3rem" }}
                    >
                      Welcome back, <b>{user.username}</b>
                    </Typography>
                    <br />
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ fontSize: "1.2rem" }}
                    >
                      Subscription Status:{" "}
                      <b
                        style={{
                          color:
                            subscriptionStatus === true
                              ? "green"
                              : subscriptionStatus === false
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {subscriptionStatus === true
                          ? "Active"
                          : subscriptionStatus === false
                          ? "Inactive"
                          : subscriptionStatus}
                      </b>
                    </Typography>
                    <br />
                    {subscriptionStatus === true && (
                      <Button
                        href="https://billing.stripe.com/p/login/3csaHQcOy9G2g6c6oo"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          background: "#103444",
                          color: "white",
                          fontWeight: "bold",
                          marginTop: "20px",
                          fontSize: "1.1rem",
                          "&:hover": {
                            bgcolor: "#0a1e30",
                          },
                        }}
                      >
                        Manage Subscription
                      </Button>
                    )}
                    {subscriptionStatus === false && (
                      <Button
                        sx={{
                          background: "#103444",
                          color: "white",
                          fontWeight: "bold",
                          marginTop: "20px",
                          fontSize: "1.1rem",
                          "&:hover": {
                            bgcolor: "#0a1e30",
                          },
                        }}
                        onClick={handleOpenSubscriptionModal}
                      >
                        Get a Subscription
                      </Button>
                    )}
                    <Button
                      sx={{
                        bgcolor: "darkred",
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "20px",
                        fontSize: "1.1rem",
                        "&:hover": {
                          bgcolor: "#460000",
                        },
                      }}
                      onClick={signOut}
                    >
                      Sign Out
                    </Button>
                  </div>
                </Grid>

                {/* Main Area */}
                <Grid
                  item
                  xs={12}
                  sm={10.5}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    bgcolor: "white",
                    padding: "10px"
                  }}
                >
                  <Grid>
                    <Typography
                      variant={!isMobile ? "h4" : "h5"}
                      gutterBottom
                      sx={{ p: "20px" }}
                    >
                      Generate a report by uploading your appropriate file(s).
                    </Typography>

                    {/* File Upload */}
                    <FileUpload
                      type={type ?? "image"}
                      maxFiles={maxFiles}
                      additionalPrompt={additionalPrompt}
                      setAdditionalPrompt={setAdditionalPrompt}
                      customText={customText}
                    />

                    {!isMobile && showPrompts && (
                      <SuggestedPrompts
                        setAdditionalPrompt={setAdditionalPrompt}
                        hidePrompts={() => setShowPrompts(false)}
                        prompts={customPrompts}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </main>
          )}
        </Authenticator>
      </div>
    </MasterOnlyLayout>
  );
}
