import React, { useState } from "react";
import axios from "axios";
import MasterLayout from "./MasterLayout";
import { Box, Grid, TextField, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import mainLogo from "../assets/mainLogo.svg";
import { ENDPOINTS } from "../util/constants";
import { getSessionJwt } from "../util/utilities";

const ContactUsLayout = () => {
  // Form states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [message, setMessage] = useState("");

  // State for handling email sending
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  // Navigation link styles
  const navLinkStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    fontSize: "22px",
    cursor: "pointer",
    transition: "color 0.3s ease",
  };
  const navLinkHoverStyle = {
    color: "#5dc1d8",
  };

  const handleNavLinkHover = (e) => {
    e.target.style.color = navLinkHoverStyle.color;
  };
  const handleNavLinkOut = (e) => {
    e.target.style.color = navLinkStyle.color;
  };

  // MAIN: handleSendEmail
  const handleSendEmail = async () => {
    // Basic validation
    if (!email) {
      alert("Please enter an email address");
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address");
      return;
    }

    try {
      setIsSendingEmail(true);
      // Build the form data
      const formData = new FormData();
      formData.append("fromEmail", email);
      formData.append("textContent", message);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("phoneNo", phoneNo);

      const jwtToken = await getSessionJwt();

      // Make the POST request
      const response = await axios.post(
        `${ENDPOINTS.EC2_ENDPOINT}/assistants/contact-email`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        // alert("Email sent successfully");
        // Clear fields
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNo("");
        setMessage("");
      } else {
        // alert("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // alert("An error occurred while sending the email");
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <MasterLayout>
      <div style={{ backgroundColor: "black" }}>
        <div
          style={{
            backgroundImage: "linear-gradient(to bottom, #103444, #5dc1d8)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            maxWidth: "1200px",
            margin: "0 auto",
            paddingBottom: "50px",
          }}
        >
          {/* Top Nav */}
          <div
            className="top-nav"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "30px",
              maxWidth: "1200px",
              margin: "0 auto",
              position: "relative",
              padding: "50px",
              paddingTop: "20px",
              zIndex: 1,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "10px",
                background: "linear-gradient(to right, #5dc1d8, #103444)",
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 1000,
              }}
            ></div>
            <Link to="/" style={{ display: "inline-block" }}>
              <img src={mainLogo} alt="Oculogyx Logo" style={{ height: 100 }} />
            </Link>
            <div className="nav-links" style={{ zIndex: 1 }}>
              <Link
                to="/"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Home
              </Link>
              <Link
                to="/about-us"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                About
              </Link>
              <Link
                to="/solutions"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Solutions
              </Link>
              {/* <Link
                to="/news-resources"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                News & Resources
              </Link> */}
              <Link
                to="/contact-us"
                style={{ ...navLinkStyle, marginRight: "0" }}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Contact
              </Link>
            </div>
          </div>

          {/* Contact Form Section */}
          <div style={{ padding: "50px 0" }}>
            <Box
              className="contact-form-box"
              sx={{
                maxWidth: "800px",
                margin: "0 auto",
                backgroundImage:
                  "linear-gradient(to bottom, #ffffff, #f4f4f4, #e6e6e6)",
                borderRadius: "12px",
                padding: "40px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography
                variant="h4"
                align="center"
                sx={{ fontWeight: "500", marginBottom: "20px", color: "#000" }}
              >
                Contact a
                <span
                  style={{
                    background: "linear-gradient(to right, #5dc1d8, #103444)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "500",
                    margin: "0 8px",
                  }}
                >
                  Gen A Eye™
                </span>
                Expert and Get Started Today
              </Typography>

              {/* Form Fields */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                    sx={{
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderImageSource:
                            "linear-gradient(to right, #5dc1d8, #103444)",
                          borderImageSlice: 1,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                    sx={{
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderImageSource:
                            "linear-gradient(to right, #5dc1d8, #103444)",
                          borderImageSlice: 1,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    sx={{
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderImageSource:
                            "linear-gradient(to right, #5dc1d8, #103444)",
                          borderImageSlice: 1,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    fullWidth
                    sx={{
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderImageSource:
                            "linear-gradient(to right, #5dc1d8, #103444)",
                          borderImageSlice: 1,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    fullWidth
                    sx={{
                      borderRadius: "8px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderImageSource:
                            "linear-gradient(to right, #5dc1d8, #103444)",
                          borderImageSlice: 1,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Dark -> Gradient Hover Button */}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Button
                  variant="contained"
                  onClick={handleSendEmail}
                  disabled={isSendingEmail}
                  sx={{
                    // 1) Default dark background
                    backgroundColor: "#103444",
                    color: "#fff",
                    borderRadius: "30px",
                    position: "relative",
                    overflow: "hidden",
                    padding: "12px 30px",
                    fontSize: "16px",
                    fontWeight: "500",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                    // Force MUI to not override background on hover
                    "&:hover": {
                      backgroundColor: "#103444",
                    },
                    // 2) Overlay fade-in on hover
                    "&:hover .btn-overlay": {
                      opacity: 1,
                    },
                  }}
                >
                  <span style={{ position: "relative", zIndex: 2 }}>
                    {isSendingEmail ? "Sending..." : "Submit"}
                  </span>
                  <Box
                    className="btn-overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "linear-gradient(to right, #5dc1d8, #103444)",
                      opacity: 0,
                      transition: "opacity 0.3s ease",
                      zIndex: 1,
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>

      {/* Responsive Styles */}
      <style>{`
        @media (max-width: 768px) {
          .contact-form-box {
            padding: 20px !important;
          }
          .top-nav {
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
          }
          .nav-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
          }
          .nav-links a {
            margin-right: 0;
            margin-bottom: 10px;
            font-size: 18px;
          }
        }
      `}</style>
    </MasterLayout>
  );
};

export default ContactUsLayout;
