import React from "react";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Box from "@mui/material/Box";

export default function DropDownButton({ dropDownItem, isMobile }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const hrefLink = () => {
    if (
      (dropDownItem.subItems && open) ||
      !dropDownItem.subItems ||
      !isMobile
    ) {
      if (dropDownItem.hasOwnProperty("link")) return dropDownItem.link;
      else return "";
    }
    return "#";
  };

  return (
    <div onMouseEnter={openMenu} onMouseLeave={closeMenu}>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        href={hrefLink()}
        disableRipple
        sx={{
          color: "#FFFFFF",
          fontSize: isMobile ? 10 : 18,
          fontFamily: "system-ui",
          fontWeight: 400,
          "&:hover": {
            backgroundColor: "#195570",
          },
        }}
      >
        {dropDownItem.text}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
                sx={{
                  backgroundColor: "#103444",
                }}
                disablePadding={true}
              >
                {populateSubItems(dropDownItem.subItems, isMobile)}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

function populateSubItems(subItems, isMobile) {
  const elements = subItems;
  const items = [];

  if (elements && elements.length > 0) {
    for (const [index, value] of elements.entries()) {
      items.push(
        <Box sx={{ height: isMobile && "30px", alignContent: "center" }}>
          <a
            href={value.hasOwnProperty("link") ? value.link : ""}
            key={index}
            style={{
              textDecoration: "none", // Add this line to remove underline from the link
              color: "inherit", // Optional: to ensure the link color matches the MenuItem
              maxHeight: "30px",
            }}
          >
            <MenuItem
              sx={{
                color: "#FFFFFF",
                fontSize: isMobile ? 10 : 18,
                fontFamily: "system-ui",
                textTransform: "none",
                fontWeight: 400,
                textDecoration: "none", // Add this line to remove underline
                "&:hover": {
                  backgroundColor: "#195570",
                },
              }}
            >
              {value.text}
            </MenuItem>
          </a>
        </Box>
      );
    }
  }

  return <div style={{ background: "#103444" }}>{items}</div>;
}
