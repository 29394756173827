import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom"; // Use React Router's Link directly
import MasterLayout from "./MasterLayout";
import ImageBannerWithText from "../components/ImageBannerWithText";
import mainLogo from "../assets/mainLogo.svg";
import gradient from "../assets/homePage/section1/gradient.png";

const PersonElement = (props) => (
  <Box
    sx={{
      textAlign: "center",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#fff",
    }}
  >
    <img
      src={props.imgLink}
      alt={props.imgAltText}
      style={{
        height: "210px",
        borderRadius: "8px",
        marginBottom: "16px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
        backgroundColor: "white",
      }}
    />
    <Typography variant="h6" sx={{ fontWeight: "500", marginBottom: "8px" }}>
      {props.name}
    </Typography>
    <Typography variant="body1">{props.title}</Typography>
    <Box sx={{ textAlign: "left", padding: "0 16px", marginTop: "8px" }}>
      <Typography variant="body2">{props.description}</Typography>
    </Box>
  </Box>
);

const PersonElementHolder = ({ peopleDataArray }) => {
  const [itemsPerRow, setItemsPerRow] = useState(3);

  useEffect(() => {
    const calculateItemsPerRow = () => {
      const width = window.innerWidth;
      if (width < 600) setItemsPerRow(1);
      else if (width < 900) setItemsPerRow(2);
      else setItemsPerRow(3);
    };
    calculateItemsPerRow();
    window.addEventListener("resize", calculateItemsPerRow);
    return () => window.removeEventListener("resize", calculateItemsPerRow);
  }, []);

  return (
    <>
      {peopleDataArray.map((personData, index) => (
        <Grid
          key={index}
          item
          xs={12 / itemsPerRow}
          sm={12 / itemsPerRow}
          md={12 / itemsPerRow}
        >
          <PersonElement {...personData} />
        </Grid>
      ))}
    </>
  );
};

const AboutUsLayout = () => {
  const PEOPLE_DATA = [
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/david-schanzlin.jpg",
      imgAltText: "David J. Schanzlin MD",
      name: "David J. Schanzlin MD",
      title: "Chairman & Founder",
      description: (
        <>
          <b>Dr. David Schanzlin, MD</b>, Chairman and Founder, an Ophthalmology
          Professor Emeritus, has an illustrious career. Currently, a senior
          partner at <i>Gordon Schanzlin New Vision Institute</i> in La Jolla,
          California, Dr. Schanzlin boasts over 40 years of experience in
          clinical and research Ophthalmology including over three decades of
          academic ophthalmology. He's recognized worldwide as an esteemed
          educator and a leading researcher in Cornea and Refractive surgery.
          His advisory roles with various start-up companies underscore his
          expertise, while his active involvement in applying AI to clinical
          ophthalmology highlights his understanding of the eye care community's
          unique complexities.
        </>
      ),
    },
  ];

  const CONSULTANT_DATA = [
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/mike-kemper.jpg",
      imgAltText: "Michael Kemper",
      name: "Michael Kemper",
      title: "Lead Programmer",
      description: (
        <>
          <b>Michael Kemper</b> is an experienced software engineer lead with a
          strong background in web development, cloud computing, and AI
          technologies. Michael excels in <i>JavaScript</i> and <i>Python</i>,
          and is currently focusing on integrating machine learning capabilities
          into web applications.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/alex-handzel.jpg",
      imgAltText: "Alex Handzel",
      name: "Alex Handzel",
      title: "Lead Machine Learning Programmer",
      description: (
        <>
          <b>Alex Handzel</b> is a seasoned Machine Learning Engineer with a
          robust background in developing and deploying machine learning models.
          He has a proven track record of leveraging data-driven approaches to
          solve complex problems and enhance decision-making processes.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/john-kemper.jpg",
      imgAltText: "John Kemper",
      name: "John Kemper",
      title: "Lead Webmaster",
      description: (
        <>
          <b>John Kemper</b> is a Software Engineer specializing in web
          development and full-stack programming. He has extensive experience
          with <i>React</i> and <i>JavaScript</i>, and has contributed to
          numerous high-performance websites and applications.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/victoria-hallberg.jpeg",
      imgAltText: "Victoria Hallberg",
      name: "Victoria Hallberg",
      title: "Chief Growth Officer",
      description: (
        <>
          <b>Victoria Hallberg</b> is a seasoned executive with 35 years of
          vision industry experience primarily focused on go-to-market strategy,
          optical retail strategy, sales and marketing leadership. Prior to
          joining Oculogyx.ai, Victoria held leadership positions with{" "}
          <i>DigitalOptometrics</i>, <i>Safilo Group</i>, <i>VSP</i> and{" "}
          <i>Marchon Eyewear</i>. A dynamic and highly motivated and
          market-driven leader, throughout her career, Victoria has successfully
          delivered impactful, strategic business growth programs to sales
          teams, optical retail chains and independent eyecare professionals,
          positively impacting their business.
        </>
      ),
    },
  ];

  const MEDICAL_ADVISORY_BOARD_DATA = [
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/julio-echegoyen.jpg",
      imgAltText: "Julio Echegoyen, MD, PhD",
      name: "Julio Echegoyen, MD, PhD",
      title: "",
      description: (
        <>
          <b>Dr. Julio Echegoyen</b> is a cataract, cornea, anterior segment,
          and refractive surgeon at <i>Gordon Schanzlin New Vision Institute</i>
          . He completed his medical education and training at <i>UC Irvine</i>{" "}
          and <i>UC San Diego</i>.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/melvani-rakhi.jpg",
      imgAltText: "Rakhi Melvani, MD",
      name: "Rakhi Melvani, MD",
      title: "",
      description: (
        <>
          <b>Dr. Rakhi Melvani</b> is a cataract, cornea, anterior segment, and
          refractive surgeon at <i>Gordon Schanzlin New Vision Institute</i>.
          She completed her medical education at{" "}
          <i>Virginia Commonwealth University</i> and her residency at{" "}
          <i>Wills Eye Hospital</i> in Philadelphia.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/alyssa-pack.jpg",
      imgAltText: "Alyssa Pack, OD",
      name: "Alyssa Pack, OD",
      title: "",
      description: (
        <>
          <b>Dr. Alyssa Pack</b> is an owner of Eye Care at the Cove in La
          Jolla, CA. She completed her undergraduate degree in Biological
          Sciences at <i>University of California Irvine</i> and graduated from
          the <i>Southern California College of Optometry</i> with Summa Cum
          Laude honors.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/michael-ammar.jpg",
      imgAltText: "Michael Ammar, MD",
      name: "Michael Ammar, MD",
      title: "",
      description: (
        <>
          <b>Dr. Michael Ammar</b> is a vitreoretinal surgeon at{" "}
          <i>Retina Consultants of San Diego</i>. He received his medical degree
          from the <i>University of Southern California</i> and did his
          fellowship at the <i>Wills Eye Hospital</i> in Philadelphia.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/david-geffen.jpeg",
      imgAltText: "David Geffen, OD",
      name: "David Geffen, OD",
      title: "",
      description: (
        <>
          <b>Dr. David Geffen</b> is the senior optometrist at{" "}
          <i>Gordon Schanzlin New Vision Institute</i>. He earned his Doctor of
          Optometry from <i>UC Berkeley</i> and has extensive experience in
          optical correction and complicated contact lens fitting.
        </>
      ),
    },
  ];

  // Define reusable style objects for navigation links
  const navLinkStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    fontSize: "22px",
    cursor: "pointer", // Ensures pointer cursor
    transition: "color 0.3s ease", // Smooth color transition
  };

  const navLinkHoverStyle = {
    color: "#5dc1d8", // Color on hover
  };

  // Function to handle hover effect
  const handleNavLinkHover = (e) => {
    e.target.style.color = navLinkHoverStyle.color;
  };

  const handleNavLinkOut = (e) => {
    e.target.style.color = navLinkStyle.color;
  };

  return (
    <MasterLayout>
      <Box sx={{ backgroundColor: "black" }}>
        <Box
          sx={{
            backgroundImage: `url(${gradient})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            maxWidth: "1200px",
            // padding: "50px",
            margin: "0 auto",
          }}
        >
          {/* Top Navigation */}
          <Box
            className="top-nav"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "30px",
              maxWidth: "1200px",
              margin: "0 auto",
              position: "relative",
              padding: "50px",
              paddingTop: "20px",
              zIndex: 1001, // Ensure nav is above gradient bar
            }}
          >
            {/* Gradient Bar */}
            <Box
              sx={{
                width: "100%",
                height: "10px",
                background: "linear-gradient(to right, #5dc1d8, #103444)",
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 1000, // Gradient bar below the nav
              }}
            ></Box>

            {/* Logo */}
            <Link to="/" style={{ display: "inline-block" }}>
              <img src={mainLogo} alt="Oculogyx Logo" style={{ height: 100 }} />
            </Link>

            {/* Navigation Links */}
            <Box
              className="nav-links"
              sx={{
                display: "flex",
                alignItems: "center",
                zIndex: 1001,
              }}
            >
              <Link
                to="/"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Home
              </Link>
              <Link
                to="/about-us"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                About
              </Link>
              <Link
                to="/solutions"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Solutions
              </Link>
              {/* <Link
                to="/news-resources"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                News & Resources
              </Link> */}
              <Link
                to="/contact-us"
                style={{ ...navLinkStyle, marginRight: "0" }} // Remove margin on last link
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Contact
              </Link>
            </Box>
          </Box>

          {/* Image Banner */}
          <ImageBannerWithText
  header="Oculogyx's Mission"
  subHeader="Oculogyx leverages artificial intelligence to streamline the daily practices of ophthalmologists and optometrists. Our suite of tools enhances practice efficiency, compliance, revenue, and patient experience, while also supporting physicians' academic endeavors through our educational programs and Scholar Assistants."
  imgLink="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/eye-model-3d.jpeg"
  imgAltText="About Us"
  height="400px" // Adjusted height for a shorter banner
/>


          {/* Main Content */}
          <Box
            sx={{
              backgroundImage: "linear-gradient(to bottom, #103444, #5dc1d8)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "50px",
              margin: "0 auto",
              maxWidth: "1200px",
              // borderRadius: "12px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.5)",
              color: "#fff",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "500",
                color: "#fff",
                textAlign: "center",
                mt: 4,
              }}
            >
              About Oculogyx
            </Typography>

            {/* Mission Section */}
            <Box sx={{ mt: 6 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#fff",
                  textAlign: "left",
                  mb: 2,
                }}
              >
                Mission
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "400",
                  color: "#fff",
                  textAlign: "left",
                }}
              >
                Oculogyx is remaking eye care through its transformative suite of
                artificial intelligence solutions. Built specifically for the
                ophthalmology and optometry fields, Oculogyx addresses some of the
                most pressing challenges in modern eye care: administrative
                inefficiencies, clinician burnout, and the need for data-driven
                insights. By empowering eye care professionals with cutting-edge
                technology, Oculogyx enhances practice efficiency, improves
                patient outcomes, and reshapes how eye care is delivered globally.
              </Typography>
            </Box>

            {/* Vision Section */}
            <Box sx={{ mt: 6 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#fff",
                  textAlign: "left",
                  mb: 2,
                }}
              >
                Vision
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: "400",
                  color: "#fff",
                  textAlign: "left",
                }}
              >
                Oculogyx envisions a future where every ophthalmologist and
                optometrist can access a seamless AI ecosystem tailored to their
                clinical and administrative needs. The expanded scope introduces
                three groundbreaking products:
              </Typography>
              <Box component="ol" sx={{ paddingLeft: "20px", color: "#fff" }}>
                <li>
                  <b>The GenA™ Eye Office</b> – An AI-powered engine streamlining
                  clinical workflows, documentation, and patient triage.
                </li>
                <li>
                  <b>The Ophthalmic DataBank</b> is a centralized, quaryable clinical
                  data repository that supports research, education, and real-time
                  decision-making.
                </li>
                <li>
                  <b>The Autonomous Ophthalmic Office</b> – A fully AI-driven
                  practice model automating diagnostics, patient flow, and
                  administrative tasks for unmatched efficiency.
                </li>
              </Box>
            </Box>

            {/* Meet Our Team */}
            <Typography
              variant="h4"
              sx={{
                fontWeight: "500",
                color: "#fff",
                textAlign: "center",
                mt: 6,
              }}
            >
              Meet Our Team
            </Typography>
            <Grid container spacing={3} sx={{ mt: 4 }} justifyContent="center">
              <PersonElementHolder peopleDataArray={PEOPLE_DATA} />
            </Grid>

            {/* Consultants */}
            <Typography
              variant="h4"
              sx={{
                fontWeight: "500",
                color: "#fff",
                textAlign: "center",
                mt: 6,
              }}
            >
              Consultants
            </Typography>
            <Grid container spacing={3} sx={{ mt: 4 }} justifyContent="center">
              <PersonElementHolder peopleDataArray={CONSULTANT_DATA} />
            </Grid>

            {/* Medical Advisory Board */}
            <Typography
              variant="h4"
              sx={{
                fontWeight: "500",
                color: "#fff",
                textAlign: "center",
                mt: 6,
              }}
            >
              Medical Advisory Board
            </Typography>
            <Grid container spacing={3} sx={{ mt: 4 }} justifyContent="center">
              <PersonElementHolder
                peopleDataArray={MEDICAL_ADVISORY_BOARD_DATA}
              />
            </Grid>

            {/* Closing Statement */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: "500",
                color: "#fff",
                textAlign: "center",
                mt: 6,
              }}
            >
              Thank you for your interest in Oculogyx. We are committed to
              revolutionizing ophthalmic healthcare through AI solutions.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Responsive Styles */}
      <style>{`
        @media (max-width: 768px) {
          .section1-content {
            padding: 20px !important;
          }
          .top-nav {
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
          }
          .nav-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
          }
          .nav-links a {
            margin-right: 0;
            margin-bottom: 10px;
            font-size: 18px;
          }
          .video-text-section {
            flex-direction: column;
          }
          .hero-text,
          .hero-video {
            max-width: 100%;
            margin-bottom: 20px;
          }
          .video-overlay h3 {
            font-size: 16px;
          }
          .video-overlay p {
            font-size: 24px;
          }
          .white-card {
            padding: 20px;
            margin-top: 20px;
          }
          .white-card h2 {
            font-size: 24px;
          }
        }
      `}</style>
    </MasterLayout>
  );
};

export default AboutUsLayout;
