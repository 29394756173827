import React from "react";
import { Typography, useMediaQuery } from "@mui/material";

export default function OldImageBannerWithText({
  header,
  subHeader,
  imgLink,
  imgAltText,
  height = 680,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div style={{ height, width: "100%", position: "relative" }}>
      <img
        src={imgLink}
        alt={imgAltText}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
      />
      <div
        style={{
          position: "absolute",
          inset: 0,
          background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            px: isMobile ? "20px" : "150px",
            paddingBottom: isMobile ? "5px" : "30px",
            fontWeight: 500,
            color: "white",
            fontSize: { xs: "20px", md: "36px", lg: "46px" },
            textShadow: "3px 3px 10px rgba(0, 0, 0, 0.8)", // Enhanced drop shadow
          }}
        >
          {header}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            px: isMobile ? "20px" : "150px",
            color: "white",
            fontSize: { xs: "16px", md: "25px", lg: "25px" },
            textShadow: "3px 3px 10px rgba(0, 0, 0, 0.8)", // Enhanced drop shadow
          }}
        >
          {subHeader}
        </Typography>
      </div>
    </div>
  );
}
