import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import MasterLayout from "./MasterLayout";
import mainLogo from "../assets/mainLogo.svg";
import { getSessionJwt } from "../util/utilities";
import { ENDPOINTS } from "../util/constants";
import SubscriptionModal from "../components/SubscriptionModal";

const ClientLoginLayout = () => {
  // State for handling subscription modal
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("PENDING");
  const isMobile = useMediaQuery("(max-width:600px)");

  // React Router's navigation hook
  const navigate = useNavigate();

  // Navigation link styles
  const navLinkStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    fontSize: "22px",
    cursor: "pointer",
    transition: "color 0.3s ease",
  };
  const navLinkHoverStyle = {
    color: "#5dc1d8",
  };

  const handleNavLinkHover = (e) => {
    e.target.style.color = navLinkHoverStyle.color;
  };
  const handleNavLinkOut = (e) => {
    e.target.style.color = navLinkStyle.color;
  };

  // Handle opening and closing the subscription modal
  const handleOpenSubscriptionModal = () => {
    setIsSubscriptionModalOpen(true);
  };
  const handleCloseSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false);
  };
  const handleSubscriptionSelect = (selectedSubscription) => {
    createCheckoutAndRedirect(selectedSubscription.name);
  };

  // Fetch subscription status
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setSubscriptionStatus("Refresh page!");
        return;
      }
      try {
        const response = await fetch(
          `${ENDPOINTS.EC2_ENDPOINT}/subscription/get-subscription-status`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        const data = await response.json();
        if (!data.results) {
          console.error(
            "Error fetching subscription status. Message:",
            data.message
          );
          setSubscriptionStatus("Error. Refresh page!");
          return;
        }
        setSubscriptionStatus(data.results.subscriptionStatus);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
        setSubscriptionStatus("Error. Refresh page!");
      }
    };
    fetchSubscriptionStatus();
  }, []);

  const createCheckoutAndRedirect = async (selectedSubscriptionName) => {
    try {
      const jwtToken = await getSessionJwt();
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/create-checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ subscriptionName: selectedSubscriptionName }),
        }
      );

      const data = await response.json();

      if (data.results?.checkoutURL) {
        window.location.href = data.results.checkoutURL;
      } else {
        console.error(
          "Error creating checkout: Missing checkout URL in response."
        );
      }
    } catch (error) {
      console.error("Error creating checkout. Message:", error);
    }
  };

  // Component to handle redirection after successful sign-in
  const AuthRedirect = ({ user }) => {
    useEffect(() => {
      if (user) {
        navigate("/genaeye");
      }
    }, [user, navigate]);

    return null;
  };

  return (
    <MasterLayout>
      <div style={{ backgroundColor: "black" }}>
        <div
          style={{
            backgroundImage: "linear-gradient(to bottom, #103444, #5dc1d8)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            maxWidth: "1200px",
            margin: "0 auto",
            paddingBottom: "50px",
          }}
        >
          {/* Top Navigation Bar */}
          <AppBar position="static" sx={{ background: "#103444" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                <Link to="/" style={{ display: "inline-block" }}>
                  <img src={mainLogo} alt="Oculogyx Logo" style={{ height: 100 }} />
                </Link>
              </Box>
              <Box className="nav-links" sx={{ display: "flex", alignItems: "center" }}>
                <Link
                  to="/"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Home
                </Link>
                <Link
                  to="/about-us"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  About
                </Link>
                <Link
                  to="/solutions"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Solutions
                </Link>
                <Link
                  to="/billing"
                  style={{ ...navLinkStyle, marginRight: "0" }}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Billing
                </Link>
              </Box>
            </Toolbar>
          </AppBar>

          {/* Main Login Section */}
          <Box
            sx={{
              padding: "50px 20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "60vh",
              backgroundColor: "#f4f4f4",
            }}
          >
            <Box
              sx={{
                maxWidth: "700px",
                width: "100%",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                padding: "40px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography
                variant="h4"
                align="center"
                sx={{ fontWeight: "500", marginBottom: "20px", color: "#103444" }}
              >
                Client Login
              </Typography>

              <Authenticator>
                {({ signOut, user }) => (
                  <Box>
                    {/* Redirect component */}
                    <AuthRedirect user={user} />

                    {/* Authenticated User Content */}
                    <Typography variant="subtitle1" gutterBottom>
                      Welcome, {user.username}!
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={signOut}
                      sx={{
                        mt: 2,
                        backgroundColor: "#103444",
                        "&:hover": {
                          backgroundColor: "#0a1e30",
                        },
                      }}
                    >
                      Sign Out
                    </Button>
                  </Box>
                )}
              </Authenticator>

              {/* Link to Billing Page */}
              <Box sx={{ textAlign: "center", marginTop: "30px" }}>
                <Typography variant="body1" sx={{ color: "#555" }}>
                  Don't have an account?{" "}
                  <Link to="/billing" style={{ color: "#5dc1d8", textDecoration: "none" }}>
                    See Billing
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </div>
      </div>

      {/* Responsive Styles */}
      <style>{`
        @media (max-width: 768px) {
          .nav-links {
            flex-direction: column;
            align-items: center;
          }
          .nav-links a {
            margin-right: 0;
            margin-bottom: 10px;
            font-size: 18px;
          }
        }
      `}</style>
    </MasterLayout>
  );
};

export default ClientLoginLayout;
