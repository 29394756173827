import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, Box, useMediaQuery } from "@mui/material";
import { getSessionJwt } from "../util/utilities";
import { ENDPOINTS } from "../util/constants";
import ImageBannerWithText from "../components/ImageBannerWithText";

const AdminUsageLayout = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");

  // Fetch *all* usage data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const jwtToken = await getSessionJwt();
        if (!jwtToken) {
          setLoading(false);
          return;
        }

        const response = await fetch(`${ENDPOINTS.EC2_ENDPOINT}/usage`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        const jsonData = await response.json();
        // Ensure the data is an array
        if (Array.isArray(jsonData)) {
          setData(jsonData);
          setFilteredData(jsonData);
        } else {
          console.error("Expected data to be an array but received:", jsonData);
          setData([]);
          setFilteredData([]);
        }
      } catch (error) {
        console.error("Error fetching usage data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Debounced search effect
  useEffect(() => {
    // If searchTerm is blank, we can restore to all data or re-fetch /usage
    if (!searchTerm.trim()) {
      setFilteredData(data);
      return;
    }

    // Otherwise, debounce the search call
    const debounceTimer = setTimeout(async () => {
      try {
        const jwtToken = await getSessionJwt();
        if (!jwtToken) return;

        // Call the /usage/search endpoint with the term query param
        const response = await fetch(
          `${ENDPOINTS.EC2_ENDPOINT}/usage/search?term=${encodeURIComponent(
            searchTerm
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (!response.ok) {
          console.error("Search request failed:", response.statusText);
          return;
        }

        const jsonData = await response.json();
        setFilteredData(jsonData);
      } catch (error) {
        console.error("Error searching usage data:", error);
      }
    }, 500);

    // Cleanup the timeout if user types again before 500 ms
    return () => clearTimeout(debounceTimer);
  }, [searchTerm, data]);

  // Triggered on every keystroke in the search box
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const columns = Object.keys(data[0] || {}).map((key) => ({
    field: key,
    headerName: key.charAt(0).toUpperCase() + key.slice(1),
    width: 150,
  }));

  return (
    <>
      <ImageBannerWithText
        header={"Admin Usage Page"}
        subHeader={""}
        imgLink="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/eye-with-binary-data.jpeg"
        imgAltText="EyeGpt"
        height={isMobile ? 95 : 150}
      />
      <Box sx={{ height: 600, width: "100%" }}>
        <Box sx={{ marginBottom: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ maxWidth: "400px" }}
          />
        </Box>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataGrid
            rows={filteredData?.map((row, index) => ({ id: index, ...row }))}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            // checkboxSelection
            // disableSelectionOnClick
          />
        )}
      </Box>
    </>
  );
};

export default AdminUsageLayout;
