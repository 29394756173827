// Section1.jsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import mainLogo from "../../assets/mainLogo.svg";
import gradient from "../../assets/homePage/section1/gradient.png";
import Section3 from "./Section3";

const Section1 = () => {
  // Define reusable style objects for navigation links
  const navLinkStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    fontSize: "22px",
    cursor: "pointer", // Ensures pointer cursor
    transition: "color 0.3s ease", // Smooth color transition
  };

  const navLinkHoverStyle = {
    color: "#5dc1d8", // Color on hover
  };

  // Function to handle hover effect
  const handleNavLinkHover = (e) => {
    e.target.style.color = navLinkHoverStyle.color;
  };

  const handleNavLinkOut = (e) => {
    e.target.style.color = navLinkStyle.color;
  };

  // 1) Create an array of capitalized features:
  const features = [
    "GenA Eye™",
    "Image Analysis",
    "Scribe",
    "Decision Support",
    "CPT / ICD10 Codes",
    "Patient Onboarding",
    "Research Assistant",
    "Student Study Guides",
  ];

  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const fadeDuration = 1000; // Fade-out / Fade-in duration
  const displayDuration = 2000; // Time the text remains fully visible

  useEffect(() => {
    const cycleFeatures = () => {
      setFadeIn(false); // trigger fade-out
      setTimeout(() => {
        // once fade-out completes, switch text
        setCurrentFeatureIndex(
          (prevIndex) => (prevIndex + 1) % features.length
        );
        // trigger fade-in
        setFadeIn(true);
      }, fadeDuration);
    };

    // Display the text for `displayDuration`, then fade out
    const intervalId = setInterval(
      cycleFeatures,
      displayDuration + fadeDuration
    );

    return () => clearInterval(intervalId);
  }, [features.length, fadeDuration, displayDuration]);

  return (
    <div
      className="section1-container"
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        backgroundImage: `url(${gradient})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "#fff",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "10px",
          background: "linear-gradient(to right, #5dc1d8, #103444)",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: "1000",
        }}
      ></div>

      <div
        className="section1-content"
        style={{ padding: "50px", paddingTop: "20px" }}
      >
        <div
          className="top-nav"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "30px",
            position: "relative",
            zIndex: "1001", // Ensure nav is above the gradient bar
          }}
        >
          <Link to="/" style={{ display: "inline-block" }}>
            <img src={mainLogo} alt="Oculogyx Logo" style={{ height: 100 }} />
          </Link>

          <div
            className="nav-links"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Link
              to="/"
              style={navLinkStyle}
              onMouseOver={handleNavLinkHover}
              onMouseOut={handleNavLinkOut}
            >
              Home
            </Link>
            <Link
              to="/about-us"
              style={navLinkStyle}
              onMouseOver={handleNavLinkHover}
              onMouseOut={handleNavLinkOut}
            >
              About
            </Link>
            <Link
              to="/solutions"
              style={navLinkStyle}
              onMouseOver={handleNavLinkHover}
              onMouseOut={handleNavLinkOut}
            >
              Solutions
            </Link>
            {/* <Link
              to="/news-resources"
              style={navLinkStyle}
              onMouseOver={handleNavLinkHover}
              onMouseOut={handleNavLinkOut}
            >
              News & Resources
            </Link> */}
            <Link
              to="/contact-us"
              style={{ ...navLinkStyle, marginRight: "0" }} // Remove margin on last link
              onMouseOver={handleNavLinkHover}
              onMouseOut={handleNavLinkOut}
            >
              Contact
            </Link>
          </div>
        </div>

        <div
          className="video-text-section"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            position: "relative",
          }}
        >
          <div
            className="hero-text"
            style={{ maxWidth: "600px", marginBottom: "20px" }}
          >
            <h1 style={{ lineHeight: "1.5", marginBottom: "20px" }}>
              <span
                style={{
                  fontSize: "30px",
                  fontWeight: "normal",
                  display: "block",
                }}
              >
                Unlock the value of the most
              </span>
              <span
                style={{
                  fontSize: "38px",
                  fontWeight: "500",
                  display: "block",
                }}
              >
                Comprehensive AI
              </span>
              <span
                style={{
                  fontSize: "38px",
                  fontWeight: "500",
                  display: "block",
                }}
              >
                Platform Built Specifically
              </span>
              <span
                style={{
                  fontSize: "38px",
                  fontWeight: "500",
                  display: "block",
                }}
              >
                for{" "}
                <span style={{ color: "#5dc1d8" }}>Eye Care Professionals</span>
              </span>
            </h1>
            <Link to="/solutions" className="explore-capabilities-button">
              Explore Capabilities
            </Link>
          </div>

          <div
            className="hero-video"
            style={{ maxWidth: "600px", position: "relative" }}
          >
            <div
              className="video-overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "98%",
                background: "rgba(63, 62, 62, 0.5)",
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                borderRadius: "10px",
                padding: "20px",
                zIndex: 2,
              }}
            >
              <h3 style={{ marginBottom: "5px", fontSize: "18px" }}>
                Features:
              </h3>
              {/* 4) Apply a fade-in/out transition on the changing text */}
              <p
                style={{
                  fontSize: "32px",
                  marginTop: "0px",
                  transition: `opacity ${fadeDuration}ms ease-in-out`,
                  opacity: fadeIn ? 1 : 0,
                }}
              >
                <strong>{features[currentFeatureIndex]}</strong>
              </p>
            </div>

            <video
              autoPlay
              muted
              loop
              playsInline
              style={{
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            >
              <source
                src="https://oculogyxpublicdatabucket.s3.amazonaws.com/videos/heroVideo.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <Section3 />
        {/* White Card Section */}
        <div
          className="white-card"
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "30px",
            marginTop: "40px",
            color: "#000",
            textAlign: "center",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2
            style={{
              fontSize: "36px",
              marginBottom: "20px",
              fontWeight: "500",
            }}
          >
            GenA Eye™ is like having a Room Full <br />
            of Ophthalmic Experts at{" "}
            <span
              style={{
                background: "linear-gradient(to right, #5dc1d8, #103444)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Your Fingertips
            </span>
          </h2>
          <div style={{ maxWidth: "700px", margin: "20px auto" }}>
            <video
              autoPlay
              muted
              playsInline
              onTimeUpdate={(e) => {
                if (e.target.currentTime >= 31) {
                  e.target.currentTime = 0;
                  e.target.play();
                }
              }}
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <source
                src="https://oculogyxpublicdatabucket.s3.amazonaws.com/videos/laptopVideo.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <p style={{ fontSize: "24px", margin: "20px 0", fontWeight: "500" }}>
            To Improve Your Practice Efficiency
          </p>

          <p style={{ fontSize: "22px", margin: "40px 0 20px" }}>
            Unlock all features from <strong>$39 per month</strong>
          </p>
          {/* "Start Now" Button Linking to /contact-us */}
          <Link to="/contact-us" className="start-now-button">
            Start Now
          </Link>
        </div>
      </div>

      {/* CSS Styles */}
      <style>{`
        /* Navigation Links Hover Effects */
        .nav-links a:hover {
          color: #5dc1d8;
        }

        /* "Explore Capabilities" Button Styles */
        .explore-capabilities-button {
          display: inline-block;
          padding: 12px 25px;
          font-size: 18px;
          font-weight: 500;
          cursor: pointer;
          background-color: #fff;
          color: #103444;
          border: none;
          border-radius: 30px;
          text-decoration: none; /* Remove underline */
          transition: background-color 0.3s ease, color 0.3s ease;
        }

        .explore-capabilities-button:hover {
          background-color: #5dc1d8;
          color: #fff;
        }

        /* "Start Now" Button Styles */
        .start-now-button {
          display: inline-block;
          padding: 12px 25px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          background: linear-gradient(to right, #fff, #fff) padding-box,
                      linear-gradient(to right, #5dc1d8, #103444) border-box;
          border: 2px solid transparent;
          border-radius: 30px;
          color: #103444;
          text-decoration: none; /* Remove underline */
          transition: background 0.3s ease, color 0.3s ease;
        }

        .start-now-button:hover {
          background: linear-gradient(to right, #5dc1d8, #103444) padding-box,
                      linear-gradient(to right, #5dc1d8, #103444) border-box;
          color: #fff;
        }

        /* Responsive Styles */
        @media (max-width: 768px) {
          .section1-content {
            padding: 20px;
          }
          .top-nav {
            flex-direction: column;
            align-items: flex-start;
          }
          .top-nav img {
            margin-bottom: 20px;
          }
          .nav-links {
            flex-direction: column;
            align-items: flex-start;
          }
          .nav-links a {
            margin-right: 0;
            margin-bottom: 10px;
          }
          .video-text-section {
            flex-direction: column;
          }
          .hero-text,
          .hero-video {
            max-width: 100%;
            margin-bottom: 20px;
          }
          .video-overlay h3 {
            font-size: 16px;
          }
          .video-overlay p {
            font-size: 24px;
          }
          .white-card {
            padding: 20px;
            margin-top: 20px;
          }
          .white-card h2 {
            font-size: 24px;
          }
        }
      `}</style>
    </div>
  );
};

export default Section1;
