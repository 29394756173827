// ForumPageLayout.js

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  Container,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Fade,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Authenticator } from "@aws-amplify/ui-react";
import { getSessionJwt, getUserId } from "../util/utilities";
import { ENDPOINTS } from "../util/constants";
import PostModalLayout from "./PostModalLayout"; // For creating/editing posts
import ReplyModalLayout from "./ReplyModalLayout"; // For creating/editing replies
import ReactMarkdown from "react-markdown";
import { useMediaQuery } from "@mui/material";
import "@aws-amplify/ui-react/styles.css";
import MasterLayout from "./MasterLayout";
import OldImageBannerWithText from "../components/OldImageBannerWithText"; // Import the banner component

const Reply = ({
  reply,
  postId,
  onAddReply,
  currentUserId,
  onDeleteReply,
  onEditReply, // Handler for editing replies
}) => {
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // State for editing reply
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleReply = (newReply) => {
    onAddReply(reply.replyId, newReply);
    setIsReplying(false);
  };

  const handleDeleteReply = () => {
    onDeleteReply(reply.replyId);
  };

  const handleEditReply = (updatedReply) => {
    onEditReply(reply.replyId, updatedReply);
    setIsEditing(false);
  };

  return (
    <Box sx={{ marginLeft: isMobile ? 2 : 4, marginTop: 2 }}>
      <Card variant="outlined" sx={{ backgroundColor: "#f9f9f9" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle2" color="text.secondary">
              {reply.username} •{" "}
              {new Date(reply.replyCreatedAt).toLocaleString()}
            </Typography>
            {reply.replyAuthorID === currentUserId && (
              <Box
                sx={{
                  display: "flex",
                  gap: isMobile ? 0.5 : 1,
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsEditing(true)}
                  startIcon={<EditIcon />}
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    minWidth: isMobile ? 36 : "auto",
                    padding: isMobile ? "6px 8px" : "auto",
                    justifyContent: isMobile ? "center" : "flex-start",
                    border: "1px solid",
                    borderColor: "primary.main",
                    borderRadius: 1,
                  }}
                >
                  {!isMobile && "Edit"}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteReply}
                  startIcon={<DeleteIcon />}
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    minWidth: isMobile ? 36 : "auto",
                    padding: isMobile ? "6px 8px" : "auto",
                    justifyContent: isMobile ? "center" : "flex-start",
                    border: "1px solid",
                    borderColor: "error.main",
                    borderRadius: 1,
                  }}
                >
                  {!isMobile && "Delete"}
                </Button>
              </Box>
            )}
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <Typography
              variant="body2"
              component="div"
              sx={{
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              <ReactMarkdown>{reply.replyContent}</ReactMarkdown>
            </Typography>
          </Box>
        </CardContent>

        {/* Render nested replies recursively */}
        {Array.isArray(reply.children) && reply.children.length > 0 && (
          <Box sx={{ paddingLeft: isMobile ? 2 : 4, paddingBottom: 1 }}>
            {reply.children.map((childReply) => (
              <Reply
                key={childReply.replyId}
                reply={childReply}
                postId={postId}
                onAddReply={onAddReply}
                currentUserId={currentUserId}
                onDeleteReply={onDeleteReply}
                onEditReply={onEditReply} // Pass down the edit handler
              />
            ))}
          </Box>
        )}

        <CardActions sx={{ justifyContent: "flex-end" }}>
          {/* Reply Modal for nested replies */}
          {isReplying && (
            <ReplyModalLayout
              open={isReplying}
              onClose={() => setIsReplying(false)}
              onSuccess={handleReply}
              postId={postId}
              parentId={`REPLY#${reply.replyId}`} // Parent is the current reply
              userId={currentUserId}
            />
          )}

          {/* Edit Reply Modal */}
          {isEditing && (
            <ReplyModalLayout
              open={isEditing}
              onClose={() => setIsEditing(false)}
              onSuccess={handleEditReply}
              postId={postId}
              parentId={reply.parentId}
              userId={currentUserId}
              reply={reply} // Pass the reply to edit
            />
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

const ForumPageLayout = () => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isCreatePostOpen, setIsCreatePostOpen] = useState(false);
  const [isCreateReplyOpen, setIsCreateReplyOpen] = useState(false);
  const [isEditPostOpen, setIsEditPostOpen] = useState(false); // State for Edit Modal
  const [postToEdit, setPostToEdit] = useState(null); // The post being edited
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const [replyToDelete, setReplyToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const isMobile = useMediaQuery("(max-width:600px)");

  const [currentUserId, setCurrentUserId] = useState(null); // State to store current user ID

  // Fetch the current user's ID when the component mounts
  useEffect(() => {
    const fetchUserId = async () => {
      const userId = await getUserId();
      setCurrentUserId(userId);
    };

    fetchUserId();
  }, []);

  // Fetch all posts
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const jwtToken = await getSessionJwt();
      const response = await fetch(`${ENDPOINTS.EC2_ENDPOINT}/forum/posts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error.message || `HTTP error! status: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("Fetched posts:", data);
      setPosts(data.results.posts || []); // Ensure posts is an array
    } catch (error) {
      console.error("Error fetching posts:", error);
      setSnackbar({
        open: true,
        message: `Error fetching posts: ${error.message}`,
        severity: "error",
      });
    }
    setLoading(false);
  };

  // Fetch a single post with replies
  const fetchPostDetails = async (postId) => {
    setLoading(true);
    try {
      const jwtToken = await getSessionJwt();
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/forum/posts/${postId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error.message || `HTTP error! status: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("Fetched post details:", data);
      setSelectedPost({
        ...data.results.post,
        replies: data.results.post.replies || [], // Ensure replies is an array
      });
    } catch (error) {
      console.error("Error fetching post details:", error);
      setSnackbar({
        open: true,
        message: `Error fetching post details: ${error.message}`,
        severity: "error",
      });
    }
    setLoading(false);
  };

  // Fetch posts on component mount
  useEffect(() => {
    fetchPosts();
  }, []);

  const handlePostSelect = (post) => {
    fetchPostDetails(post.postId);
  };

  const handleCreatePost = (newPost) => {
    setPosts([newPost, ...posts]);
    setSnackbar({
      open: true,
      message: "Post created successfully!",
      severity: "success",
    });
    setIsCreatePostOpen(false);
  };

  const handleCreateReply = (newReply) => {
    setSelectedPost({
      ...selectedPost,
      replies: [{ ...newReply, children: [] }, ...selectedPost.replies],
    });
    setSnackbar({
      open: true,
      message: "Reply added successfully!",
      severity: "success",
    });
    setIsCreateReplyOpen(false);
  };

  const handleAddNestedReply = (parentReplyId, newReply) => {
    const addReplyRecursively = (replies) => {
      return replies.map((reply) => {
        if (reply.replyId === parentReplyId) {
          return {
            ...reply,
            children: [
              { ...newReply, children: [] }, // Initialize children
              ...(reply.children || []),
            ],
          };
        }
        if (reply.children) {
          return {
            ...reply,
            children: addReplyRecursively(reply.children),
          };
        }
        return reply;
      });
    };

    setSelectedPost({
      ...selectedPost,
      replies: addReplyRecursively(selectedPost.replies),
    });
    setSnackbar({
      open: true,
      message: "Nested reply added successfully!",
      severity: "success",
    });
  };

  // Handle Delete Post
  const handleDeletePost = async () => {
    if (!postToDelete) return;
    setLoading(true);
    try {
      const jwtToken = await getSessionJwt();
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/forum/posts/${postToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error.message || `HTTP error! status: ${response.status}`
        );
      }

      // Remove the deleted post from the posts state
      setPosts(posts.filter((post) => post.postId !== postToDelete));

      // If the deleted post is currently selected, clear the selection
      if (selectedPost && selectedPost.postId === postToDelete) {
        setSelectedPost(null);
      }

      setSnackbar({
        open: true,
        message: "Post deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting post:", error);
      setSnackbar({
        open: true,
        message: `Error deleting post: ${error.message}`,
        severity: "error",
      });
    }
    setLoading(false);
    setDeleteDialogOpen(false);
    setPostToDelete(null);
  };

  // Handle Delete Reply
  const handleDeleteReply = (replyId) => {
    setReplyToDelete(replyId);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteReply = async () => {
    if (!replyToDelete || !selectedPost) return;
    setLoading(true);
    try {
      const jwtToken = await getSessionJwt();
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/forum/posts/${selectedPost.postId}/replies/${replyToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error.message || `HTTP error! status: ${response.status}`
        );
      }

      // Remove the deleted reply from the selectedPost state
      const removeReplyRecursively = (replies) => {
        return replies
          .filter((reply) => reply.replyId !== replyToDelete)
          .map((reply) => ({
            ...reply,
            children: reply.children
              ? removeReplyRecursively(reply.children)
              : [],
          }));
      };

      setSelectedPost({
        ...selectedPost,
        replies: removeReplyRecursively(selectedPost.replies),
      });

      setSnackbar({
        open: true,
        message: "Reply deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting reply:", error);
      setSnackbar({
        open: true,
        message: `Error deleting reply: ${error.message}`,
        severity: "error",
      });
    }
    setLoading(false);
    setDeleteDialogOpen(false);
    setReplyToDelete(null);
  };

  // Handle Update Post
  const handleUpdatePost = (updatedPost) => {
    setPosts(
      posts.map((post) =>
        post.postId === updatedPost.postId ? updatedPost : post
      )
    );

    if (selectedPost && selectedPost.postId === updatedPost.postId) {
      setSelectedPost({
        ...selectedPost,
        title: updatedPost.title,
        content: updatedPost.content,
        updatedAt: updatedPost.updatedAt, // Assuming the backend returns this
      });
    }

    setSnackbar({
      open: true,
      message: "Post updated successfully!",
      severity: "success",
    });
    setIsEditPostOpen(false);
    setPostToEdit(null);
  };

  // Handle Update Reply
  const handleUpdateReply = (replyId, updatedReply) => {
    const updateReplyRecursively = (replies) => {
      return replies.map((reply) => {
        if (reply.replyId === replyId) {
          return { ...reply, ...updatedReply };
        }
        if (reply.children) {
          return { ...reply, children: updateReplyRecursively(reply.children) };
        }
        return reply;
      });
    };

    setSelectedPost({
      ...selectedPost,
      replies: updateReplyRecursively(selectedPost.replies),
    });

    setSnackbar({
      open: true,
      message: "Reply updated successfully!",
      severity: "success",
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <MasterLayout>
      <OldImageBannerWithText
        header="FORUM"
        subHeader="Welcome to the OculogyX Forum! Engage with our community, share insights, and collaborate on advancing ophthalmic healthcare through AI."
        imgLink="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/eye-with-binary-data.jpeg"
        imgAltText="Forum Banner"
        height={isMobile ? 95 : 150}
      />
      <Authenticator>
        {({ signOut, user }) => (
          <Container maxWidth="xl" sx={{ paddingY: isMobile ? 2 : 4 }}>
            <Grid container spacing={isMobile ? 2 : 4}>
              {/* Sidebar - List of Posts */}
              <Grid item xs={12} md={4} sx={{ paddingRight: isMobile ? 0 : 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 3,
                  }}
                >
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    component="h1"
                    sx={{ fontWeight: "bold" }}
                  >
                    Forum
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setIsCreatePostOpen(true)}
                    size={isMobile ? "small" : "medium"}
                    sx={{
                      minWidth: isMobile ? 36 : "auto",
                      padding: isMobile ? "6px 8px" : "auto",
                      justifyContent: isMobile ? "center" : "flex-start",
                    }}
                  >
                    {!isMobile && "New Post"}
                  </Button>
                </Box>
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <List>
                    {posts.map((post) => (
                      <React.Fragment key={post.postId}>
                        <ListItem
                          button
                          onClick={() => handlePostSelect(post)}
                          alignItems="flex-start"
                          sx={{
                            paddingY: isMobile ? 1 : 2,
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="h6"
                                component="span"
                                sx={{
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "block",
                                  maxWidth: "100%",
                                }}
                              >
                                {post.title}
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {post.username}
                                </Typography>
                                {" — "}
                                <Typography
                                  sx={{
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "block",
                                    maxWidth: "100%",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {post.content.length > 100
                                    ? `${post.content.substring(0, 100)}...`
                                    : post.content}
                                </Typography>
                              </>
                            }
                          />

                          {post.authorID === currentUserId && (
                            <Box
                              sx={{
                                display: "flex",
                                gap: isMobile ? 0.5 : 1,
                                flexDirection: isMobile ? "column" : "row",
                                marginLeft: 2,
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                size={isMobile ? "small" : "medium"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPostToEdit(post);
                                  setIsEditPostOpen(true);
                                }}
                                startIcon={<EditIcon />}
                                sx={{
                                  minWidth: isMobile ? 36 : "auto",
                                  justifyContent: isMobile
                                    ? "center"
                                    : "flex-start",
                                }}
                              >
                                {!isMobile && "Edit"}
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                size={isMobile ? "small" : "medium"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPostToDelete(post.postId);
                                  setDeleteDialogOpen(true);
                                }}
                                startIcon={<DeleteIcon />}
                                sx={{
                                  minWidth: isMobile ? 36 : "auto",
                                  justifyContent: isMobile
                                    ? "center"
                                    : "flex-start",
                                }}
                              >
                                {!isMobile && "Delete"}
                              </Button>
                            </Box>
                          )}
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </Grid>

              {/* Main Content - Selected Post Details */}
              <Grid item xs={12} md={8} sx={{ paddingLeft: isMobile ? 0 : 4 }}>
                {selectedPost ? (
                  <Box
                    sx={{
                      border: "1px solid #e0e0e0",
                      padding: isMobile ? 2 : 3,
                      borderRadius: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 2,
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="h2"
                        sx={{ fontWeight: "bold" }}
                      >
                        {selectedPost.title}
                      </Typography>
                      <Box sx={{ display: "flex", gap: isMobile ? 0.5 : 1 }}>
                        <Button
                          variant="contained"
                          onClick={() => setIsCreateReplyOpen(true)}
                          startIcon={<AddIcon />}
                          size={isMobile ? "small" : "medium"}
                          sx={{
                            minWidth: isMobile ? 36 : "auto",
                            padding: isMobile ? "6px 8px" : "auto",
                            justifyContent: isMobile ? "center" : "flex-start",
                          }}
                        >
                          {!isMobile && "Reply"}
                        </Button>
                        {selectedPost.authorID === currentUserId && (
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setPostToEdit(selectedPost);
                                setIsEditPostOpen(true);
                              }}
                              startIcon={<EditIcon />}
                              size={isMobile ? "small" : "medium"}
                              sx={{
                                minWidth: isMobile ? 36 : "auto",
                                justifyContent: isMobile
                                  ? "center"
                                  : "flex-start",
                              }}
                            >
                              {!isMobile && "Edit"}
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                setPostToDelete(selectedPost.postId);
                                setDeleteDialogOpen(true);
                              }}
                              startIcon={<DeleteIcon />}
                              size={isMobile ? "small" : "medium"}
                              sx={{
                                minWidth: isMobile ? 36 : "auto",
                                justifyContent: isMobile
                                  ? "center"
                                  : "flex-start",
                              }}
                            >
                              {!isMobile && "Delete"}
                            </Button>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >
                      By {selectedPost.username} on{" "}
                      {new Date(selectedPost.createdAt).toLocaleString()}
                    </Typography>
                    <Box sx={{ marginY: 3 }}>
                      <Typography
                        variant="body1"
                        component="div"
                        sx={{
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }}
                      >
                        <ReactMarkdown>{selectedPost.content}</ReactMarkdown>
                      </Typography>
                    </Box>

                    <Divider />
                    <Box sx={{ marginY: 3 }}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "bold" }}
                      >
                        Replies
                      </Typography>
                      {selectedPost.replies &&
                      selectedPost.replies.length > 0 ? (
                        selectedPost.replies.map((reply) => (
                          <Reply
                            key={reply.replyId}
                            reply={reply}
                            postId={selectedPost.postId}
                            onAddReply={handleAddNestedReply}
                            currentUserId={currentUserId}
                            onDeleteReply={handleDeleteReply}
                            onEditReply={handleUpdateReply}
                          />
                        ))
                      ) : (
                        <Typography>No replies yet.</Typography>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60vh",
                    }}
                  >
                    <Typography variant="h6" color="text.secondary">
                      Select a post to view details.
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>

            {/* Post Modal (Create & Edit) */}
            <PostModalLayout
              open={isCreatePostOpen || isEditPostOpen}
              onClose={() => {
                setIsCreatePostOpen(false);
                setIsEditPostOpen(false);
                setPostToEdit(null);
              }}
              onSuccess={isEditPostOpen ? handleUpdatePost : handleCreatePost}
              post={isEditPostOpen ? postToEdit : null}
              userId={currentUserId}
            />

            {/* Create Reply Modal for replying to the post */}
            {selectedPost && (
              <ReplyModalLayout
                open={isCreateReplyOpen}
                onClose={() => setIsCreateReplyOpen(false)}
                onSuccess={handleCreateReply}
                postId={selectedPost.postId}
                parentId={`POST#${selectedPost.postId}`}
                userId={currentUserId}
              />
            )}

            {/* Delete Confirmation Dialog */}
            <Dialog
              open={deleteDialogOpen}
              onClose={() => {
                setDeleteDialogOpen(false);
                setPostToDelete(null);
                setReplyToDelete(null);
              }}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
            >
              <DialogTitle>
                {postToDelete && !replyToDelete
                  ? "Delete Post"
                  : "Delete Reply"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {postToDelete && !replyToDelete
                    ? "Are you sure you want to delete this post? This action cannot be undone."
                    : "Are you sure you want to delete this reply? This action cannot be undone."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDeleteDialogOpen(false);
                    setPostToDelete(null);
                    setReplyToDelete(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={postToDelete ? handleDeletePost : confirmDeleteReply}
                  color="error"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            {/* Snackbar for Notifications */}
            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={snackbar.severity}
                sx={{ width: "100%" }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </Container>
        )}
      </Authenticator>
    </MasterLayout>
  );
};

export default ForumPageLayout;
