import React from "react";
import genAEyeMockup from "../../assets/genAEyeMockup.png";
import imageAnalysisMockup from "../../assets/imageAnalysisMockup.png";
import assistantMockup from "../../assets/assistantMockup.png";
import scribeMockup from "../../assets/scribeMockup.png";
import gradient from "../../assets/gradient.png";
import checkmarkBright from "../../assets/checkmarkBright.svg";
import checkmarkDark from "../../assets/checkmarkDark.svg";
import scholarMockup from "../../assets/scholarMockup.png";

const Section2 = () => {
  // ===== Scroll to Contact Form (same as Section1) =====
  const scrollToContactForm = () => {
    window.scrollTo({
      top: 3800, // Adjust to match your layout
      behavior: "smooth",
    });
  };

  const ListItem = ({ text, isBright }) => (
    <li style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
      <img
        src={isBright ? checkmarkBright : checkmarkDark}
        alt="checkmark"
        style={{ width: "20px", marginRight: "10px" }}
      />
      <span>{text}</span>
    </li>
  );

  return (
    <div
      className="section2-outer"
      style={{
        background: "#e0f7fa", // Changed from "#fff" to light blue
        color: "#000",
        padding: "30px 0",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <div className="section2-inner" style={{ margin: "0 auto" }}>
        {/* =========================================
            1) GenA Eye Section (Light Blue Background)
        ========================================= */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "30px 20px",
            marginTop: "20px",
            background: "inherit", // Inherits light blue from outer div
          }}
        >
          <div style={{ flex: 1 }}>
            <img
              src={genAEyeMockup}
              alt="GenA Eye™ Mockup"
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </div>
          <div style={{ flex: 1, textAlign: "left", marginLeft: "20px" }}>
            <h2
              style={{
                fontSize: "32px",
                fontWeight: "600",
                marginBottom: "15px",
                color: "#5dc1d8",
              }}
            >
              GenA Eye™
            </h2>
            <h3
              style={{
                fontSize: "24px",
                fontWeight: "500",
                marginBottom: "15px",
              }}
            >
              Make Informed Decisions, Faster.
            </h3>
            <p
              style={{
                fontSize: "18px",
                marginBottom: "15px",
                lineHeight: "1.6",
                fontWeight: 500,
              }}
            >
              The GenA Eye™ Engine, an advanced Retrieval-Augmented Generation
              (RAG) model built on a vast library of open-source ophthalmic
              information, is a specialized knowledge repository providing
              immediate answers to clinical queries. Doctors can navigate
              information with ease, ensuring they remain at the forefront of
              ophthalmic advancements without sacrificing patient time.
            </p>
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                gap: "10px",
                listStyle: "none",
                padding: "0",
                fontSize: "18px",
                margin: "15px 0",
              }}
            >
              <ListItem text="Ask Everything Ophthalmic!" isBright={false} />
              <ListItem text="Wavefront Optimized TX" isBright={false} />
              <ListItem text="Refractive Surgery Advisor" isBright={false} />
              <ListItem text="Research Assistant" isBright={false} />
              <ListItem text="IOL Advisor" isBright={false} />
              <ListItem text="Ophthalmic Image Analysis" isBright={false} />
            </ul>
            {/* Dark button (was teal), now same overlay logic + scroll */}
            <button
              style={{ marginTop: "15px" }}
              className="button-dark-get-started"
              onClick={scrollToContactForm}
            >
              <span>Get Started</span>
              <div className="get-started-overlay"></div>
            </button>
          </div>
        </div>

        {/* =========================================
            2) Image Analysis Section (Gradient Background)
        ========================================= */}
        <div
          style={{
            backgroundImage: `url(${gradient})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "30px 20px",
            marginTop: "20px",
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1, marginRight: "20px", textAlign: "left" }}>
              <h2
                style={{
                  fontSize: "32px",
                  fontWeight: "500",
                  marginBottom: "15px",
                  color: "#fff",
                }}
              >
                <span style={{ color: "#5dc1d8" }}>Image Analysis:</span> <br />
                Save Hours Spent on Manual Reporting
              </h2>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "15px",
                  lineHeight: "1.6",
                  fontWeight: 500,
                }}
              >
                The Image Analysis Program quickly generates detailed reports of
                ophthalmic tests and imaging results, ensuring each report is
                complete, compliant, and ready for insurance submission.
                Enhanced accuracy and compliance of AI-generated reports reduces
                billing delays, accelerating the revenue cycle.
              </p>
              <ul
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                  gap: "10px",
                  listStyle: "none",
                  padding: "0",
                  fontSize: "18px",
                  margin: "15px 0",
                }}
              >
                <ListItem text="Visual Fields" isBright={true} />
                <ListItem text="Retinal Photography" isBright={true} />
                <ListItem text="Retinal OCT" isBright={true} />
                <ListItem text="Optic Nerve OCT" isBright={true} />
                <ListItem text="Optos" isBright={true} />
                <ListItem text="Corneal Photography" isBright={true} />
                <ListItem text="Corneal Topography" isBright={true} />
                <ListItem text="Pentacam" isBright={true} />
              </ul>
              {/* White button + scroll */}
              <button
                style={{ marginTop: "15px" }}
                className="button-white-get-started"
                onClick={scrollToContactForm}
              >
                <span>Get Started</span>
                <div className="get-started-overlay"></div>
              </button>
            </div>
            <div style={{ flex: 1 }}>
              <img
                src={imageAnalysisMockup}
                alt="Analysis Mockup"
                style={{
                  width: "100%",
                  maxWidth: "500px",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        </div>

        {/* =========================================
            3) Scribe Section (Light Blue Background)
        ========================================= */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "30px 20px",
            marginTop: "20px",
            background: "inherit", // Inherits light blue from outer div
          }}
        >
          <div style={{ flex: 1 }}>
            <img
              src={scribeMockup}
              alt="Scribe Mockup"
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            />
          </div>
          <div style={{ flex: 1, textAlign: "left", marginLeft: "20px" }}>
            <h2
              style={{
                fontSize: "32px",
                fontWeight: "500",
                marginBottom: "15px",
                color: "#5dc1d8",
              }}
            >
              Scribe
            </h2>
            <h3
              style={{
                fontSize: "24px",
                fontWeight: "500",
                marginBottom: "15px",
              }}
            >
              Eliminate Time-Consuming Documentation Tasks
            </h3>
            <p
              style={{
                fontSize: "18px",
                marginBottom: "15px",
                lineHeight: "1.6",
                fontWeight: 500,
              }}
            >
              Rather than spending hours on charting, physicians can rely on
              Oculogyx Scribe to capture critical details of each consultation.
              Scribe output is available as various note formats and also
              suggests appropriate ICD-10 and CPT codes based on the recording
              length.
            </p>
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                gap: "10px",
                listStyle: "none",
                padding: "0",
                fontSize: "18px",
              }}
            >
              <ListItem text="Dictation" isBright={false} />
              <ListItem text="Chart Notes" isBright={false} />
              <ListItem text="Reports" isBright={false} />
              <ListItem text="Referral Letters" isBright={false} />
            </ul>
            {/* Dark button + scroll */}
            <button
              style={{ marginTop: "15px" }}
              className="button-dark-get-started"
              onClick={scrollToContactForm}
            >
              <span>Get Started</span>
              <div className="get-started-overlay"></div>
            </button>
          </div>
        </div>

        {/* =========================================
            4) Assistant Section (Gradient Background)
        ========================================= */}
        <div
          style={{
            backgroundImage: `url(${gradient})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "30px 20px",
            marginTop: "20px",
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1, textAlign: "left" }}>
              <h2
                style={{
                  fontSize: "32px",
                  fontWeight: "500",
                  marginBottom: "15px",
                  color: "#5dc1d8",
                }}
              >
                Assistant
              </h2>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "15px",
                  lineHeight: "1.6",
                  fontWeight: 500,
                }}
              >
                Let your AI assistant automate patient intake through secure
                online screenings completed before the visit. Patients access a
                pre-visit link to engage in a conversation, which the system
                summarizes in a medical format for the doctor’s office.
              </p>
              <ul
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                  gap: "10px",
                  listStyle: "none",
                  padding: "0",
                  fontSize: "18px",
                }}
              >
                <ListItem text="Communicate with Patients" isBright={true} />
                <ListItem text="Ophthalmic Triage" isBright={true} />
                <ListItem text="Pre-Visit Interviews" isBright={true} />
                <ListItem text="Practice Information Agent" isBright={true} />
              </ul>
              {/* White button + scroll */}
              <button
                style={{ marginTop: "15px" }}
                className="button-white-get-started"
                onClick={scrollToContactForm}
              >
                <span>Get Started</span>
                <div className="get-started-overlay"></div>
              </button>
            </div>
            <div style={{ flex: 1, marginLeft: "20px" }}>
              <img
                src={assistantMockup}
                alt="Assistant Mockup"
                style={{
                  width: "100%",
                  maxWidth: "500px",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        </div>

        {/* =========================================
            5) Ophthalmic Scholar Section (Light Blue Background)
        ========================================= */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
            padding: "30px 20px",
            marginTop: "20px",
            background: "inherit", // Inherits light blue from outer div
          }}
        >
          <div style={{ flex: "1 1 50%" }}>
            <img
              src={scholarMockup}
              alt="Ophthalmic Scholar Mockup"
              style={{ width: "100%", maxWidth: "500px" }}
            />
          </div>
          <div
            style={{
              flex: "1 1 50%",
              textAlign: "left",
              marginLeft: "20px",
            }}
          >
            <h2
              style={{
                fontSize: "32px",
                fontWeight: "600",
                marginBottom: "15px",
                color: "#5dc1d8",
              }}
            >
              Ophthalmic Scholar
            </h2>
            <p
              style={{
                fontSize: "18px",
                marginBottom: "15px",
                lineHeight: "1.6",
                fontWeight: 500,
              }}
            >
              Ophthalmic Scholar is a comprehensive AI-powered solution for
              professionals and students in the vision industry. It serves as a
              Research Assistant, Ophthalmic Study Guide, Clinical Trial Design
              and Data Analysis aid, a tutor to test your acumen, and a resource
              for Board Preparation.
            </p>
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                gap: "10px",
                listStyle: "none",
                padding: "0",
                fontSize: "18px",
              }}
            >
              <ListItem text="Research Assistant" isBright={false} />
              <ListItem text="Ophthalmic Study Guide" isBright={false} />
              <ListItem text="Clinical Trial Design and Data Analysis" isBright={false} />
              <ListItem text="Test Your Clinical Acumen" isBright={false} />
              <ListItem text="Board Preparation" isBright={false} />
            </ul>
            {/* Dark button + scroll */}
            <button
              style={{ marginTop: "15px" }}
              className="button-dark-get-started"
              onClick={scrollToContactForm}
            >
              <span>Get Started</span>
              <div className="get-started-overlay"></div>
            </button>
          </div>
        </div>
      </div>

      {/* ================== CSS Styles ================== */}
      <style>{`
        /* Dark "Get Started" Button */
        .button-dark-get-started {
          position: relative;
          display: inline-block;
          padding: 10px 25px;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
          background-color: #103444; /* same as Section1 default */
          border: none;
          border-radius: 30px;
          cursor: pointer;
          overflow: hidden;
          text-decoration: none;
          transition: background 0.3s ease;
        }

        /* White "Get Started" Button */
        .button-white-get-started {
          position: relative;
          display: inline-block;
          padding: 10px 25px;
          font-size: 18px;
          font-weight: 500;
          color: #103444; /* text color */
          background-color: #fff;
          border: none;
          border-radius: 30px;
          cursor: pointer;
          overflow: hidden;
          text-decoration: none;
          transition: background 0.3s ease, color 0.3s ease;
        }

        /* Overlay is invisible by default */
        .get-started-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to right, #5dc1d8, #103444);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 1;
        }

        /* The text sits above the overlay */
        .button-dark-get-started span,
        .button-white-get-started span {
          position: relative;
          z-index: 2;
        }

        /* Hover => fade in gradient overlay */
        .button-dark-get-started:hover .get-started-overlay,
        .button-white-get-started:hover .get-started-overlay {
          opacity: 1;
        }

        /* Optional: force text to white on hover for both button types */
        .button-white-get-started:hover {
          color: #fff;
        }
        .button-dark-get-started:hover {
          color: #fff;
        }
      `}</style>
    </div>
  );
};

export default Section2;
