import React, { useState } from "react";
import blog1 from "../../assets/homePage/section4/blog1.jpg";
import blog2 from "../../assets/homePage/section4/blog2.jpg";
import blog3 from "../../assets/homePage/section4/blog3.jpg";
import gradient from "../../assets/homePage/section4/gradient.jpg";

const Section4 = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null); // New state for button hover

  const advantages = [
    { id: 1, title: "Enhanced Decision Making", info: "AI-driven insights provide data-backed support for informed decisions." },
    { id: 2, title: "Increased Efficiency", info: "Automate routine tasks, freeing up time for patient care and strategic thinking." },
    { id: 3, title: "Improved Patient Experience", info: "Deliver personalized experiences through AI-powered insights." },
    { id: 4, title: "Competitive Advantage", info: "Gain a first-mover advantage by embracing AI early." },
    { id: 5, title: "Talent Acquisition & Retention", info: "Attract top talent by showcasing your organization's commitment to AI." },
    { id: 6, title: "Cost Reduction", info: "Optimize operations and reduce expenses through AI-driven automation." },
    { id: 7, title: "Innovation Catalyst", info: "Generate new ideas and products through AI-powered research and development." },
    { id: 8, title: "Risk Mitigation", info: "Identify and address potential disease progression proactively using AI analytics." },
    { id: 9, title: "Scalability", info: "Expand your business reach and market penetration with AI-powered solutions." },
    { id: 10, title: "Future-Proofing", info: "Prepare your organization for the evolving digital landscape." },
  ];

  const blogs = [
    { id: 1, image: blog1, title: "Featured Blog Title", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod et dolore magna aliqua." },
    { id: 2, image: blog2, title: "Featured Blog Title", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod et dolore magna aliqua." },
    { id: 3, image: blog3, title: "Featured Blog Title", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod et dolore magna aliqua." },
  ];

  return (
    <div
      className="section4-container"
      style={{
        backgroundImage: `url(${gradient})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        maxWidth: "1200px",
        margin: "0 auto",
        color: "#fff",
        padding: "70px 30px",
        position: "relative",
      }}
    >
      <div
        className="section4-inner"
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "0 30px",
          textAlign: "center",
        }}
      >
        <h2
          style={{
            fontSize: "32px",
            fontWeight: "600",
            marginBottom: "40px",
          }}
        >
          Your <span style={{ color: "#5dc1d8" }}>Oculogyx Advantage</span>
        </h2>
        <div
          className="advantage-cards"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "20px",
            marginBottom: "60px",
          }}
        >
          {advantages.map((advantage) => (
            <div
              key={advantage.id}
              style={{
                background: "#fff",
                color: "#000",
                padding: "20px",
                height: "200px",
                borderRadius: "10px",
                position: "relative",
                overflow: "hidden",
                textAlign: "center",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "transform 0.6s ease, box-shadow 0.6s ease",
                transform: hoveredCard === advantage.id ? "scale(1.05)" : "scale(1)",
              }}
              onMouseEnter={() => setHoveredCard(advantage.id)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div
                style={{
                  position: "absolute",
                  top: hoveredCard === advantage.id ? "42px" : "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "16px",
                  fontWeight: "500",
                  zIndex: 2,
                  transition: "top 0.6s ease",
                }}
              >
                {advantage.title}
                <div
                  style={{
                    marginTop: "5px",
                    width: hoveredCard === advantage.id ? "50%" : "40%",
                    height: "2px",
                    backgroundColor: "#5dc1d8",
                    marginLeft: "auto",
                    marginRight: "auto",
                    transition: "width 0.6s ease",
                  }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: hoveredCard === advantage.id ? "20px" : "-100%",
                  left: "10px",
                  right: "10px",
                  transition: "bottom 0.6s ease",
                  fontSize: "14px",
                  textAlign: "left",
                  maxWidth: "calc(100% - 20px)",
                  zIndex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                {advantage.info}
              </div>
            </div>
          ))}
        </div>
        {/* <h2
          style={{
            fontSize: "28px",
            fontWeight: "500",
            marginBottom: "40px",
          }}
        >
          Featured Insights
        </h2>
        <div
          className="featured-insights"
          style={{
            display: "flex",
            gap: "30px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {blogs.map((blog) => (
            <div
              key={blog.id}
              style={{
                background: "#fff",
                color: "#000",
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                maxWidth: "300px",
                textAlign: "left",
                transition: "transform 0.6s ease, box-shadow 0.6s ease",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <img
                src={blog.image}
                alt={blog.title}
                style={{ width: "100%", height: "180px", objectFit: "cover" }}
              />
              <div style={{ padding: "20px" }}>
                <h3
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {blog.title}
                </h3>
                <p style={{ fontSize: "14px", marginBottom: "15px" }}>
                  {blog.description}
                </p>
                <button
                  style={{
                    padding: "10px 20px",
                    fontSize: "14px",
                    backgroundColor: "#103444",
                    color: "#fff",
                    border: "none",
                    borderRadius: "20px",
                    cursor: "pointer",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={() => setHoveredButton(blog.id)}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  <span style={{ position: "relative", zIndex: 2 }}>Read Article</span>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "linear-gradient(to right, #5dc1d8, #103444)",
                      opacity: hoveredButton === blog.id ? 1 : 0,
                      transition: "opacity 0.3s ease",
                      zIndex: 1,
                    }}
                  ></div>
                </button>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "10px",
          background: "linear-gradient(to right, #5dc1d8, #103444)",
        }}
      />
      <style>{`
        @media (max-width: 768px) {
          .advantage-cards {
            grid-template-columns: 1fr 1fr !important;
            grid-row-gap: 30px;
          }
          .featured-insights {
            flex-direction: column;
            align-items: center;
          }
          .featured-insights > div {
            max-width: 100% !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Section4;
