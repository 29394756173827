import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export async function getSessionJwt() {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    return idToken?.toString();
  } catch (err) {
    console.log(err);
  }
}

export function generateAudioFileName() {
  const now = new Date();
  const monthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const month = monthNames[now.getMonth()];
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  const fileName = `${month}${day}_${hours}${minutes}${seconds}.webm`;
  return fileName;
}

export function formatSize(size) {
  const units = ["B", "KB", "MB", "GB"];
  let unitIndex = 0;
  let sizeInUnits = size;

  while (sizeInUnits >= 1000 && unitIndex < units.length - 1) {
    sizeInUnits = sizeInUnits / 1000;
    unitIndex++;
  }

  sizeInUnits = Math.ceil(sizeInUnits);

  return `${sizeInUnits}${units[unitIndex]}`;
}

// utilities.js

export const getUserId = async () => {
  // Implement this based on how you store user information.
  // For example, decode the JWT token and extract the user ID.
  const token = await getSessionJwt();
  if (!token) return null;

  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.sub; // Assuming 'sub' contains the user ID
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};


export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}