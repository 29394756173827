import React from "react";
import MasterLayout from "./MasterLayout";
import {
  Box,
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import mainLogo from "../assets/mainLogo.svg";
import { ENDPOINTS } from "../util/constants";
import { getSessionJwt } from "../util/utilities";

const BillingLayout = () => {
  // Navigation link styles
  const navLinkStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    fontSize: "22px",
    cursor: "pointer",
    transition: "color 0.3s ease",
  };
  const navLinkHoverStyle = {
    color: "#5dc1d8",
  };

  const handleNavLinkHover = (e) => {
    e.target.style.color = navLinkHoverStyle.color;
  };
  const handleNavLinkOut = (e) => {
    e.target.style.color = navLinkStyle.color;
  };

  // Function to handle subscription selection and redirect to Stripe
  const createCheckoutAndRedirect = async (selectedSubscriptionName) => {
    try {
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        alert("Authentication token missing. Please log in again.");
        return;
      }

      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/create-checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ subscriptionName: selectedSubscriptionName }),
        }
      );

      const data = await response.json();

      if (data.results?.checkoutURL) {
        window.location.href = data.results.checkoutURL;
      } else {
        console.error(
          "Error creating checkout: Missing checkout URL in response."
        );
        alert(data.error.message);
      }
    } catch (error) {
      console.error("Error creating checkout. Message:", error);
      alert("An error occurred while initiating checkout. Please try again.");
    }
  };

  return (
    <MasterLayout>
      <div style={{ backgroundColor: "black" }}>
        <div
          style={{
            backgroundImage: "linear-gradient(to bottom, #103444, #5dc1d8)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            maxWidth: "1200px",
            margin: "0 auto",
            paddingBottom: "50px",
          }}
        >
          {/* Top Navigation Bar */}
          <AppBar position="static" sx={{ background: "#103444" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                <Link to="/" style={{ display: "inline-block" }}>
                  <img src={mainLogo} alt="Oculogyx Logo" style={{ height: 100 }} />
                </Link>
              </Box>
              <Box className="nav-links" sx={{ display: "flex", alignItems: "center" }}>
                <Link
                  to="/"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Home
                </Link>
                <Link
                  to="/about-us"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  About
                </Link>
                <Link
                  to="/solutions"
                  style={navLinkStyle}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Solutions
                </Link>
                <Link
                  to="/contact-us"
                  style={{ ...navLinkStyle, marginRight: "0" }}
                  onMouseOver={handleNavLinkHover}
                  onMouseOut={handleNavLinkOut}
                >
                  Contact
                </Link>
              </Box>
            </Toolbar>
          </AppBar>

          {/* Billing Information Section */}
          <div style={{ padding: "50px 20px" }}>
            <Box
              sx={{
                maxWidth: "1200px",
                margin: "0 auto",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                padding: "40px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography
                variant="h4"
                align="center"
                sx={{
                  fontWeight: "500",
                  marginBottom: "30px",
                  color: "#103444",
                }}
              >
                Subscription Tiers
              </Typography>

              <Typography variant="body1" sx={{ mb: 4, color: "#555" }}>
                <strong>Gen A Eye Office</strong>
                <br />
                To cater to the diverse needs of eye care professionals, OculogyX
                offers a flexible subscription model based on a point system for
                each practice facility. This tiered approach ensures that
                practices of all sizes can benefit from all of our AI-powered
                solutions:
              </Typography>

              {/* Subscription Cards */}
              <Grid container spacing={4}>
                {/* Starter Tier */}
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      border: "2px solid #5dc1d8",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ color: "#5dc1d8", fontWeight: "600" }}
                      >
                        Starter
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        $39 / month
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        40,000 pts / month
                        <br />
                        Overage at $0.001 / pt.
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{
                          mt: 2,
                          borderColor: "#5dc1d8",
                          color: "#5dc1d8",
                          "&:hover": {
                            borderColor: "#103444",
                            color: "#103444",
                          },
                        }}
                        onClick={() => createCheckoutAndRedirect("Starter")}
                      >
                        Select
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Pro Tier */}
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      border: "2px solid #5dc1d8",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ color: "#5dc1d8", fontWeight: "600" }}
                      >
                        Pro
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        $99 / month
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        125,000 pts / month
                        <br />
                        Overage at $0.0008 / pt.
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{
                          mt: 2,
                          borderColor: "#5dc1d8",
                          color: "#5dc1d8",
                          "&:hover": {
                            borderColor: "#103444",
                            color: "#103444",
                          },
                        }}
                        onClick={() => createCheckoutAndRedirect("Pro")}
                      >
                        Select
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Pro + Tier */}
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      border: "2px solid #5dc1d8",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ color: "#5dc1d8", fontWeight: "600" }}
                      >
                        Pro +
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        $199 / month
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        250,000 pts / month
                        <br />
                        Overage at $0.0007 / pt.
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{
                          mt: 2,
                          borderColor: "#5dc1d8",
                          color: "#5dc1d8",
                          "&:hover": {
                            borderColor: "#103444",
                            color: "#103444",
                          },
                        }}
                        onClick={() => createCheckoutAndRedirect("Pro +")}
                      >
                        Select
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Super User Tier */}
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      border: "2px solid #5dc1d8",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ color: "#5dc1d8", fontWeight: "600" }}
                      >
                        Super User
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        $250 / month
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        500,000 pts / month
                        <br />
                        Overage at $0.0005 / pt.
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{
                          mt: 2,
                          borderColor: "#5dc1d8",
                          color: "#5dc1d8",
                          "&:hover": {
                            borderColor: "#103444",
                            color: "#103444",
                          },
                        }}
                        onClick={() => createCheckoutAndRedirect("Super User")}
                      >
                        Select
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Enterprise Tier */}
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      border: "2px solid #5dc1d8",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ color: "#5dc1d8", fontWeight: "600" }}
                      >
                        Enterprise
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        Call for pricing
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Customized solutions tailored to your practice's needs.
                      </Typography>
                      <Button
                        component={Link}
                        to="/contact-us"
                        variant="outlined"
                        sx={{
                          mt: 2,
                          borderColor: "#5dc1d8",
                          color: "#5dc1d8",
                          "&:hover": {
                            borderColor: "#103444",
                            color: "#103444",
                          },
                        }}
                      >
                        Contact Us
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* Detailed Point System */}
              <Box sx={{ mt: 6 }}>
                <Typography
                  variant="h5"
                  sx={{ mb: 2, color: "#103444", fontWeight: "600" }}
                >
                  Our Pricing Structure
                </Typography>
                <Typography variant="body1" sx={{ mb: 3, color: "#555" }}>
                  Our pricing structure is designed to be transparent and cost-effective.
                  The average points per query for OculogyX's Core features are as follows:
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <ul>
                      <li>
                        <strong>Gen A Eye:</strong> 20 points
                      </li>
                      <li>
                        <strong>Transcription to Chart Note:</strong> 200 points/minute
                      </li>
                      <li>
                        <strong>Pentacam Image Analysis:</strong> 1,000 points
                      </li>
                      <li>
                        <strong>Visual Field Image Analysis:</strong> 2,000 points
                      </li>
                      <li>
                        <strong>Optic Nerve OCT Analysis:</strong> 1,200 points
                      </li>
                      <li>
                        <strong>Retinal OCT Analysis:</strong> 1,000 points
                      </li>
                      <li>
                        <strong>Retina Photo (30 degrees):</strong> 1,000 points
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ul>
                      <li>
                        <strong>Refractive Surgery Advisor:</strong> 1,275 points
                      </li>
                      <li>
                        <strong>Optos Retinal Image Analysis:</strong> 1,250 points
                      </li>
                      <li>
                        <strong>Corneal Photo Analysis:</strong> 1,300 points
                      </li>
                      <li>
                        <strong>Corneal Topography Analysis:</strong> 600 points
                      </li>
                      <li>
                        <strong>Ophthalmic Triage Agent:</strong> 400 points (text), 600 points (conversation)
                      </li>
                      <li>
                        <strong>Pre-Visit Interview Agent:</strong> 250 points (text); 600 points (conversation)
                      </li>
                      <li>
                        <strong>IOL Advisor Agent:</strong> 200 points
                      </li>
                      <li>
                        <strong>Practice Information Agent:</strong> 225 points (text), 550 points (conversation)
                      </li>
                      <li>
                        <strong>Research Assistant:</strong> 125 points
                      </li>
                      <li>
                        <strong>Ophthalmic Study Guide:</strong> 100 points
                      </li>
                    </ul>
                  </Grid>
                </Grid>

                <Typography variant="body1" sx={{ mt: 3, color: "#555" }}>
                  This pricing model allows clinics to pay only for the features they use, ensuring cost-effectiveness and flexibility. As practices grow and utilize more features, their subscription can quickly scale to meet their needs.
                </Typography>
              </Box>
            </Box>
          </div>
        </div>
      </div>

      {/* Responsive Styles */}
      <style>{`
        @media (max-width: 768px) {
          .top-nav {
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
          }
          .nav-links {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
          }
          .nav-links a {
            margin-right: 0;
            margin-bottom: 10px;
            font-size: 18px;
          }
        }
      `}</style>
    </MasterLayout>
  );
};

export default BillingLayout;
