import React, { useState } from "react";
import axios from "axios";
import { Box, Grid, TextField, Typography, Button } from "@mui/material";
import { ENDPOINTS } from "../../util/constants";
import { getSessionJwt } from "../../util/utilities";

const Section3 = () => {
  // Local state for form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName]  = useState("");
  const [email, setEmail]        = useState("");
  const [phoneNo, setPhoneNo]    = useState("");
  const [message, setMessage]    = useState("");

  // Whether we’re currently sending
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleSendEmail = async () => {
    // 1) Simple validation
    if (!email) {
      alert("Please enter an email address");
      return;
    }
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address");
      return;
    }

    try {
      setIsSendingEmail(true);

      // 2) Retrieve JWT if needed
      const jwtToken = await getSessionJwt();

      // 3) POST JSON data
      const response = await axios.post(
        `${ENDPOINTS.EC2_ENDPOINT}/assistants/contact-email`,
        {
          fromEmail: email,
          textContent: message,
          firstName,
          lastName,
          phoneNo,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        alert("Email sent successfully");
        // Clear fields
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNo("");
        setMessage("");
      } else {
        alert("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred while sending the email");
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <div
      className="section3-container"
      style={{
        backgroundImage: "linear-gradient(to bottom, #103444, #5dc1d8)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "70px 30px",
        color: "#000",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <div style={{ padding: "50px 0" }}>
        <Box
          className="contact-form-box"
          sx={{
            maxWidth: "800px",
            margin: "0 auto",
            backgroundImage: "linear-gradient(to bottom, #ffffff, #f4f4f4, #e6e6e6)",
            borderRadius: "12px",
            padding: "40px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: "500", marginBottom: "20px", color: "#000" }}
          >
            Contact a
            <span
              style={{
                background: "linear-gradient(to right, #5dc1d8, #103444)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "500",
                margin: "0 8px",
              }}
            >
              Gen A Eye™
            </span>
            Expert and Get Started Today
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderImageSource: "linear-gradient(to right, #5dc1d8, #103444)",
                      borderImageSlice: 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderImageSource: "linear-gradient(to right, #5dc1d8, #103444)",
                      borderImageSlice: 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderImageSource: "linear-gradient(to right, #5dc1d8, #103444)",
                      borderImageSlice: 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderImageSource: "linear-gradient(to right, #5dc1d8, #103444)",
                      borderImageSlice: 1,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderImageSource: "linear-gradient(to right, #5dc1d8, #103444)",
                      borderImageSlice: 1,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Dark -> Gradient Hover Button */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              variant="contained"
              onClick={handleSendEmail}
              disabled={isSendingEmail}
              sx={{
                backgroundColor: "#103444",
                color: "#fff",
                borderRadius: "30px",
                position: "relative",
                overflow: "hidden",
                padding: "12px 30px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                "&:hover": {
                  backgroundColor: "#103444",
                },
                "&:hover .btn-overlay": {
                  opacity: 1,
                },
              }}
            >
              <span style={{ position: "relative", zIndex: 2 }}>
                {isSendingEmail ? "Sending..." : "Submit"}
              </span>
              <Box
                className="btn-overlay"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "linear-gradient(to right, #5dc1d8, #103444)",
                  opacity: 0,
                  transition: "opacity 0.3s ease",
                  zIndex: 1,
                }}
              />
            </Button>
          </Box>
        </Box>
      </div>

      <style>{`
        @media (max-width: 768px) {
          .section3-container {
            padding: 30px 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default Section3;
