import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/altMainLogo.svg";
import target from "../../assets/target.svg";
import poweredByGenAEyeIcon from "../../assets/poweredByGenAEyeIcon.svg";

// Your icons
import eyeGear from "../../assets/eyeGear.svg";
import eyeMagnify from "../../assets/eyeMagnify.svg";
import eyeScan from "../../assets/eyeScan.svg";
import eyeScholar from "../../assets/eyeScholar.svg";
import eyeGearBlue from "../../assets/eyeGearBlue.svg";
import eyeMagnifyBlue from "../../assets/eyeMagnifyBlue.svg";
import eyeScanBlue from "../../assets/eyeScanBlue.svg";
import eyeScholarBlue from "../../assets/eyeScholarBlue.svg";
import checkmarkDark from "../../assets/checkmarkDark.svg";

const Section1 = () => {
  const [isGetStartedHovered, setIsGetStartedHovered] = useState(false);

  // Which icon is hovered
  const [hoveredIcon, setHoveredIcon] = useState("");
  // Whether we're currently fading out the hover box
  const [isFadingOut, setIsFadingOut] = useState(false);

  // Data for each icon's hover content
  const iconHoverData = {
    eyeScan: {
      title: "Physicians",
      icon: eyeScanBlue,
      bulletPoints: [
        "Diagnosis Support",
        "Image Analysis",
        "Ophthalmic Test Reports",
        "Transcription",
      ],
    },
    eyeGear: {
      title: "Operations",
      icon: eyeGearBlue,
      bulletPoints: [
        "Report Automation",
        "Coding Support",
        "Automated Notes",
        "Referral Letters",
        "Instructions to Staff",
      ],
    },
    eyeMagnify: {
      title: "Patients",
      icon: eyeMagnifyBlue,
      bulletPoints: [
        "Pre-Visit Screenings",
        "Automated Onboarding",
        "Accelerated Care",
      ],
    },
    eyeScholar: {
      title: "Scholar",
      icon: eyeScholarBlue,
      bulletPoints: [
        "Research Assistant",
        "Ophthalmic Study Guide",
        "Board Preparation",
      ],
    },
  };

  // Smoothly scroll to contact form
  const scrollToContactForm = () => {
    window.scrollTo({
      top: 3800, // Adjust to match your layout
      behavior: "smooth",
    });
  };

  // Handle mouse enter on any icon
  const handleIconMouseEnter = (iconName) => {
    // Cancel any fade-out process and set the hovered icon
    setIsFadingOut(false);
    setHoveredIcon(iconName);
  };

  // Handle mouse leave for the entire icons container
  const handleIconsMouseLeave = () => {
    // Begin fading out
    setIsFadingOut(true);
  };

  // Called when the fade transition finishes
  const handleTransitionEnd = (e) => {
    // Only reset after the opacity transition completes
    if (e.propertyName === "opacity" && isFadingOut) {
      setHoveredIcon("");
      setIsFadingOut(false);
    }
  };

  // The hover box should remain **visible** if we have a hovered icon **or** we're fading out
  const isBoxVisible = hoveredIcon !== "" || isFadingOut;

  return (
    <div
      className="section1-container"
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        background: "#e0f7fa",
        color: "#000",
        textAlign: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* Gradient Bar */}
      <div
        style={{
          width: "100%",
          height: "10px",
          background: "linear-gradient(to right, #5dc1d8, #103444)",
          position: "absolute",
          top: "0",
          left: "0",
          zIndex: 1000,
        }}
      />

      {/* Header */}
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "50px",
          paddingTop: "20px",
          position: "relative",
          zIndex: 1001, // Ensure header is above the gradient bar
        }}
      >
        {/* Logo */}
        <Link to="/" style={{ display: "inline-block" }}>
          <img src={logo} alt="Oculogyx Logo" style={{ height: 100 }} />
        </Link>

        {/* Navigation Links */}
        <div
          className="nav-links"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Link to="/" className="nav-link">
            Home
          </Link>
          <Link to="/about-us" className="nav-link">
            About
          </Link>
          <Link to="/solutions" className="nav-link">
            Solutions
          </Link>
          {/* <Link to="/news-resources" className="nav-link">
            News & Resources
          </Link> */}
          <Link to="/contact-us" className="nav-link last-nav-link">
            Contact
          </Link>
        </div>
      </header>

      {/* Main Content */}
      <div
        className="content-section"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "120px 50px",
          flexWrap: "wrap", // Responsive layout
        }}
      >
        {/* Left Text Column */}
        <div style={{ flex: 1, textAlign: "left", minWidth: "300px" }}>
          <h1
            style={{
              fontSize: "36px",
              fontWeight: 500,
              marginBottom: "20px",
              lineHeight: "1.4",
            }}
          >
            Surround Your Team and Patients With{" "}
            <span
              style={{
                background: "linear-gradient(90deg, #103444 0%, #5dc1d8 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              AI-Driven Ophthalmic Solutions
            </span>
          </h1>
          <p style={{ fontSize: "18px", marginBottom: "30px" }}>
            Our comprehensive AI platform is built to improve practice
            efficiency, support your physicians, and enhance the experience for
            your patients.
          </p>
          <button
            onClick={scrollToContactForm}
            className={`get-started-button ${
              isGetStartedHovered ? "hovered" : ""
            }`}
            onMouseEnter={() => setIsGetStartedHovered(true)}
            onMouseLeave={() => setIsGetStartedHovered(false)}
          >
            <span>Get Started</span>
            <div className="get-started-overlay"></div>
          </button>
        </div>

        {/* Right Icons/Visuals Column */}
        <div
          className="icons-section"
          style={{
            flex: 1,
            textAlign: "center",
            position: "relative",
            minWidth: "300px",
          }}
          onMouseLeave={handleIconsMouseLeave} // fade out when leaving the icons area
        >
          {/* Target Icon in the Background */}
          <div
            style={{
              position: "absolute",
              width: "800px",
              left: "0px",
              bottom: "-200px",
              zIndex: 0,
            }}
          >
            <img src={target} alt="Target Icon" style={{ width: "100%" }} />
          </div>

          {/* Circular area with icons */}
          <div
            style={{
              position: "relative",
              zIndex: 2,
              textAlign: "center",
              width: "300px",
              height: "300px",
              margin: "0 auto",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Icons in Circle */}
            {/* eyeMagnify => Patients */}
            <img
              src={eyeMagnify}
              alt="Eye Magnify Icon"
              style={{
                position: "absolute",
                top: "-220px",
                left: "15%",
                transform: "translateX(-50%)",
                width: "150px",
                cursor: "pointer",
              }}
              onMouseEnter={() => handleIconMouseEnter("eyeMagnify")}
            />

            {/* eyeGear => Operations */}
            <img
              src={eyeGear}
              alt="Eye Gear Icon"
              style={{
                position: "absolute",
                top: "20px",
                left: "-40%",
                transform: "translateY(-50%)",
                width: "150px",
                cursor: "pointer",
              }}
              onMouseEnter={() => handleIconMouseEnter("eyeGear")}
            />

            {/* eyeScan => Physicians */}
            <img
              src={eyeScan}
              alt="Eye Scan Icon"
              style={{
                position: "absolute",
                top: "130px",
                left: "-12%",
                transform: "translateX(-50%)",
                width: "150px",
                cursor: "pointer",
              }}
              onMouseEnter={() => handleIconMouseEnter("eyeScan")}
            />

            {/* eyeScholar => Scholar */}
            <img
              src={eyeScholar}
              alt="Eye Scholar Icon"
              style={{
                position: "absolute",
                top: "280px",
                left: "25%",
                transform: "translateX(-50%)",
                width: "150px",
                cursor: "pointer",
              }}
              onMouseEnter={() => handleIconMouseEnter("eyeScholar")}
            />

            {/* Center Content: "Powered By Gen A Eye" + Icon */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 3,
              }}
            >
              <img
                src={poweredByGenAEyeIcon}
                alt="Powered By Gen A Eye Icon"
                style={{
                  width: "80px",
                  position: "absolute",
                  top: "40px",
                  left: "70%",
                  transform: "translateX(-50%)",
                  marginBottom: "10px",
                }}
              />
              <p
                style={{
                  fontSize: "28px",
                  fontWeight: 500,
                  position: "absolute",
                  color: "#4B5D73",
                  top: "35px",
                  left: "80%",
                  margin: 0,
                  width: "200px",
                }}
              >
                Powered By Gen A Eye™
              </p>
            </div>
          </div>

          {/* Hovered icon info box (fades in/out) */}
          <div
            onTransitionEnd={handleTransitionEnd}
            style={{
              position: "absolute",
              top: "-30px", // Adjust to position over center text
              left: "75%", // Adjust to position over center text
              transform: "translateX(-50%)",
              background: "#C8EBF4", // Updated to match section1-container
              padding: "20px",
              borderRadius: "40px",
              width: "270px",
              textAlign: "left",
              transition: "opacity 0.3s ease, visibility 0.3s ease",
              // Always "visible" if we have data or are still fading out
              visibility: isBoxVisible ? "visible" : "hidden",
              // Fade out if we're in isFadingOut or if there's no hovered icon
              opacity: hoveredIcon && !isFadingOut ? 1 : 0,
              zIndex: 4,
            }}
          >
            {/* Only render if there's actual hover data (or if we're still fading out) */}
            {hoveredIcon && iconHoverData[hoveredIcon] && (
              <>
                <h3
                  style={{
                    marginTop: 0,
                    marginBottom: "10px",
                    color: "#374A6E",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "36px",
                  }}
                >
                  {/* Blue icon to the left of the title */}
                  <img
                    src={iconHoverData[hoveredIcon].icon}
                    alt="Blue Icon"
                    style={{
                      width: "45px",
                      height: "45px",
                      marginRight: "8px",
                    }}
                  />
                  {iconHoverData[hoveredIcon].title}
                </h3>
                <ul style={{ marginLeft: "0", paddingLeft: "20px", marginBottom: 0 }}>
                  {iconHoverData[hoveredIcon].bulletPoints.map((point, idx) => (
                    <li
                      key={idx}
                      style={{
                        marginBottom: "6px",
                        color: "#000", // bullet text color
                        listStyle: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={checkmarkDark}
                        alt="Checkmark"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      {point}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Access Oculogyx Section */}
      <div
        style={{
          backgroundColor: "#000",
          padding: "10px",
          color: "#fff",
          position: "relative",
          zIndex: 1,
        }}
      >
        <h2 style={{ fontSize: "36px", fontWeight: 500, marginBottom: "20px" }}>
          Access the Entire Oculogyx Platform from $39/mo.
        </h2>
      </div>

      {/* CSS Styles */}
      <style>{`
        /* Navigation Links Styles */
        .nav-link {
          color: #000;
          text-decoration: none;
          margin-right: 20px;
          font-size: 22px;
          cursor: pointer;
          transition: color 0.3s ease;
        }
        .nav-link.last-nav-link {
          margin-right: 0;
        }
        .nav-link:hover {
          color: #5dc1d8;
        }

        /* "Get Started" Button Styles */
        .get-started-button {
          display: inline-block;
          position: relative;
          padding: 15px 30px;
          font-size: 18px;
          color: #fff;
          background: #103444;
          border-radius: 30px;
          border: none;
          cursor: pointer;
          margin-top: 20px;
          text-decoration: none; /* Remove underline */
          overflow: hidden;
          transition: background 0.3s ease; 
        }

        /* Overlay is hidden by default */
        .get-started-button .get-started-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to right, #5dc1d8, #103444);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 1; 
        }

        .get-started-button span {
          position: relative; 
          z-index: 2; 
        }

        /* This triggers the overlay fade-in on hover or via the hovered class */
        .get-started-button:hover .get-started-overlay,
        .get-started-button.hovered .get-started-overlay {
          opacity: 1;
        }

        /* Responsive Styles */
        @media (max-width: 768px) {
          .section1-container {
            padding: 20px;
          }
          header {
            flex-direction: column;
            align-items: flex-start;
          }
          .nav-links {
            flex-direction: column;
            align-items: flex-start;
          }
          .nav-link {
            margin-right: 0;
            margin-bottom: 10px;
          }
          .content-section {
            flex-direction: column;
          }
          .icons-section {
            margin-top: 30px;
          }
          /* Scale icons smaller if needed */
          .icons-section img {
            width: 100px !important; 
          }
          /* Adjust overlay position on small screens */
          .icons-section > div:nth-of-type(2) {
            left: 50% !important; /* e.g. center it */
          }
        }
      `}</style>
    </div>
  );
};

export default Section1;
