import React, { useState } from "react";
import cardGradient1 from "../../assets/homePage/section2/cardGradient1.png";
import cardGradient2 from "../../assets/homePage/section2/cardGradient2.png";
import cardGradient3 from "../../assets/homePage/section2/cardGradient3.png";
import conversation from "../../assets/homePage/section2/conversation.png";
import referralLetter from "../../assets/homePage/section2/referralLetter.png";
import chartNote from "../../assets/homePage/section2/chartNote.png";
import conversationIcon from "../../assets/homePage/section2/conversationIcon.svg";
import chartNoteIcon from "../../assets/homePage/section2/chartNoteIcon.svg";
import referralLetterIcon from "../../assets/homePage/section2/referralLetterIcon.svg";
import gradient from "../../assets/homePage/section2/gradient.png";
import step1 from "../../assets/homePage/section2/step1.png";
import step2 from "../../assets/homePage/section2/step2.png";
import step3 from "../../assets/homePage/section2/step3.png";
import oculogyxOneIcon from "../../assets/homePage/section2/oculogyxOneIcon.svg";
import oculogyxTwoIcon from "../../assets/homePage/section2/oculogyxTwoIcon.svg";
import oculogyxThreeIcon from "../../assets/homePage/section2/oculogyxThreeIcon.svg";

const Section2 = () => {
  const [activeScreenshot, setActiveScreenshot] = useState(conversation);

  return (
    <div
      className="section2-outer"
      style={{
        backgroundImage: `url(${gradient})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "50px 0",
        maxWidth: "1200px",
        margin: "0 auto",
        color: "#000",
      }}
    >
      <div
        className="section2-inner"
        style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 50px" }}
      >
        <h2
          style={{
            textAlign: "center",
            fontSize: "36px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          Analyze any Image and Receive an{" "}
          <span
            style={{
              background: "linear-gradient(to right, #5dc1d8, #103444)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Interpretation in Seconds
          </span>
        </h2>
        <p
          style={{
            textAlign: "center",
            fontSize: "32px",
            marginBottom: "40px",
            marginTop: "10px",
            fontWeight: "500",
          }}
        >
          It's as easy as 1, 2, 3
        </p>
        <div
          className="steps-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          {/* Step 1 */}
          <div style={{ width: "30%", textAlign: "center" }}>
            <div
              style={{
                backgroundImage: `url(${cardGradient1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <img
                src={step1}
                alt="Step 1"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <img
                src={oculogyxOneIcon}
                alt="Step 1 Icon"
                style={{ height: "100px", marginRight: "15px" }}
              />
              <p style={{ fontSize: "24px", fontWeight: "500", margin: 0, color: "#36496E" }}>
                Upload
              </p>
            </div>
          </div>

          {/* Step 2 */}
          <div style={{ width: "30%", textAlign: "center" }}>
            <div
              style={{
                backgroundImage: `url(${cardGradient2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <img
                src={step2}
                alt="Step 2"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <img
                src={oculogyxTwoIcon}
                alt="Step 2 Icon"
                style={{ height: "100px", marginRight: "15px" }}
              />
              <p style={{ fontSize: "24px", fontWeight: "500", margin: 0, color: "#36496E" }}>
                Analyze
              </p>
            </div>
          </div>

          {/* Step 3 */}
          <div style={{ width: "30%", textAlign: "center" }}>
            <div
              style={{
                backgroundImage: `url(${cardGradient3})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <img
                src={step3}
                alt="Step 3"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <img
                src={oculogyxThreeIcon}
                alt="Step 3 Icon"
                style={{ height: "100px", marginRight: "15px" }}
              />
              <p style={{ fontSize: "24px", fontWeight: "500", margin: 0, color: "#36496E" }}>
                Review
              </p>
            </div>
          </div>
        </div>
        <div
          className="features-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: "50%", paddingRight: "20px" }}>
            <h3
              style={{
                fontSize: "36px",
                fontWeight: "500",
                marginBottom: "20px",
              }}
            >
              Improve Your Patient Interaction as You{" "}
              <span
                style={{
                  background: "linear-gradient(to right, #5dc1d8, #103444)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Automate Your Charting and Reporting
              </span>
            </h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {[
                {
                  icon: conversationIcon,
                  text: "Patient Conversations",
                  image: conversation,
                },
                { icon: chartNoteIcon, text: "Chart Notes", image: chartNote },
                {
                  icon: referralLetterIcon,
                  text: "Referral Letters",
                  image: referralLetter,
                },
              ].map(({ icon, text, image }, idx) => (
                <li
                  key={text}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    cursor: "pointer",
                    paddingBottom: idx < 2 ? "10px" : "0",
                    borderBottom: idx < 2 ? "1px solid #ddd" : "none",
                  }}
                  onMouseOver={() => setActiveScreenshot(image)}
                >
                  <img
                    src={icon}
                    alt={`${text} Icon`}
                    style={{ height: "50px", marginRight: "15px" }}
                  />
                  <span style={{ fontSize: "20px", fontWeight: "500" }}>
                    {text}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div
            style={{
              width: "50%",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "25px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img
              src={activeScreenshot}
              alt="Dynamic Screenshot"
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
      <style>{`
        @media (max-width: 768px) {
          .section2-outer {
            padding: 20px 0;
          }
          .section2-inner {
            padding: 20px;
          }
          .steps-container,
          .features-container {
            flex-direction: column;
            align-items: center;
          }
          .steps-container > div,
          .features-container > div {
            width: 100% !important;
            margin-bottom: 20px;
            padding-right: 0 !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Section2;
