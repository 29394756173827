import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Chip,
  AppBar,
  Toolbar,
  Container,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Hub } from "@aws-amplify/core";
import { Authenticator } from "@aws-amplify/ui-react";
import Markdown from "react-markdown";
import "@aws-amplify/ui-react/styles.css";
import "../App.css";
import copyIcon from "../assets/copyIcon.png";
import logo from "../assets/mainLogo.svg";
import { getSessionJwt } from "../util/utilities";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import HeadsetOffIcon from "@mui/icons-material/HeadsetOff";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from "@mui/icons-material/Send";
import { ENDPOINTS } from "../util/constants";
import jsPDF from "jspdf";
import axios from "axios";
import DropDownButton from "../components/DropDownButton";
import OldImageBannerWithText from "../components/OldImageBannerWithText";
import SubscriptionModal from "../components/SubscriptionModal"; // Ensure this is imported
import blueEye from "../assets/homePage/section3/blueEye.jpg";

const SuggestedPrompts = ({
  setInput,
  setMessages,
  sendMessage,
  hidePrompts,
  prompts = [],
}) => {
  const handlePromptClick = (prompt) => {
    setInput(prompt);
    setTimeout(() => {
      const form = document.querySelector("form.chat-input");
      const submitButton = form.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
      hidePrompts();
    }, 100);
  };

  return prompts.length ? (
    <Box sx={{ textAlign: "center", marginY: 2 }}>
      <Typography variant="h6" gutterBottom>
        Suggested Prompts
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {prompts.map((prompt, index) => (
          <Grid item key={index}>
            <Button
              variant="outlined"
              onClick={() => handlePromptClick(prompt)}
              sx={{
                minWidth: 200,
                padding: "15px 20px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                border: "1px solid #ddd",
                textAlign: "center",
                fontSize: "14px",
                color: "#333",
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {prompt}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : null;
};

function populateHeaderCategories(menuItems, isMobile, open, setOpen) {
  const items = menuItems.map((value, index) => (
    <Grid
      key={index}
      item
      xs={value?.subItems?.length ? 2 : value?.text?.length > 10 ? 2 : 1}
      sx={{ textAlign: "center" }}
    >
      <DropDownButton dropDownItem={value} isMobile={isMobile} />
    </Grid>
  ));
  return (
    <>
      {isMobile && (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={logo} alt="Oculogyx Logo" style={{ height: 40 }} />
              </Box>
            </Link>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Button
              disableRipple
              sx={{
                color: "#FFFFFF",
                fontSize: isMobile ? 10 : 14,
                fontFamily: "system-ui",
                fontWeight: 400,
              }}
              onClick={() => setOpen((o) => !o)}
            >
              {`${!open ? "Open" : "Hide"} Menu`}
            </Button>
          </Box>
        </>
      )}
      {((open && isMobile) || !isMobile) && (
        <Grid container spacing={2} justifyContent="center">
          {items}
        </Grid>
      )}
    </>
  );
}

export default function GptLayout(props) {
  const {
    headerText,
    subheaderText,
    getChat,
    getChats,
    sendMessage,
    isLoading,
    setIsLoading,
    input,
    setInput,
    responseText,
    setResponseText,
    messages,
    setMessages,
    autoRead,
    setAutoRead,
    isPlaying,
    waitingAfterDone,
    setWaitingAfterDone,
    isLoadingPastChats,
    pastChats,
    prompts,
  } = props;

  const [subscriptionStatus, setSubscriptionStatus] = useState("PENDING");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [authActivity, setAuthActivity] = useState(0);
  const [pastChatsWindow, setPastChatsWindow] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const [files, setFiles] = useState([]);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [showSpeechRecognitionAlert, setShowSpeechRecognitionAlert] =
    useState(false);
  const inputRef = useRef(null);

  const {
    transcript,
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const isBraveBrowser = () => navigator.brave !== undefined;
  const speechRecognitionAvailable =
    browserSupportsSpeechRecognition && !isBraveBrowser();

  useEffect(() => {
    if (responseText && waitingAfterDone && isLoading) {
      setMessages((prev) => [...prev, { text: responseText, sender: "gpt" }]);
      setResponseText("");
      setWaitingAfterDone(false);
      setIsLoading(false);
      setShowPrompts(false);
    }
  }, [
    responseText,
    waitingAfterDone,
    isLoading,
    setMessages,
    setResponseText,
    setWaitingAfterDone,
    setIsLoading,
  ]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .catch((err) => console.error("Failed to copy text:", err));
  };

  const toggleConversation = () => {
    if (listening || autoRead) {
      if (speechRecognitionAvailable) SpeechRecognition.stopListening();
      setAutoRead(false);
    } else {
      if (!speechRecognitionAvailable) {
        setShowSpeechRecognitionAlert(true);
        setAutoRead(true);
      } else {
        SpeechRecognition.startListening();
        setAutoRead(true);
      }
    }
  };

  useEffect(() => {
    if (isLoading || isPlaying) {
      if (listening && speechRecognitionAvailable)
        SpeechRecognition.stopListening();
    } else if (autoRead && !listening && speechRecognitionAvailable) {
      SpeechRecognition.startListening();
    }
  }, [isLoading, isPlaying, autoRead, listening, speechRecognitionAvailable]);

  // Updated createCheckoutAndRedirect function
  const createCheckoutAndRedirect = async (selectedSubscriptionName) => {
    try {
      const jwtToken = await getSessionJwt();
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/create-checkout`,
        {
          method: "POST", // Changed to POST
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ subscriptionName: selectedSubscriptionName }), // Include subscription name
        }
      );

      const data = await response.json();
      if (data.results?.checkoutURL) {
        window.location.href = data.results.checkoutURL;
      } else {
        console.error("Error creating checkout: Missing checkout URL in response.");
      }
    } catch (error) {
      console.error("Error creating checkout:", error);
    }
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setSubscriptionStatus("Refresh page!");
        return;
      }
      const response = await fetch(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/get-subscription-status`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      if (!data.results) {
        setSubscriptionStatus("Error. Refresh page!");
        return;
      }
      setSubscriptionStatus(data.results.subscriptionStatus);
      if (inputRef.current) inputRef.current.focus();
    };
    fetchSubscriptionStatus();
  }, [authActivity]);

  const hubListenerCancel = Hub.listen("auth", (data) => {
    setAuthActivity((p) => p + 1);
    if (data.payload.event === "signedIn") hubListenerCancel();
  });

  const prevListeningRef = useRef(listening);
  useEffect(() => {
    if (!speechRecognitionAvailable) return;
    if (prevListeningRef.current && !listening && finalTranscript?.trim()) {
      const combinedText = input + " " + finalTranscript;
      handleSend({ preventDefault: () => {} }, combinedText);
      resetTranscript();
      setInput("");
    }
    prevListeningRef.current = listening;
  }, [
    listening,
    finalTranscript,
    speechRecognitionAvailable,
    input,
    resetTranscript,
    setInput,
  ]);

  const endOfMessagesRef = useRef(null);
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleFocus = () => {
    if (input.length) setShowPrompts(false);
  };

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSend = (e, textToSend) => {
    e.preventDefault();
    const text = textToSend || input;
    if (text.trim()) {
      if (speechRecognitionAvailable && listening)
        SpeechRecognition.stopListening();
      sendMessage({ event: e, text, files });
      setFiles([]);
      setInput("");
    }
  };

  const generatePDF = (shouldSave = true) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    let yOffset = 20;
    doc.setFontSize(14);
    doc.text("Conversation Summary", margin, yOffset);
    yOffset += 10;
    messages.forEach((message) => {
      const sender = message.sender === "user" ? "User" : "Assistant";
      const wrappedText = doc.splitTextToSize(
        `${sender}: ${message.text}`,
        pageWidth - margin * 2
      );
      if (
        yOffset + wrappedText.length * 10 >
        doc.internal.pageSize.getHeight() - margin
      ) {
        doc.addPage();
        yOffset = margin;
      }
      doc.setFontSize(12);
      doc.setTextColor(message.sender === "user" ? "#007bff" : "#000000");
      doc.text(wrappedText, margin, yOffset);
      yOffset += wrappedText.length * 7;
    });
    if (shouldSave) doc.save("chat-transcript.pdf");
    return doc;
  };

  const handleSendEmail = async () => {
    if (!emailAddress) {
      alert("Please enter an email address");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(emailAddress)) {
      alert("Please enter a valid email address");
      return;
    }
    try {
      setIsSendingEmail(true);
      const doc = generatePDF(false);
      const pdfBlob = await doc.output("blob");
      const formData = new FormData();
      formData.append("toEmail", emailAddress);
      formData.append("subject", "Chat Transcript");
      formData.append(
        "textContent",
        "Please find the attached chat transcript."
      );
      formData.append("pdf", pdfBlob, "chat-transcript.pdf");
      const jwtToken = await getSessionJwt();
      const res = await axios.post(
        `${ENDPOINTS.EC2_ENDPOINT}/assistants/send-pdf-email`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.success) {
        alert("Email sent successfully");
        setEmailModalOpen(false);
        setEmailAddress("");
      } else {
        alert("Failed to send email");
      }
    } catch (err) {
      console.error("Error sending email:", err);
      alert("An error occurred while sending the email");
    } finally {
      setIsSendingEmail(false);
    }
  };

  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const handleOpenSubscriptionModal = (e) => {
    e.preventDefault();
    setIsSubscriptionModalOpen(true);
  };

  const handleCloseSubscriptionModal = () => {
    setIsSubscriptionModalOpen(false);
  };

  const handleSubscriptionSelect = (selectedSubscription) => {
    createCheckoutAndRedirect(selectedSubscription.name); // Pass subscription name
  };

  const [open, setOpen] = useState(false);
  const menuItems = [
    { text: "GenA Eye™", link: "/genaeye" },
    { text: "Image Analysis", link: "/image-analysis" },
    {
      text: "Practice Tools",
      subItems: [
        { text: "Ophthalmic Scribe", link: "/ophthalmic-scribe" },
        { text: "Ophthalmic Triage", link: "/ophthalmic-triage" },
        { text: "Refractive Surgery Advisor", link: "/refractive-surgery" },
        {
          text: "Wavefront Optimized Treatment Plan",
          link: "/wavefront-optimized-treatment-plan",
        },
        {
          text: "Practice Information Agent",
          link: "/practice-information-agent",
        },
        { text: "Ophthalmic Pre-Visit Interview", link: "/previsit-interview" },
      ],
    },
    {
      text: "OPHTHALMIC SCHOLAR",
      link: "/ophthalmic-scholar",
      subItems: [
        { text: "Ophthalmic Study Guide", link: "/ophthalmic-study-guide" },
        { text: "OculogyX Research Assistant", link: "/research-assistant" },
        {
          text: "Ophthalmic Patient History",
          link: "/ophthalmic-patient-history",
        },
      ],
    },
  ];

  return (
    <>
      <div
        style={{
          background: "#e0f7fa",
          minHeight: "80vh",
          position: "relative",
        }}
      >
        {!isMobile ? (
          <AppBar position="static" sx={{ background: "#103444" }}>
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                <Link to="/" style={{ display: "inline-block" }}>
                  <img src={logo} alt="Oculogyx Logo" style={{ width: 150 }} />
                </Link>
              </Box>
              {populateHeaderCategories(menuItems, isMobile, open, setOpen)}
            </Toolbar>
          </AppBar>
        ) : (
          <Box sx={{ bgcolor: "#103444" }}>
            <Container maxWidth="mx">
              {populateHeaderCategories(menuItems, isMobile, open, setOpen)}
            </Container>
          </Box>
        )}

        <OldImageBannerWithText
          header={headerText}
          subHeader={
            subheaderText ??
            "An AI digital assistant for Ophthalmic Care professionals."
          }
          imgLink={blueEye}
          imgAltText="EyeGpt"
          height={isMobile ? 95 : 150}
        />
        <Authenticator signUpAttributes={["email"]}>
          {({ signOut, user }) => (
            <main>
              {/* Past Chats Modal */}
              <Modal open={pastChatsWindow}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: isMobile ? "35vh" : "50vh",
                    height: "80vh",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    marginY: "20px",
                    overflowY: "auto",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "1.2rem",
                    }}
                  >
                    Previous Chats (click to go to)
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      onClick={() => setPastChatsWindow(!pastChatsWindow)}
                      sx={{ fontSize: "1.1rem" }}
                    >
                      Close
                    </Button>
                  </Box>
                  {isLoadingPastChats && (
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1.1rem",
                      }}
                    >
                      Loading past chats...
                    </Typography>
                  )}
                  {pastChats.map((chat, index) => (
                    <div key={index}>
                      <Button
                        onClick={(e) => {
                          getChat(e, chat.chatID || chat.threadID);
                          setPastChatsWindow(!pastChatsWindow);
                        }}
                        sx={{ fontSize: "1.1rem" }}
                      >
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ fontSize: "1rem" }}
                        >
                          <b>{chat.name}</b>
                        </Typography>
                      </Button>
                    </div>
                  ))}
                </Box>
              </Modal>

              {/* Email Modal */}
              <Modal
                open={emailModalOpen}
                onClose={() => setEmailModalOpen(false)}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: isMobile ? "80%" : 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontSize: "1.3rem", mb: 2 }}
                  >
                    Send Chat Transcript via Email
                  </Typography>
                  <TextField
                    label="Recipient Email"
                    fullWidth
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    sx={{ mb: 2, fontSize: "1.1rem" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendEmail}
                    sx={{ mb: 2, fontSize: "1.1rem" }}
                    disabled={isSendingEmail}
                  >
                    {isSendingEmail ? "Sending..." : "Send"}
                  </Button>
                </Box>
              </Modal>

              {/* Speech Recognition Alert Modal */}
              <Modal
                open={showSpeechRecognitionAlert}
                onClose={() => setShowSpeechRecognitionAlert(false)}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 300,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontSize: "1.3rem", mb: 2 }}
                  >
                    Speech Recognition Unavailable
                  </Typography>
                  <Typography sx={{ mb: 2, fontSize: "1.1rem" }}>
                    Your browser does not support speech recognition. The
                    assistant will still read out responses, but voice input is
                    not available.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowSpeechRecognitionAlert(false)}
                    sx={{ fontSize: "1.1rem" }}
                  >
                    OK
                  </Button>
                </Box>
              </Modal>

              {/* Subscription Modal */}
              <SubscriptionModal
                open={isSubscriptionModalOpen}
                onClose={handleCloseSubscriptionModal}
                onSubscriptionSelect={handleSubscriptionSelect}
              />

              {/* Main Content Grid */}
              <Grid container alignItems="center">
                {/* Sidebar */}
                <Grid
                  item
                  xs={12}
                  sm={1.5}
                  sx={{ textAlign: "center", bgcolor: "#E1D7CB" }}
                >
                  <div
                    className={!isMobile ? "gpt-sidebar-container" : ""}
                    style={{ height: isMobile ? "auto" : "calc(100vh - 236px)" }}
                  >
                    <br />
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ fontSize: "1.3rem" }}
                    >
                      Welcome back, <b>{user.username}</b>
                    </Typography>
                    <br />
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ fontSize: "1.2rem" }}
                    >
                      Subscription Status:{" "}
                      <b
                        style={{
                          color:
                            subscriptionStatus === true
                              ? "green"
                              : subscriptionStatus === false
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {subscriptionStatus === true
                          ? "Active"
                          : subscriptionStatus === false
                          ? "Inactive"
                          : subscriptionStatus}
                      </b>
                    </Typography>

                    <br />
                    <Button
                      sx={{
                        background: "#103444",
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "20px",
                        fontSize: "1.1rem",
                        "&:hover": {
                          bgcolor: "#0a1e30",
                        },
                      }}
                      onClick={(e) => {
                        getChats(e);
                        setPastChatsWindow(!pastChatsWindow);
                      }}
                    >
                      View Past Chats
                    </Button>
                    {subscriptionStatus === true && (
                      <Button
                        href="https://billing.stripe.com/p/login/3csaHQcOy9G2g6c6oo"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          background: "#103444",
                          color: "white",
                          fontWeight: "bold",
                          marginTop: "20px",
                          fontSize: "1.1rem",
                          "&:hover": {
                            bgcolor: "#0a1e30",
                          },
                        }}
                      >
                        Manage Subscription
                      </Button>
                    )}
                    {subscriptionStatus === false && (
                      <Button
                        sx={{
                          background: "#103444",
                          color: "white",
                          fontWeight: "bold",
                          marginTop: "20px",
                          fontSize: "1.1rem",
                          "&:hover": {
                            bgcolor: "#0a1e30",
                          },
                        }}
                        onClick={handleOpenSubscriptionModal}
                      >
                        Get a Subscription
                      </Button>
                    )}
                    <br />
                    <Button
                      sx={{
                        bgcolor: "darkred",
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "20px",
                        fontSize: "1.1rem",
                        "&:hover": {
                          bgcolor: "#460000",
                        },
                      }}
                      onClick={signOut}
                    >
                      Sign Out
                    </Button>
                  </div>
                </Grid>

                {/* Main Chat Area */}
                <Grid item xs={12} sm={10.5}>
                  <div
                    className={
                      isMobile ? "app-container-mobile" : "app-container"
                    }
                    style={{
                      height: isMobile ? "auto" : "calc(100vh - 236px)",
                    }}
                  >
                    <div className="chat-container">
                      {/* Suggested Prompts */}
                      {!isMobile && showPrompts && prompts?.length > 0 && (
                        <SuggestedPrompts
                          setInput={setInput}
                          setMessages={setMessages}
                          sendMessage={sendMessage}
                          hidePrompts={() => setShowPrompts(false)}
                          prompts={prompts}
                        />
                      )}

                      {/* Chat Messages */}
                      <div className="chat-messages">
                        {messages.map((message, index) => (
                          <div
                            key={index}
                            className={`message ${
                              message.sender === "user"
                                ? "user-message"
                                : "api-message"
                            }`}
                            style={{ position: "relative", fontSize: 18 }}
                          >
                            <Markdown className="message-content">
                              {message.text}
                            </Markdown>
                            <button
                              className="copy-icon"
                              onClick={() => copyToClipboard(message.text)}
                              style={{
                                position: "absolute",
                                top: 5,
                                right: 5,
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                                opacity: 1,
                                zIndex: 10,
                              }}
                            >
                              <img src={copyIcon} alt="Copy" />
                            </button>
                          </div>
                        ))}
                        {isLoading && (
                          <div
                            key="loadingDots"
                            className={`message api-message`}
                          >
                            {responseText + ` ...`}
                          </div>
                        )}
                        {/* This is the element to scroll into view */}
                        <div ref={endOfMessagesRef} />
                      </div>

                      {/* Chat Input Form */}
                      <form className="chat-input" onSubmit={handleSend}>
                        <div className="input-wrapper">
                          {/* Moved IconButtons to the left of TextField */}
                          <IconButton onClick={toggleConversation}>
                            {listening || autoRead ? (
                              <HeadsetMicIcon color="primary" />
                            ) : (
                              <HeadsetOffIcon />
                            )}
                          </IconButton>
                          <IconButton
                            onClick={() => generatePDF(true)}
                            color="primary"
                          >
                            <DownloadIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => setEmailModalOpen(true)}
                            color="primary"
                          >
                            <EmailIcon />
                          </IconButton>
                          <TextField
                            variant="outlined"
                            placeholder="Type a message..."
                            fullWidth
                            value={
                              input +
                              (listening
                                ? input?.length
                                  ? " " + transcript
                                  : transcript
                                : "")
                            }
                            disabled={subscriptionStatus !== true}
                            onChange={(e) => setInput(e.target.value)}
                            inputRef={inputRef}
                            sx={{
                              bgcolor: "white",
                              marginLeft: "8px",
                              marginRight: "8px",
                            }} // Added margins
                            onFocus={handleFocus}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <input
                                    accept="image/*,video/*"
                                    style={{ display: "none" }}
                                    id="file-upload"
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                  />
                                  <label htmlFor="file-upload">
                                    <IconButton component="span">
                                      <AttachFileIcon />
                                    </IconButton>
                                  </label>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <IconButton
                            type="submit"
                            color="primary"
                            disabled={!input?.trim().length}
                          >
                            <SendIcon />
                          </IconButton>
                        </div>
                        {!!files.length && (
                          <div className="file-chips">
                            {files.map((file, idx) => (
                              <Chip
                                key={idx}
                                label={file.name}
                                onDelete={() => handleRemoveFile(idx)}
                                style={{ margin: "5px", fontSize: "1rem" }}
                              />
                            ))}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </main>
          )}
        </Authenticator>
      </div>
    </>
  );
}
