import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { getSessionJwt } from "../util/utilities";
import { ENDPOINTS } from "../util/constants";

export default function SubscriptionModal({
  open,
  onClose,
  onSubscriptionSelect,
}) {
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Mapping subscription names to additional details
  const subscriptionDetails = {
    Starter: {
      pointsPerMonth: 40000,
      overageRate: 0.001,
    },
    Pro: {
      pointsPerMonth: 125000,
      overageRate: 0.0008,
    },
    "Pro +": {
      pointsPerMonth: 250000,
      overageRate: 0.0007,
    },
    "Super User": {
      pointsPerMonth: 500000,
      overageRate: 0.0005,
    },
  };

  const fetchSubscriptions = async () => {
    try {
      setIsLoading(true); // Start loading
      const jwtToken = await getSessionJwt(); // Get your JWT token
      const response = await axios.get(
        `${ENDPOINTS.EC2_ENDPOINT}/subscription/get-available-subscriptions`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.data?.results?.success) {
        let subscriptions = response.data.results.subscriptions;

        // Enhance each subscription with pointsPerMonth and overageRate
        subscriptions = subscriptions.map((sub) => {
          const details = subscriptionDetails[sub.name] || {};
          return {
            ...sub,
            pointsPerMonth: details.pointsPerMonth || "N/A",
            overageRate: details.overageRate
              ? `$${details.overageRate.toFixed(4)}/pt.`
              : "N/A",
          };
        });

        setSubscriptionOptions(subscriptions);

        // Set the first subscription as the default selected option
        if (subscriptions.length > 0) {
          setSelectedSubscription(subscriptions[0]);
        }
      } else {
        alert("Failed to fetch subscription options.");
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      alert("An error occurred while fetching subscriptions.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (open) {
      fetchSubscriptions();
    }
    // Reset selected subscription when modal opens/closes
    return () => {
      setSelectedSubscription(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSubscriptionSelect = (subscription) => {
    setSelectedSubscription(subscription);
  };

  const handleGetSubscription = () => {
    if (!selectedSubscription) {
      alert("Please select a subscription option.");
      return;
    }
    onSubscriptionSelect(selectedSubscription); // Pass selected subscription to parent
    onClose(); // Close modal after selection
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, sm: 400 },
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{ mb: 2, textAlign: "center" }}
        >
          Select a Subscription
        </Typography>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : subscriptionOptions.length > 0 ? (
          subscriptionOptions.map((option, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 2,
                p: 2,
                border: "1px solid #ddd",
                borderRadius: "8px",
                cursor: "pointer",
                backgroundColor:
                  selectedSubscription?.name === option.name
                    ? "#e0f7fa"
                    : "#fff",
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}
              onClick={() => handleSubscriptionSelect(option)}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                {option.name}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>Price:</strong> ${option.price} / month
              </Typography>
              <Typography variant="body2">
                <strong>Points:</strong> {option.pointsPerMonth.toLocaleString()} pts/month
              </Typography>
              <Typography variant="body2">
                <strong>Overage:</strong> {option.overageRate}
              </Typography>
              {selectedSubscription?.name === option.name && (
                <Chip label="Selected" color="primary" size="small" sx={{ mt: 1 }} />
              )}
            </Box>
          ))
        ) : (
          <Typography>No subscriptions available.</Typography>
        )}
        {!isLoading && subscriptionOptions.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleGetSubscription}
            sx={{ mt: 2 }}
          >
            Get Subscription
          </Button>
        )}
      </Box>
    </Modal>
  );
}
