// TermsAndConditionsLayout.jsx
import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";
import logo from "../assets/altMainLogo.svg"; // Adjust the path as needed
import MasterLayout from "./MasterLayout";

const TermsAndConditionsLayout = () => {
  // Define reusable style objects for navigation links
  const navLinkStyle = {
    color: "#000", // Black text for light background
    textDecoration: "none",
    marginRight: "20px",
    fontSize: "22px",
    cursor: "pointer",
    transition: "color 0.3s ease",
  };

  const navLinkHoverStyle = {
    color: "#5dc1d8", // Blue color on hover
  };

  // Function to handle hover effect
  const handleNavLinkHover = (e) => {
    e.target.style.color = navLinkHoverStyle.color;
  };

  const handleNavLinkOut = (e) => {
    e.target.style.color = navLinkStyle.color;
  };

  return (
    <MasterLayout>
      <div style={{ backgroundColor: "black" }}>
      <Box
        className="terms-container"
        sx={{
          maxWidth: "1200px",
          margin: "0 auto",
          backgroundColor: "#fff", // White background for readability
          color: "#000", // Black text for contrast
          position: "relative",
          minHeight: "100vh",
          paddingTop: "20px",
          paddingBottom: "50px",
        }}
      >
        {/* Gradient Bar at the Top */}
        <Box
          sx={{
            width: "100%",
            height: "10px",
            background: "linear-gradient(to right, #5dc1d8, #103444)",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "1000",
          }}
        ></Box>

        <Container sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
          {/* Navigation Header */}
          <Box
            className="top-nav"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "30px",
              position: "relative",
              zIndex: "1001",
            }}
          >
            {/* Logo */}
            <Link to="/" style={{ display: "inline-block" }}>
              <img src={logo} alt="Oculogyx Logo" style={{ height: 100 }} />
            </Link>

            {/* Navigation Links */}
            <Box
              className="nav-links"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Link
                to="/"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Home
              </Link>
              <Link
                to="/about-us"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                About
              </Link>
              <Link
                to="/solutions"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Solutions
              </Link>
              {/* Uncomment if needed
              <Link
                to="/news-resources"
                style={navLinkStyle}
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                News & Resources
              </Link>
              */}
              <Link
                to="/contact-us"
                style={{ ...navLinkStyle, marginRight: "0" }} // Remove margin on last link
                onMouseOver={handleNavLinkHover}
                onMouseOut={handleNavLinkOut}
              >
                Contact
              </Link>
            </Box>
          </Box>

          {/* Terms and Conditions Content */}
          <Box my={4}>
            <Typography
              variant="h4"
              component="h1"
              align="center"
              sx={{ fontWeight: "bold", my: 4 }}
            >
              Terms and Conditions
            </Typography>
            <Typography variant="body1" component="div" sx={{ lineHeight: 1.6 }}>
              {/* Begin Terms and Conditions Content */}
              <p>
                <strong>REGISTERED USER AGREEMENT</strong>
              </p>
              <p>
                This Registered User Agreement is made between OCULOGYX INC., a
                Wyoming corporation (“Company”), and any person (“User”) who is
                authorized to register with the Company to access and use the
                GenA Eye™ Database Software and Website as defined below. Company
                and User are collectively referred to as “parties”.
              </p>
              <p>
                The Company reserves the right to modify this Agreement at any
                time and without prior notice by posting amended terms on the
                Website. User’s continued use of the GenA Eye™ Database and
                Software and restricted features of the Website indicates User’s
                acceptance of the amended Registered User Agreement.
              </p>
              <p>
                BY CLICKING THE “I ACCEPT” BUTTON OR ACCESSING OR USING ANY PART
                OF THE GENAEYE DATABASE SOFTWARE OR OTHER RESTRICTED FEATURES OF
                THE WEBSITE, USER INDIVIDUALLY AND ON BEHALF OF THE ENTITY FOR WHOM
                USER IS AUTHORIZED TO ACCESS THE GENAEYE DATABASE SOFTWARE OR
                OTHER FEATURES OF THE WEBSITE EXPRESSLY AGREES TO AND CONSENTS TO
                BE BOUND BY THE TERMS OF THIS AGREEMENT, THE TERMS OF USE, AND THE
                PRIVACY POLICY GOVERNING THE USE OF THE WEBSITE WWW.OCULOGYX.AI.
                IF USER DOES NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, USER
                MAY NOT ACCESS OR USE ANY PART OF THE GENAEYE DATABASE SOFTWARE
                OR OTHER RESTRICTED FEATURES OF THE WEBSITE.
              </p>

              {/* Description of GenA Eye™ */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Description of GenA Eye™
              </Typography>
              <p>
                (a) GenA Eye™ is a proprietary cloud-based database of identifying
                markers, conditions, factors, and other information related to eye
                health and eye conditions (“Database”) and proprietary software
                that uses artificial intelligence to enable health care
                practitioners to search the database and narrow the scope of such
                searches to identify subsets of eye conditions, which allows such
                practitioners to more efficiently analyze, diagnose, understand, and
                treat eye health and eye conditions of their patients
                (“Software”). The GenA Eye™ Database and Software were developed by
                and are owned, operated, and maintained by the Company. The GenA Eye™
                Database and Software are proprietary to Company and are protected
                by intellectual property laws.
              </p>
              <p>
                (b) Company and registered users of WWW.OCULOGYX.AI (the
                “Website”) will be able to upload data, images, including
                topographies of patients’ eyes, and other eye health data
                pertaining to patients (collectively “Data”) to the Database. All
                Data uploaded to the GenA Eye™ database shall not contain any
                “Protected Health Information” (as that term is defined in the
                HIPAA privacy rule at 45 C.F.R. §160.103) and shall be
                de-identified in accordance with 45 C.F.R. §164.514(b) of the
                HIPAA privacy rule (“De-Identified”). Users who upload such Data
                shall be solely responsible for ensuring all Data is De-Identified
                prior to uploading it to the Database. For the avoidance of doubt,
                De-Identified health information neither identifies nor provides a
                reasonable basis to identify an individual.
              </p>
              <p>
                (c) User agrees to comply with applicable privacy laws and the
                Content Standards set forth in the Terms of Use in connection with
                any content posted to the Website by User, including but not
                limited to blogs, discussion boards, or other interactive features
                of the Website. The Company has the right in its sole discretion,
                but not the obligation, to remove any content posted to the Website
                by a User.
              </p>
              <p>
                (d) By submitting Data and other content to the Database and
                Website, User acknowledges and agrees that such Data and content is
                available to the public, and User hereby grants to the Company a
                non-exclusive, worldwide, royalty-free, perpetual, irrevocable,
                transferable, and sublicensable right and license to use, copy,
                reproduce, modify, distribute, publish, translate, create derivative
                works from, and process such Data and content in whole or part in
                other works in any form, media, or technology now known or later
                developed without any further consent, notice, and/or compensation
                to User or others. Nothing in this Agreement shall restrict the
                right of Company from the sale, lease, license, or other transfer of
                the Data or from publishing, disseminating, or otherwise disclosing
                the Data individually or in the aggregate with other Data received
                by the Company.
              </p>
              <p>
                (e) Only the following persons will be authorized to access the
                GenA Eye™ Database and Software and other restricted features of
                the Website: individuals 18 years or older; residents of the
                United States; persons who are registered with the Company; and
                persons who execute this Agreement and pay the monthly
                Subscription Fee. Access to and use of the GenA Eye™ Database and
                Software is meant for health care professionals for purposes of
                diagnosis and treatment of eye conditions and for other research,
                public health, or health care operations as determined by the
                Company in its sole discretion, subject to compliance with
                applicable law. Any transactions which may arise between users
                from their use of the Database, Software, or Website (e.g., diagnosis
                or treatment of a patient) are the sole responsibility of the
                users involved in such transaction.
              </p>
              <p>
                (f) User agrees to provide the Company and its third-party payment
                processor with accurate and complete information, including User’s
                name, address, telephone number, email address, and credit card number
                (collectively “Registration Information”) and to promptly update
                such information as necessary. User is solely responsible for
                ensuring all Registration Information is current and accurate.
                Failure to comply with this provision may, at the Company’s sole
                discretion, result in the immediate suspension or termination of
                User’s right to access and/or use the Database, Software, and other
                restricted features of the Website.
              </p>

              {/* Grant of License */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Grant of License
              </Typography>
              <p>
                (a) Company hereby grants to User a non-transferable,
                non-assignable, non-exclusive, revocable license to access and use
                the GenA Eye™ Database and Software and other restricted features
                of the Website, subject to the terms of this Agreement, the Website
                Terms of Use, and Privacy Policy, and subject to the payment of a
                monthly subscription fee as set forth herein (“License”). User
                acknowledges that no license is granted to User in the source code
                of the GenA Eye™ Software. User shall not have the right to sell,
                assign, transfer, rent, lease, license, or sublicense GenA Eye™;
                copy, distribute, display, perform, modify, or create derivative works
                from the GenA Eye™ Database, Software, and Website, or circumvent any
                digital rights management or copyright management protection associated
                with the GenA Eye™ Database, Software, and Website.
              </p>
              <p>
                (b) User acknowledges that except for the use rights granted in
                this Section 2, no intellectual or any other proprietary rights are
                granted, transferred, or assigned through this Agreement. All
                intellectual property rights, including but not limited to patents,
                copyrights, trade secrets, and trademarks used or embodied in or in
                connection with the Database and Software and other restricted
                features of the Website are and remain entirely with the Company.
                The GenA Eye™ Database and Software are protected by copyright and
                other intellectual property laws and by international treaties.
              </p>
              <p>
                (c) User agrees not to transfer or assign the License granted
                herein or this Agreement to another party without the prior
                written consent of Company. If such consent is given and User
                transfers or assigns the License and/or this Agreement, then User
                agrees that User’s access to and use of the GenA Eye™ Database, Software,
                and other restricted features of the Website shall be terminated effective
                as of the date of transfer or assignment of this License.
              </p>
              <p>
                (d) If User is an entity (e.g., corporation, limited liability
                company, partnership, etc.) and seeks to register multiple
                individuals to obtain access to and use of the GenA Eye™ Database,
                Software, and restricted features of the Website, such entity
                must register the entity and each individual as a user and shall
                be obligated to pay the Subscription Fee on behalf of the entity
                and each user.
              </p>

              {/* Limitations to Use/Access; User Obligations */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Limitations to Use/Access; User Obligations
              </Typography>
              <p>
                (a) <strong>Security.</strong> User will be solely responsible for
                (i) the security, confidentiality, and integrity of all Data and
                other content that User posts to the GenA Eye™ Database and the
                Website, and (ii) any authorized or unauthorized access to User’s
                account by any person. Company, its affiliates, subsidiaries, members,
                managers, directors, officers, employees, successors, and assigns
                will have no obligation or responsibility for the content or accuracy
                of Data or other content uploaded to the Database or Website by User.
              </p>
              <p>
                (b) <strong>Privacy.</strong> Access to and use of the Database,
                Software, and Website is subject to the Privacy Policy governing
                the Website.
              </p>
              <p>
                (c) <strong>Accessibility.</strong> User agrees that from time to
                time the GenA Eye™ Database, Software, and Website may be
                inaccessible or inoperable for any reason, including without
                limitation (i) equipment malfunctions; (ii) periodic maintenance
                procedures or repairs which Company may undertake from time to
                time; or (iii) causes beyond the control of Company or which are
                not reasonably foreseeable by Company.
              </p>
              <p>
                (d) <strong>Citation.</strong> User agrees to use the following
                citation in all references to the Database and the Data obtained
                through the Database and/or the Software in all published papers
                and technical reports: “GenA Eye™ owned and operated by OCULOGYX
                INC. – WWW.OCULOGYX.AI”.
              </p>
              <p>
                (e) <strong>Prohibited Use.</strong> User shall not:
                <ol>
                  <li>
                    Remove any proprietary notices, labels, or marks from the GenA Eye™ Database,
                    Software, and Website;
                  </li>
                  <li>
                    Sell, transfer, lend, lease, license, or sublicense GenA Eye™;
                  </li>
                  <li>
                    Copy, distribute, display, perform, modify, or create derivative works
                    from the GenA Eye™ Database, Software, and Website, or circumvent any
                    digital rights management or copyright management protection associated
                    with the GenA Eye™ Database, Software, and Website;
                  </li>
                  <li>
                    Access the GenA Eye™ Database, Software, and Website in order to build a
                    similar or competitive product or service; or
                  </li>
                  <li>
                    Translate, reverse engineer, decompile, or disassemble the GenA Eye™
                    Database, Software, and Website.
                  </li>
                </ol>
              </p>
              <p>
                (f) <strong>Further Restrictions.</strong> All reproduction,
                downloading, and electronic storage of Data and other materials
                retrieved through the Database and/or Website shall be for
                internal or personal use and in accordance with the doctrine of
                “fair use” as defined under federal law. Downloading all or parts
                of the Database in a systematic or regular manner so as to create
                a collection of materials comprising all or part of the Database
                is strictly prohibited, whether or not such collection is in
                electronic or print form. The User shall take all reasonable
                precautions to limit the usage of the Database, Software, and
                Website to those specifically authorized by this Agreement.
              </p>
              <p>
                (g) <strong>Equipment.</strong> User will be solely responsible
                for providing, maintaining, and ensuring compatibility with the
                GenA Eye™ Database, Software, and Website, including all hardware,
                software, electrical, and other physical requirements for User’s use
                of the GenA Eye™ Database, Software, and Website, including without
                limitation telecommunications and internet access connections and
                links, web browsers, or other equipment, programs, and services
                required to access and use the GenA Eye™ Database, Software, and Website.
              </p>

              {/* User Representations */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                User Representations
              </Typography>
              <p>
                User represents and warrants that:
                <ol>
                  <li>
                    User is over the age of eighteen (18) and has the authority to enter into and perform User’s obligations under this Agreement.
                  </li>
                  <li>User is a resident of the United States.</li>
                  <li>
                    User is a health care professional or otherwise engaged in research, public health, or health care operations.
                  </li>
                  <li>
                    Any Data uploaded to the Database by User shall be De-Identified prior to it being uploaded.
                  </li>
                  <li>User is authorized to submit all content submitted to the Website and/or the Company by User.</li>
                  <li>
                    User will comply with all terms and conditions of this Agreement, the Terms of Use, and the Privacy Policy.
                  </li>
                  <li>
                    User has provided and will provide accurate and complete registration
                    information, including without limitation User’s legal name,
                    business entity name, address, telephone number, and email address,
                    and payment information, including credit card information.
                  </li>
                  <li>
                    Each individual executing this Agreement on behalf of a User that
                    is an entity has the power and authority to enter into this
                    Agreement and bind such party hereto. This Agreement has been duly
                    executed and delivered on behalf of User and is a legal, valid, and
                    binding obligation of User, enforceable against User in accordance
                    with its terms.
                  </li>
                </ol>
              </p>

              {/* Subscription Fee */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Subscription Fee
              </Typography>
              <p>
                Access to and use of the GenA Eye™ Database, Software, and other
                restricted features of the Website are subject to a monthly
                subscription in accordance with the terms of this Agreement. User
                shall pay a monthly subscription fee (“Subscription Fee”) of
                ___________________ Dollars ($___________.__) to the Company. The
                Subscription Fee is non-refundable and subject to change upon
                sixty (60) days written notice to User by Company. User’s
                continued enrollment in the subscription after the changes become
                effective will constitute User’s acceptance of the changes. If
                User does not wish to continue its subscription at the revised
                rates, User must notify the Company in writing in accordance with
                Section 8 of this Agreement; otherwise, the revised rate will apply
                on and from the date set forth in the notice.
              </p>

              {/* Payment Terms */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Payment Terms
              </Typography>
              <p>
                (a) <strong>Third Party Payment Processor.</strong> Company uses a
                third-party vendor Stripe Inc. (“Stripe”) to facilitate the
                payment of the Subscription Fee. The payment processing services
                provided by Stripe are subject to Stripe’s privacy policy, terms of
                use, and other terms of sale as may be modified from time to time
                (see https://stripe.com/privacy). By signing this Agreement, User
                agrees to be bound by Stripe’s terms of use, privacy policy, and
                other terms of sale as they may be modified by Stripe from time to
                time. The Company assumes no liability or responsibility for any
                payments that User may make through Stripe, and all such payments
                are non-refundable.
              </p>
              <p>
                (b) <strong>Payments Due in Advance.</strong> The Subscription Fee
                is due in advance and is payable on or before the first day of
                each month. The monthly Subscription Fee will be prorated for any
                partial months.
              </p>
              <p>
                (c) <strong>Past Due Payments.</strong> The Subscription Fee is
                considered late if payment is not received within five (5) days of
                the due date. Late payments are subject to interest charges of
                twelve percent (12%) per annum on the unpaid balance (or the
                maximum rate allowed by applicable law if such rate is less than
                12%). The Company reserves the right to terminate User’s access to
                the GenA Eye™ Database, Software, and other restricted features of
                the Website for failure to timely pay the Subscription Fee.
              </p>
              <p>
                (d) <strong>Credit Card Payments.</strong> Users must keep an
                active credit card on file and must complete and sign the
                Automatic Credit Card Billing Authorization attached hereto as
                Schedule 1. By signing the Authorization, User authorizes the
                monthly Subscription Fee to be charged to User’s credit card
                during the term of this Agreement. If User’s credit card is
                expired or no longer works, User shall promptly notify the Company
                of updated credit card information. If the Company is unable to
                obtain updated credit card information, the Company may terminate
                this Agreement and User’s access to the GenA Eye™ Database, Software,
                and other restricted features of the Website.
              </p>
              <p>
                (e) <strong>Payment Authorization.</strong> By signing this
                Agreement, User agrees to pay the Subscription Fee in full each
                month during the term of this Agreement and authorizes User’s
                credit card on file to be billed automatically each month by or on
                behalf of the Company until this Agreement has been terminated by
                either User or Company in accordance with the terms hereof. If the
                Subscription Fee is not paid when due in accordance with the terms
                of this Agreement or User’s credit card cannot be processed for
                any reason, the Company reserves the right to suspend, cancel, or
                terminate User’s subscription and access to the GenA Eye™ Database,
                Software, and other restricted features of the Website. User
                shall be responsible for paying all past due amounts.
              </p>

              {/* Recurring Billing Policy */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Recurring Billing Policy
              </Typography>
              <p>
                The Company’s subscription model is a monthly recurring charge, and
                the monthly billing cycle begins on the first day of each month.
                By agreeing to Company’s recurring billing terms, User hereby
                agrees to the following:
              </p>
              <p>
                (a) User represents and warrants that:
                <ol>
                  <li>
                    Any credit card information that User supplies to the Company is
                    true, correct, and complete;
                  </li>
                  <li>
                    Charges incurred for the subscription hereunder will be honored
                    by User’s credit card company;
                  </li>
                  <li>
                    User will pay the charges incurred in the amounts posted, including any applicable taxes;
                  </li>
                  <li>
                    User is the person in whose name the credit card was issued and/or
                    is authorized to make a purchase or other transaction with the
                    relevant credit card, and information provided in Schedule 1
                    hereto.
                  </li>
                </ol>
              </p>
              <p>
                (b) User agrees and authorizes the payment method provided to be
                billed automatically each month for the entire term of this
                Agreement at the price and upon the terms set forth in this
                Agreement.
              </p>
              <p>
                (c) If Company or Stripe is unable to secure payment from User’s
                credit card for any reason, including without limitation due to
                insufficient funds or inaccurate information provided by User when
                submitting electronic payment, the Company may undertake further
                collection action and apply fees therefor to the extent permitted by
                law.
              </p>
              <p>
                (d) User has the right to revoke this authorization by contacting
                the Company via email or through the Website no later than the
                fifteenth (15th) day of the month to cancel the next month’s
                payment. User understands and acknowledges that User’s right to
                access the GenA Eye™ Database, Software, and other restricted
                features of the Website may be canceled or withheld by the Company
                upon revocation of this authorization and that User is still
                responsible for all charges incurred by User pursuant to this
                Agreement.
              </p>
              <p>
                (e) This authorization will remain in full force and effect until
                revoked by User or the Company. User acknowledges and agrees that
                User will not dispute the payment with the credit card company as
                long as the transactions comply with the terms stated in this
                Authorization and the Agreement.
              </p>

              {/* Termination */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Termination
              </Typography>
              <p>
                (a) <strong>Commencement of Term.</strong> This Agreement is
                effective upon User’s acceptance as set forth herein and will
                continue in full force until terminated as set forth herein.
              </p>
              <p>
                (b) <strong>Termination by Company.</strong> A User’s License to
                access the GenA Eye™ Database, Software, and Website may terminate at
                any time in Company’s sole discretion. Company reserves the right
                in its sole discretion and without notice at any time to restrict
                access to the GenA Eye™ Database, Software, and Website to any User
                who fails to comply with the terms of this Agreement, the Terms of
                Use, the Privacy Policy, or any other written agreement applicable
                to User’s access to and use of the GenA Eye™ Database, Software, and
                Website.
              </p>
              <p>
                (c) <strong>Termination by User.</strong> User may terminate this
                Agreement at any time and for any reason by providing written
                notice to Company (by email or through the Website if available)
                at least fifteen (15) days prior to the commencement of User’s
                next billing cycle to take effect prior to the next billing cycle.
                For the avoidance of doubt, if User seeks to cease use at the end
                of any given month, User must provide written notice of termination
                to the Company on or before the 15th day of that month.
              </p>
              <p>
                (d) <strong>Effect of Termination.</strong> Upon termination of
                this Agreement, User will cease and desist from all use of the
                Database, Software, and Website and will uninstall, remove, and
                destroy all copies of the Database in the User’s possession or
                control, including any modified or merged portions thereof in any
                form, and execute and deliver evidence of such actions to the
                Company upon request. User shall remain bound by those provisions
                of this Agreement which by their terms extend beyond the date of
                termination.
              </p>

              {/* Disclaimer of Warranties & Guarantees */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Disclaimer of Warranties & Guarantees
              </Typography>
              <p>
                (a) The data contained in the Database is provided for information
                purposes only and does not constitute any form of advice,
                recommendation, representation, or endorsement. The Company does not
                guarantee and makes no warranties as to the accuracy, accessibility,
                integrity, and timeliness of this information. The Company assumes
                no liability or responsibility for any errors or omissions in the
                content of the Database or the Website and further disclaims any
                liability of any nature for any loss howsoever caused in
                connection with using the Database or the Website. The Company may
                make changes to the Database at any time without notice.
              </p>
              <p>
                (b) THE GENAEYE DATABASE, SOFTWARE, AND WEBSITE ARE PROVIDED BY
                COMPANY AND ACCEPTED BY USER "AS IS," "AS AVAILABLE," AND "WITH ALL
                FAULTS." COMPANY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING BUT
                NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, AND AGAINST INFRINGEMENT. COMPANY DOES
                NOT WARRANT THAT ACCESS TO THE GENAEYE DATABASE, SOFTWARE, AND
                WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
                CORRECTED, OR THAT ANY WEBSITE OR APPLICATION THAT MAKES THE
                GENAEYE DATABASE, SOFTWARE, AND WEBSITE AVAILABLE IS FREE OF
                VIRUSES OR OTHER HARMFUL COMPONENTS. COMPANY DOES NOT WARRANT OR
                MAKE ANY REPRESENTATION OR GUARANTEE REGARDING USE OR THE RESULT
                OF USE OF THE GENAEYE DATABASE, SOFTWARE, AND WEBSITE IN TERMS OF
                ACCURACY, RELIABILITY, OR OTHERWISE. COMPANY WILL NOT BE LIABLE FOR
                ANY DELIVERABLES PROVIDED BY THIRD-PARTY VENDORS IDENTIFIED OR
                REFERRED TO THE USER BY COMPANY DURING THE TERM OF THIS AGREEMENT.
              </p>

              {/* Limitation of Liability */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Limitation of Liability
              </Typography>
              <p>
                (a) THE COMPANY SHALL IN NO EVENT BE LIABLE FOR ANY BUSINESS
                DECISION TAKEN BY THE USER BASED ON THE DATA MADE AVAILABLE
                THROUGH THE DATABASE, SOFTWARE, OR WEBSITE. THE ENTIRE RISK ARISING
                OUT OF THE USE OF THE DATABASE, SOFTWARE, AND WEBSITE REMAINS WITH
                THE USER.
              </p>
              <p>
                (b) UNDER NO CIRCUMSTANCES WILL COMPANY BE LIABLE TO USER OR ANY
                OTHER PERSON FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR
                PUNITIVE DAMAGES FOR ANY MATTER ARISING FROM OR RELATING TO THIS
                AGREEMENT, THE GENAEYE DATABASE, SOFTWARE, OR WEBSITE GENERALLY,
                INCLUDING WITHOUT LIMITATION USER’S USE OR INABILITY TO USE THE
                GENAEYE DATABASE, SOFTWARE, OR WEBSITE, ANY CHANGES TO OR
                INACCESSIBILITY OF THE GENAEYE DATABASE, SOFTWARE, OR WEBSITE,
                DELAY, FAILURE, UNAUTHORIZED ACCESS TO OR ALTERATION OF ANY DATA OR
                OTHER CONTENT SENT OR RECEIVED, OR NOT SENT OR RECEIVED, ANY
                TRANSACTION OR AGREEMENT ENTERED INTO THROUGH THE WEBSITE, OR ANY
                DATA OR OTHER CONTENT FROM A THIRD PERSON ACCESSED ON OR THROUGH
                THE WEBSITE, WHETHER SUCH LIABILITY IS ASSERTED ON THE BASIS OF
                CONTRACT, TORT, OR OTHERWISE. IF USER IS DISSATISFIED WITH THE
                GENAEYE DATABASE, SOFTWARE, OR WEBSITE, USER’S SOLE AND EXCLUSIVE
                REMEDY WILL BE FOR USER TO DISCONTINUE USE OF THE GENAEYE DATABASE,
                SOFTWARE, OR WEBSITE AND TERMINATE THIS AGREEMENT.
              </p>

              {/* Indemnification */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Indemnification
              </Typography>
              <p>
                User agrees to indemnify, hold harmless, and defend Company, its
                shareholders, directors, officers, employees, and agents from and
                against any action, cause, claim, damage, debt, demand, or liability
                including reasonable costs and attorney’s fees asserted by any
                person arising out of or relating to:
                <ol>
                  <li>This Agreement;</li>
                  <li>User’s use of the GenA Eye™ Database, Software, or Website;</li>
                  <li>The failure of User to properly or completely de-identify Data
                  prior to uploading Data to the Database and/or Website;</li>
                  <li>Any unacceptable use of the GenA Eye™ Database, Software, or
                  Website, including without limitation any statement, Data, or content
                  made, transmitted, or republished by User which is prohibited under
                  this Agreement.</li>
                </ol>
              </p>

              {/* Miscellaneous */}
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Miscellaneous
              </Typography>
              <p>
                (a) The parties agree that this Agreement constitutes a legal,
                valid, and binding obligation of Company and User, enforceable
                against Company and User in accordance with its terms.
              </p>
              <p>
                (b) The relationship of the Company and User is that of
                independent contractors. Nothing in this Agreement and no course
                of dealing between the parties will be construed to create or
                imply an employment or agency relationship or a partnership or
                joint venture relationship between the parties. Each of the
                parties is an independent contractor and neither Company nor User
                has the authority to bind or contract any obligation in the name
                of or on account of the other party or to incur any liability or
                make any statements, representations, warranties, or commitments on
                behalf of the other party or otherwise act on behalf of the other.
              </p>
              <p>
                (c) A printed version of this Agreement and of any notice given in
                electronic form will be admissible in judicial or administrative
                proceedings based upon or relating to this Agreement to the same
                extent and subject to the same conditions as other business
                documents and records originally generated and maintained in
                printed form.
              </p>
              <p>
                (d) This Agreement will be governed by and interpreted in
                accordance with the laws of the State of California without giving
                effect to the principles of conflicts of law of such state. Any
                legal suit, action, or proceeding arising out of or related to this
                Agreement will be instituted exclusively in the federal courts of
                the United States or the courts of the State of California in the
                County of San Diego. User waives any and all objections to the
                exercise of jurisdiction over User by such courts and to venue in
                such courts.
              </p>
              <p>
                (e) Each party recognizes that any actual or threatened breach of
                this Agreement may cause irreparable harm and be exceptionally
                difficult to quantify, such that each party will be entitled to
                injunctive relief or a decree of specific performance upon a
                proper showing of such a violation without the necessity of
                demonstrating actual monetary damage.
              </p>
              <p>
                (f) If any provision or portion of this Agreement is rendered by
                applicable law or held by a court of competent jurisdiction to be
                illegal, invalid, or unenforceable, the remaining provisions or
                portions shall remain in full force and effect.
              </p>
              <p>
                (g) The failure of either party to enforce any provision of this
                Agreement will not be construed as a waiver or modification of
                such provision or impairment of its right to enforce such
                provision or any other provision of this Agreement thereafter.
              </p>
              <p>
                (h) User may not assign, sublicense, or transfer (in insolvency
                proceedings, by mergers, acquisitions, or otherwise) any right or
                obligation under this Agreement without the prior written consent
                of the Company, which consent shall be at the Company’s sole
                discretion. Any assignment or other transfer without Company’s
                written consent shall be null and void. User hereby consents to
                Company assigning this Agreement in whole or in part. This
                Agreement shall be binding upon and inure to the benefit of the
                successors, permitted assigns, and legal representatives of the
                parties.
              </p>
              <p>
                (i) User acknowledges that this Agreement is a legal document and
                creates certain rights and responsibilities. User also
                acknowledges having had reasonable time to seek legal advice
                regarding the Agreement and has either chosen not to do so or has
                done so and is satisfied with the terms and conditions of the
                Agreement. By signing this Agreement, User acknowledges and agrees
                that User is legally bound by its content.
              </p>

              {/* Schedule 1: Automatic Credit Card Billing Authorization
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 4 }}
              >
                Schedule 1: Automatic Credit Card Billing Authorization
              </Typography>
              <p>
                To enjoy the convenience of automated billing, simply complete and
                sign this Authorization. All requested information is required.
                Upon approval, you will be enrolled in a monthly auto-deduction
                subscription plan. The date of debit will occur monthly on the
                first day of the month. You agree that no prior notification will
                be provided unless the date or amount changes, in which case you
                will receive 60 days prior written notice.
              </p>
              <p>
                User Name:
                ________________________________________________________
              </p>
              <p>
                I authorize OCULOGYX INC. to automatically bill the card listed
                below as specified:
              </p>
              <p>
                Amount: $ ________________ <br />
                Frequency: Monthly on the 1st day of each month. <br />
                Start billing on: ___ /___ /___ <br />
                End billing on: Upon written cancellation by User or Company
                pursuant to the terms of Subscription Agreement.
              </p>
              <p>
                <strong>Credit Card Information</strong>
              </p>
              <p>
                Name as it appears on Credit Card (Please print): <br />
                _________________________________________________________________________________
              </p>
              <p>
                Credit Card Type (Check One): ____ MasterCard; ____ Visa; ____
                Discover; ____ American Express
              </p>
              <p>
                Credit Card Number: ___________________________________________ <br />
                Expiration Date: ___________________________________________ <br />
                Security Code: ___________________________________________
              </p>
              <p>
                Billing Address: <br />
                _________________________________________________________________________________
                <br />
                (Address) (City) (State) (Zip)
              </p>
              <p>
                Phone Number:
                ____________________________________________________________ <br />
                Email:
                ____________________________________________________________________
              </p>
              <p>
                I understand that this authorization will remain in effect until I
                cancel it in writing. I agree to notify OCULOGYX INC. in writing
                of any changes in my account information or termination of this
                authorization at least fifteen (15) days prior to the next billing
                date. If the above noted payment dates fall on a weekend or
                holiday, I understand that the payments may be executed on the next
                business day. I certify that I am an authorized user of this
                Credit Card and will not dispute these scheduled transactions; so
                long as the transactions correspond to the terms stated in this
                Authorization and the Agreement.
              </p>
              <p>
                Card Holder Signature: ______________________________________ <br />
                Date:___________________
              </p> */}
            </Typography>
          </Box>
        </Container>

        {/* Inline Styles */}
        <Box component="div" sx={{ display: "none" }}>
          <style>{`
            /* Navigation Links Styles */
            .nav-link {
              color: #000;
              text-decoration: none;
              margin-right: 20px;
              font-size: 22px;
              cursor: pointer;
              transition: color 0.3s ease;
            }
            .nav-link.last-nav-link {
              margin-right: 0;
            }
            .nav-link:hover {
              color: #5dc1d8;
            }

            /* Responsive Styles */
            @media (max-width: 768px) {
              .top-nav {
                flex-direction: column;
                align-items: flex-start;
              }
              .top-nav img {
                margin-bottom: 20px;
              }
              .nav-links {
                flex-direction: column;
                align-items: flex-start;
              }
              .nav-link {
                margin-right: 0;
                margin-bottom: 10px;
              }
              .terms-container {
                padding: 20px;
              }
              .terms-container h1 {
                font-size: 28px;
              }
              .terms-container h2 {
                font-size: 24px;
              }
              .terms-container p {
                font-size: 16px;
              }
            }

            /* Additional Styles for Terms and Conditions */
            .terms-container h1, .terms-container h2, .terms-container p {
              color: #000; /* Set text color to black for readability */
            }

            /* Adjust background for readability */
            .terms-container {
              background-color: #f9f9f9;
              color: #000;
              border-radius: 10px;
              padding: 30px;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
            }

            /* Optional: Style ordered lists */
            .terms-container ol {
              padding-left: 20px;
            }
            .terms-container li {
              margin-bottom: 10px;
            }
          `}</style>
        </Box>
      </Box>
      </div>
    </MasterLayout>
  );
};

export default TermsAndConditionsLayout;
